import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorMsg from '../../../common/components/error-msg.rn';
import { FeedPollItem } from './feed-poll-item/feed-poll-item.container';
import { FeedEventItem } from './feed-event-item/feed-event-item.container';
import { FeedNewsItem } from './feed-news-item/feed-news-item-container';
import { FeedEventModal } from '../feed-event-modal/feed-event-modal.container';
import FirstTimeReminderModal from './feed-event-item/first-time-reminder-modal.rn';
import LoadingSpinner from '../../../common/components/loading-spinner.rn';
import EmptyNotice from '../../../common/components/empty-notice.rn';
import debounce from 'lodash.debounce';
import diff from 'deep-diff';
import { analytics } from '../../../analytics/report';
import { LangData } from '../../../assets/locales/localization';
import { Theme } from '../../../common/providers/colors';
import { NewFeedItemSubscription } from '../../graphql/news-feed-item.subscription';
import { NewFeedItemUpdatedSubscription } from '../../graphql/news-feed-item-updated.subscription';
import { apolloClient } from '../../../apollo-subs';

const width = window.innerWidth

export class FeedListView extends Component {
  static propTypes = {
    feed: PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.any,
      refetch: PropTypes.func,
      networkStatus: PropTypes.number,
      feedList: PropTypes.shape({
        hasMore: PropTypes.bool,
        items: PropTypes.arrayOf(PropTypes.any)
      })
    }),
    categories: PropTypes.array,
    setReadIndicationForItem: PropTypes.func,
    shouldIndicateNewItems: PropTypes.any,
    onlyReminders: PropTypes.bool,
    shouldShowFirstTimeBell: PropTypes.bool,
    loadMoreFeedData: PropTypes.func,
    updateRecentItem: PropTypes.func,
    loadNewItems: PropTypes.func,
    dismissBellModal: PropTypes.func,
    hideNewBadgeForItems: PropTypes.any,
    activeEventModal: PropTypes.any,
    setActiveEventModal: PropTypes.func
  };

  static defaultProps = {
    feed: {
      loading: true,
      networkStatus: 1,
      error: null,
      feed: []
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      isScrollEnd: false,
      scrollPosition: 0,
      modalNews: null,
      refreshing: false,
      eventImageViewerData: null,
      loadingMore: false,
      doneRenderingItems: false,
      data: null
    };
    this._subscriptionHandle = null;
    this._intervalHandler = null;
    this.loadMoreData = debounce(this.loadMoreData.bind(this), 150);
    this.setEventImageViewerData = this.setEventImageViewerData.bind(this);
  }

  componentWillMount = () => {
    if (!this.props.feed.loading &&
      this.props.feed &&
      this.props.feed.feedList &&
      this.props.feed.feedList.items) this.setState({ data: this.props.feed.feedList.items })
    this.subscribeToData()
    this.subscribeToUpdates()
  }

  subscribeToUpdates = () => {
    apolloClient.subscribe({
      query: NewFeedItemUpdatedSubscription,
      variables: {}
    })
      .subscribe({
        next: (data) => {
          let updatedData = data.itemUpdated
          if (updatedData !== null) {
            let idx = this.state.data.findIndex(item => item.id.toString() === updatedData.id.toString())
            if (idx !== -1) {
              let cpy = [...this.state.data]
              cpy[idx] = updatedData
              this.setState({ data: cpy })

            }
          }
        }
      })
  }

  subscribeToData = () => {
    apolloClient.subscribe({
      query: NewFeedItemSubscription,
      variables: {}
    })
      .subscribe({
        next: (data) => {
          let newItem = data.itemAdded
          this.setState({ data: [newItem, ...this.state.data] })

        }
      })
  }


  componentWillUnmount() {
    if (width > 500) {
      document.getElementById('feed-scroll-container')
        .removeEventListener('scroll', this.handleScroll);
    }
  }

  componentDidMount() {
    if (width > 500) {
      const element = document.getElementById('feed-scroll-container');
      if (element)
        element.addEventListener('scroll', this.handleScroll, true);
    }
  }


  componentWillReceiveProps(newProps) {
    if (!newProps.feed.loading &&
      newProps.feed &&
      newProps.feed.feedList &&
      newProps.feed.feedList.items) this.setState({ data: newProps.feed.feedList.items })

    if (
      this.props.activeEventModal &&
      newProps.feed &&
      newProps.feed.feedList &&
      newProps.feed.feedList.items
    ) {
      try {
        const newState = newProps.feed.feedList.items.find(
          e => e.id === this.props.activeEventModal.id
        );
        if (newState) {
          const objDiff = diff(this.props.activeEventModal, newState);

          if (objDiff && objDiff.length > 0) {
            this.props.setActiveEventModal(newState);
          }
        }
      } catch (e) {
      }
    }

    let eventModalIdFromUrl = localStorage.getItem('eventToDisplay');

    if (
      newProps.feed &&
      newProps.feed.feedList &&
      newProps.feed.feedList.items &&
      eventModalIdFromUrl !== 'none' &&
      eventModalIdFromUrl !== null
    ) {
      let eventToDispaly = newProps.feed.feedList.items.filter(
        item => item.id === localStorage.getItem('eventToDisplay')
      );
      localStorage.setItem('eventToDisplay', 'none');
      this.props.setActiveEventModal(eventToDispaly[0]);
      this.props.toggleComponent('News_Feed');
    }
  }

  loadMoreData = () => {
    if (this.props.onlyReminders) {
      return;
    }

    this.setState({ loadingMore: true });
    this.props
      .loadMoreFeedData()
      .then(() => this.setState({ loadingMore: false }));
  };

  closeEventModel = () => {
    document.body.style.position = '';
    window.scrollTo(0, this.state.scrollPosition);
    requestAnimationFrame(() => {
      this.props.setActiveEventModal(null);
    });
  };

  openEventModal(event) {
    let position = document.documentElement.scrollTop;
    document.body.style.position = 'fixed';
    this.setState({ scrollPosition: position });
    if (this.state.modalNews === null) {
      this.props.setActiveEventModal(event);
    } else {
      this.setState(
        {
          modalNews: null
        },
        () => {
          this.props.setActiveEventModal(event);
        }
      );
    }
  }

  openImageModal = imageUrl => {
    this.props.setLastPosition(document.documentElement.scrollTop);
    this.props.openImgModal(imageUrl);
    this.props.history.push('/fullScreenImageModal');
    this.props.toggleComponent('FullScreenImageModal');
    this.setState({ modalNews: { uri: imageUrl } });
  };

  closeNewsItemModal = () => {
    this.setState({ modalNews: null });
  };

  setEventImageViewerData(data) {
    this.setState({ eventImageViewerData: data });
  }

  renderItem = item => {
    const feedItem = item;
    let child = null;
    if (!feedItem || !feedItem.id) return;
    const alreadyRead =
      this.props.hideNewBadgeForItems === null ||
      this.props.hideNewBadgeForItems[feedItem.id] ||
      feedItem.__typename === 'NewsFeedPollItem';
    switch (feedItem.__typename) {
      case 'NewsFeedPollItem':
        child = <FeedPollItem poll={feedItem}/>;

        break;
      case 'NewsFeedNewsItem':
      case 'NewsFeedItemType':
        child = (
          <FeedNewsItem
            onPicClicked={imageUrl => this.openImageModal(imageUrl)}
            news={feedItem}
            history={this.props.history}
          />
        );

        break;
      case 'NewsFeedSportItem':
      case 'NewsFeedEventItem': {
        child = (
          <FeedEventItem
            onLastImagesClicked={this.setEventImageViewerData}
            onPosterClicked={this.openImageModal}
            eventItem={{
              ...feedItem,
              isNew: feedItem.isNew && !alreadyRead
            }}
            history={this.props.history}
            showComments={true}
          />
        );

        break;
      }
      default:
        child = null;

        break;
    }

    if (child) {
      return (
        <div
          id='row223'
          key={item.id}
          style={
            feedItem.author && feedItem.author.admin
              ? styles.itemContainerAdmin
              : styles.itemContainer
          }
          disabled={feedItem.__typename === 'NewsFeedPollItem'}
          onClick={() => this.handleItemPressed(feedItem)}
        >
          {feedItem.author && feedItem.author.admin && (
            <p style={{ textAlign: 'center', fontWeight: 'bold' }}>
              Yocas Team
            </p>
          )}
          {child}
        </div>
      );
    }

    return null;
  };

  handleItemPressed(feedItem) {
    this.props.setReadIndicationForItem({ [feedItem.id]: true });

    if (
      feedItem.__typename === 'NewsFeedEventItem' ||
      feedItem.__typename === 'NewsFeedSportItem'
    ) {
      analytics.report('Feed', 'EventView', feedItem.id);
      this.openEventModal(feedItem);
    } else if (feedItem.__typename === 'NewsFeedNewsItem') {
      analytics.report('Feed', 'NewsReadMore', feedItem.id);
    }
  }

  _renderError() {
    return <ErrorMsg message={ErrorMsg.LOADING_MSG} style={styles.message}/>;
  }

  _renderLoadingSpinner() {
    return <LoadingSpinner/>;
  }

  onRefresh = () => {
    this.setState({
      refreshing: true
    });

    if (this.props.feed) {
      this.props.feed
        .refetch()
        .catch(() => null)
        .then(() => {
          this.setState({
            refreshing: false
          });
        });
    }
  };

  closeImageViewer = () => {
    this.setState({ eventImageViewerData: null });
  };

  _renderFeedList() {
    if (
      !this.props.feed.loading &&
      this.props.feed &&
      this.props.feed.feedList &&
      this.props.feed.feedList.items &&
      this.props.feed.feedList.items.length === 0
    ) {
      if (this.props.onlyReminders) {
        return (
          <EmptyNotice
            title={LangData.feedList.title}
            emoji='⏰'
            text={LangData.feedList.interest}
          />
        );
      }

      return (
        <EmptyNotice
          title={LangData.feedList.nothing}
          emoji='⚒'
          text={LangData.feedList.noPicked}
        />
      );
    }

    const data =
      this.state.data
        ? this.state.data || []
        : [];
    const hasMore =
      this.props.feed &&
      this.props.feed.feedList &&
      this.props.feed.feedList.hasMore;

    return (
      <div
        style={{
          width: width < 500 ? window.innerWidth : '60%',
          overflowY: width < 500 ? 'scroll' : 'unset',
          marginLeft: width < 500 ? 0 : '12vw',
          height: width < 500 ? window.innerHeight : 'auto',
        }}
        onScroll={width < 500 ? this.handleScroll : () => {
        }}
      >
        {this.props.onlyReminders && (
          <p style={styles.remindersNotice}>{LangData.feedList.display}</p>
        )}

        {data !== null &&
          data.map((item, index) => {
            return this.renderItem(item);
          })}
      </div>
    );
  }

  handleScroll = e => {
    let element = e.target;
    let elementHeight = parseInt(element.clientHeight);
    let currentHeight =
      parseInt(element.scrollHeight) - parseInt(element.scrollTop);
    let distance = currentHeight - elementHeight;
    const isEnd = distance < 10 && distance > -5;

    if (isEnd) {
      this.setState({
        isScrollEnd: true
      });
      let hasMore =
        this.props.feed &&
        this.props.feed.feedList &&
        this.props.feed.feedList.hasMore;
      if (hasMore) {
        this.loadMoreData();
      }
    }
  };

  render() {
    const { networkStatus, error } = this.props.feed;
    let toDisplay = null;

    if (networkStatus === 1 && !this.props.feed.feedList) {
      toDisplay = this._renderLoadingSpinner();
    } else if (error) {
      toDisplay = this._renderError();
    } else {
      toDisplay = this._renderFeedList();
    }

    return (
      <div id='mainFeedList' style={styles.container}>
        <FeedEventModal
          onClose={this.closeEventModel}
          event={this.props.activeEventModal}
          history={this.props.history}
        />
        <FirstTimeReminderModal
          isOpen={this.props.shouldShowFirstTimeBell}
          onClose={() => {
            this.props.dismissBellModal();
          }}
        />
        {toDisplay}
        {this.state.loadingMore && <LoadingSpinner/>}
      </div>
    );
  }
}

const styles = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Theme.BACKGROUND_COLOR,
    overflowX: 'hidden'
  },
  list: {
    flexGrow: 1,
    paddingBottom: 55
  },
  message: {
    color: 'white',
    fontSize: 16,
    fontFamily: 'Assistant-Bold',
    textAlign: 'center',
    fontStyle: 'italic'
  },
  emptyMessage: {
    color: 'white',
    fontSize: 14,
    textAlign: 'center',
    marginTop: 30,
    marginHorizontal: 20
  },
  remindersNotice: {
    alignSelf: 'center',
    textAlign: 'center',
    color: 'white',
    fontSize: 12
  },
  newBadge: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 100
  },
  itemContainer: {
    width: width < 500 ? 'unset' : '70%',
    margin: width < 500 ? '2%' : '2% auto',
    padding: '10px',
    backgroundColor: '#f3f2ef',
    borderRadius: '20px',
    position: 'relative'
    // opacity: 0.8
  },
  itemContainerAdmin: {
    width: width < 500 ? 'unset' : '70%',
    padding: '10px',
    backgroundColor: '#eae9e9',
    margin: width < 500 ? '2%' : '2% auto',
    borderRadius: '20px',
    position: 'relative'
  },
  addPostContainer: {
    display: 'flex',
    alignItems: 'center',
    width: window.innerWidth,
    padding: '1% 5%'
  },
  addNewPost: {
    width: '70%',
    borderRadius: '30px',
    border: `1px solid ${Theme.SHAPE_AND_LINES}`,
    marginLeft: '3%',
    color: Theme.TEXT_COLOR,
    padding: '3%'
  }
};
