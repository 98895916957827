import React, { useState, useEffect, useMemo } from 'react';
import { Theme } from '../common/providers/colors';
import { getClient } from '../apollo';
import { userTalkedWithYogi } from './graphql/userTalkedWithYogi';
import Yogi from '../assets/images/Yogi.png';
import { CreateUpdateNewsForm } from './AddPosts/AddPostContainer';
import yogiBlob from '../assets/images/yogiBlob.png';
import Chatbot from '../chatbot/Chatbot';
import SwipeableViews from 'react-swipeable-views';
import { FeedList } from '../news-feed/components/feed-list/feed-list.container';
import { ChatList } from '../chat/components/chats-list/chat-list.container';
import { FriendsList } from '../friends/components/friends-list.container';
import { MyNotifications } from '../my-notifications/components/my-notifications.container';
import { NotificationModal } from '../notification/components/notification-modal.container';
import { NewsFeedCategoriesGrid } from '../news-feed/components/categories-grid/categories-grid.container';
import './main-page-view.scss';
import { ChatsModal } from "../chat/components/chats-modal";
import { useSelector } from "react-redux";
import { selectIsChatListModalOpen } from "../chat/chat.reducer";
import { selectIsNotificationsListModalOpen } from "../my-notifications/notifications.reducers";
import { MyNotificationsModal } from "../my-notifications/components/my-notifications-modal";

const width = window.innerWidth

const addPostTextOptions = [
  "Tell us what's on your mind",
  "Share your experiences with yoga",
  "Tell us about your experiences with cancer",
  "Share your story with us",
  "Tell us your tips for beating cancer",
  "Tell us your tips for practicing yoga"
]

function GeneralView(props) {
  const [isYogiOpen, setIsYogiOpen] = useState(false);
  const [updatedTalkWithYogi, setUpdatedTalkWithYogi] = useState(false);
  const [displayBubble, setDisplayBubble] = useState(true);
  const [addPostOpen, setAddPostOpen] = useState(false);
  const randomTextSelected = useMemo(() => addPostTextOptions[Math.floor(Math.random() * addPostTextOptions.length)],
    []);
  const isChatListOpen = useSelector(selectIsChatListModalOpen);
  const isNotificationsListOpen = useSelector(selectIsNotificationsListModalOpen);
  const [notifGetMore, setNotifGetMore] = useState(false)

  const playYogi = async () => {
    let soundOn = await localStorage.getItem('soundOn');
    if (soundOn !== null && soundOn === 'true') {
      let audio = new Audio('/sounds/yogiSound.mp3');
      audio.load();
      if (displayBubble === true) audio.play();
    }
  }

  useEffect(() => {
    document.getElementById('zmmtg-root').style.display = 'none'

    playYogi()
    setTimeout(() => {
      setDisplayBubble(false)
    }, 5000)
  }, [props.currentComponent]);

  const userTalkedWithYogiUpdate = () => {
    if (!props.currentUser.talkedWithYogi && !updatedTalkWithYogi) {
      getClient().mutate({
        mutation: userTalkedWithYogi,
        variables: {
          userId: props.currentUser.id
        }
      })
        .then(_ => {
          setUpdatedTalkWithYogi(true)
        })
    }
  }

  return (
    <>
      <div id='feed-scroll-container' style={width > 500 && props.currIndex === 0 ? styles.scrollContainer : {}}>
        <div className='yogi_avatar' style={styles.talkWithYourYogiContainer}>
          <button onClick={() => {
            setIsYogiOpen(!isYogiOpen);
            userTalkedWithYogiUpdate()
          }} style={props.currIndex === 0 ? styles.talkWithYourYogiBtn : { display: 'none' }}>
            {
              props.currIndex === 0 && displayBubble && !isYogiOpen && !updatedTalkWithYogi &&
              <span style={styles.yogiBlobStyle}>
                <span
                  style={styles.yogiBlobTextStyle}>Hi, I'm your personal Yogi.<br></br>Click on me to get started</span>
                </span>
            }
            {!isYogiOpen && props.currIndex === 0 && <img src={Yogi} style={styles.yogiLogo} alt=""/>}
          </button>
        </div>
        {isYogiOpen && <div onClick={() => {
          setIsYogiOpen(!isYogiOpen);
          userTalkedWithYogiUpdate()
        }} style={styles.exitIcon}>x</div>}
        {
          props.currIndex === 0 &&
          <div style={styles.addNewPostContainer}>
            <button style={styles.addNewPostBtn} onClick={() => {
              // props.toggleComponent('MyPosts');
              // props.history.push('myPosts');
              setAddPostOpen(!addPostOpen)
            }}>
              <span style={
                width < 500 ?
                  { width: '80%', marginRight: '5%', fontSize: "4vw" }
                  :
                  { width: '77%', marginRight: '5%', fontSize: "1.5vw" }
              }>{randomTextSelected}</span>
              <span style={styles.addPostToggler}>{addPostOpen ? '<' : '>'}</span>
            </button>
          </div>
        }
        {
          addPostOpen &&
          <CreateUpdateNewsForm currentUser={props.currentUser} updateAddPost={() => setAddPostOpen(!addPostOpen)}/>
          // <AddPost/>
        }
        <Chatbot {...props} isYogiOpen={isYogiOpen}/>
        <SwipeableViews index={props.currIndex} onChangeIndex={props.handleChangeIndex}>
          <FeedList history={props.history}/>
          <FriendsList history={props.history}/>
          <MyNotifications history={props.history} inComponent={props.currIndex === 2}/>
          <ChatList history={props.history} currentUser={props.currentUser} />
        </SwipeableViews>
        <NotificationModal/>
        {/* <AddToHomescreen onAddToHomescreenClick={handleAddToHomescreenClick}/> */}
        {props.currentComponent === 'Filter' && (
          <NewsFeedCategoriesGrid history={props.history} />
        )}
      </div>
      {/* {props.currIndex === 0 && width > 500 && <FriendsModal history={props.history}/>} */}
      {isChatListOpen && width > 500 && <ChatsModal history={props.history} currentUser={props.currentUser} />}
      {isNotificationsListOpen && width > 500 &&
        <MyNotificationsModal history={props.history} inComponent={props.currIndex === 2} getMore = {notifGetMore} setGetMore = {setNotifGetMore}/>}
    </>
  )
}

export default GeneralView;

const styles = {
  container: {
    display: 'flex',
  },
  scrollContainer: {
    marginTop: '120px',
    maxHeight: 'calc(100vh - 120px)',
    overflowY: 'auto',
    position: 'relative'
  },
  bubble: {
    backgroundColor: 'red',
    fontSize: '13px',
    height: 'fit-content',
    padding: '0.7% 2.2%',
    borderRadius: '50%',
    position: 'absolute',
    top: '18%',
    right: '6%'
  },
  mainPage: {
    // minHeight: '100vh',
    backgroundColor: Theme.BACKGROUND_COLOR
  },
  outerNav: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: window.innerWidth,
    paddingTop: '2%',
    position: 'fixed',
    bottom: 0,
    zIndex: 9,
    backgroundColor: '#4d4b62',
    borderRadius: '20px 20px 0 0',
    padding: '3% 0'
  },
  innerMenu: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%'
  },
  topNav: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: window.innerWidth,
    paddingTop: '2%',
    paddingBottom: width < 500 ? '0' : '2%',
    position: 'fixed',
    top: 0,
    zIndex: 99999,
    backgroundColor: Theme.BACKGROUND_COLOR
  },
  topNavGray: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: window.innerWidth,
    paddingTop: '2%',
    position: 'fixed',
    top: 0,
    zIndex: 99999,
    backgroundColor: Theme.GRAY_COLOR
  },
  innerNav: {
    textAlign: 'center',
    fontSize: '35px',
    color: Theme.LOWER_NAV_ICONS_COLOR,
    paddingBottom: '1%',
    display: 'flex'
  },
  innerNavSelected: {
    textAlign: 'center',
    fontSize: '35px',
    color: Theme.WHITE_COLOR,
    paddingBottom: '1%',
    borderBottom: `2px solid ${Theme.WHITE_COLOR}`
  },
  logo: {
    width: width < 500 ? '50%' : '20%',
    padding: '1%'
  },
  menu: {
    color: Theme.BLACK_COLOR,
    fontSize: '25px',
    marginLeft: width < 500 ? '0' : '5%'
  },
  fullPage: {
    minHeight: window.innerHeight,
    width: window.innerWidth,
    opacity: '0.9',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: '999999',
    textAlign: 'center',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '0'
  },
  msgContainer: {
    width: window.innerWidth / 2 + 'px',
    backgroundColor: 'white',
    fontFamily: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: Theme.TEXT_COLOR
  },
  btn: {
    color: Theme.TEXT_COLOR,
    backgroundColor: Theme.BACKGROUND_COLOR,
    border: 'none',
    padding: '7%',
    fontWeight: 'bold',
    fontSize: window.innerWidth / 24 + 'px',
    fontFamily: 'inherit',
    width: '50%',
    margin: '1%'
  },
  icon: {
    color: Theme.BACKGROUND_COLOR,
    fontSize: window.innerWidth / 7.2 + 'px',
    marginTop: '5%'
  },
  buttonsRow: {
    width: '100%',
    display: 'flex'
  },
  userAnswerInput: {
    border: '1px solid #dcd7c8',
    fontFamily: 'inherit',
    marginBottom: '10%',
    padding: '5%',
    fontSize: '15px',
    borderRadius: '15px'
  },
  talkWithYourYogiBtn: {
    width: width < 500 ? "100%" : "64px",
    borderRadius: "25px",
    border: "none",
    padding: "5px 10px",
    backgroundColor: "transparent",
    color: '#665f70',
    // margin: '5%'
    display: width < 500 ? 'flex' : 'block',
    flexDirection: 'row-reverse',
    outline: '0'
  },
  exitIcon: {
    position: width < 500 ? "absolute" : 'fixed',
    zIndex: 10000,
    top: width < 500 ? "20%" : "28%",
    left: width < 500 ? "94%" : "initial",
    right: width < 500 ? "initial" : '24px',
    width: width < 500 ? "20%" : '20px',
    fontSize: "18px",
    color: "#978689",
    cursor: "pointer"
  },
  talkWithYourYogiContainer: {
    // marginTop: width < 500 ? window.innerWidth / 7.2 + 'px' : window.innerWidth / 11 + 'px',
    // width: 'fit-content',
    // display: "flex",
    // justifyContent: "center",
    position: width < 500 ? 'absolute': 'fixed',
    zIndex: '10000',
    top: width < 500 ? '16%' : "76%",
    left: width < 500 ? '80%' : 'unset',
    right: width < 500 ? 'unset' : '13%',
    width: width < 500 ? '20%' : '100px',
    flexDirection: 'row-reverse'

  },
  outerNavSubMenuBtn: {
    width: '70%',
    borderRadius: '25px',
    padding: '2%',
    backgroundColor: '#e1c2b0',
    color: '#4D4B62',
    fontSize: 'large',
    marginBottom: '2%'
  },
  addNewPostContainer: {
    marginTop: width < 500 ? (window.innerWidth / 7.2 - 2) + 'px' : 0,
    width: '80%',
    marginLeft: width < 500 ? 'unset' : '16vw',
    display: 'flex',
    justifyContent: width < 500 ? 'center' : 'flex-start',
  },
  addNewPostBtn: {
    outline: 'none',
    width: width < 500 ? "80%" : "55%",
    borderRadius: "25px",
    border: "none",
    padding: "5px 10px",
    backgroundColor: "#e1c2b0",
    color: '#665f70',
    margin: '5%',
    display: 'flex',
    justifyContent: "center",
    alignItems: 'center'
  },
  yogiLogo: {
    width: 'inherit'
  },
  yogiBlobStyle: {
    width: width<500 ? '280px': '432px',
    height: width < 500 ? '100%' : '191px',
    position: 'absolute',
    top:width<500? '-38%': '-102%',
    right: width < 500 ? 'unset' : '20px',
    backgroundImage: `url(${yogiBlob})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  yogiBlobTextStyle: {
    width: '38%',
    textAlign: 'center',
    fontSize: width<500?'13px':'20px',
    fontWeight: 'bolder',
    color:'black',
  },
  addPostToggler: {
    display: 'flex',
    transform: 'rotate(90deg)',
    fontSize: '20px',
    /* padding: 4px 8px; */
    backgroundColor: 'rgb(77, 75, 98)',
    borderRadius: '50%',
    color: 'rgb(225, 194, 176)',
    fontWeight: 'bolder',
    width: '35px',
    height: '33px',
    justifyContent: 'center'
  }
};
