import { graphql } from 'react-apollo-temp';
import { unreadNotificationCountQuery } from '../../graphql/unread-notifications-count.query';

export const withUnreadNotificationsCount = graphql(unreadNotificationCountQuery, {
  name: 'unreadNotifications',
  options: ({ currentUser}) => {
    return {
      variables: {
        userId: currentUser ? currentUser.id : null,
      },
      fetchPolicy: 'network-only'
    }
  },
  props: ({ unreadNotifications }) => {
    if (!unreadNotifications || unreadNotifications.loading || unreadNotifications.error) {
      return {
        unreadNotificationsCount: 0,
      };
    }

    return {
      unreadNotificationsCount: unreadNotifications.unreadNotificationsCount,
    };
  },
});
