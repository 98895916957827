import { connect } from 'react-redux'
import { graphql } from 'react-apollo-temp'
import { compose } from 'redux'
import gql from 'graphql-tag'
import update from 'immutability-helper'
import { ChatScreenView } from './chat-screen.rn'
import { chatNavigate } from '../../chat.actions'
import { chatQuery } from '../../graphql/chat-data.query'
import { sendMessageMutation } from '../../graphql/send-message.mutation'
import { notifyChatReadStatusMutation } from '../../graphql/notify-chat-read-status.mutation'
import { addFriendsToChatMutation } from '../../graphql/add-friends-to-chat.mutation'
import { withCreateChatMutation } from '../../../common/components/create-new-chat.container'
import { withErrorHandler } from '../../../common/hoc/error-handler.rn'
import { toggleComponent } from '../../../router/router.actions'
import { toggleUserProfileModal } from '../../../profile/profile.actions'
// import { withPermissionsState } from '../../../notification/hoc/with-permissions-state';
import { totalUnreadCountQuery } from '../../graphql/unread-count.query'
import { openImgModal } from '../../../router/router.actions'
import { setAlert } from '../../../alert/alert.actions'
import { chatMessageAddedSubscription } from '../../graphql/message-added.subscription'
import { closeWindowedChat } from '../../../windowedChat/windowedChat.actions'

const mapStateToProp = ({ chat, auth, navigation }) => ({
  chat: chat.currentChat,
  currentUser: auth.currentUser,
  currentComponent: navigation.navigationComponents.component,
  prevComponent: navigation.navigationComponents.prevComponent,
})

const mapDispatch = (dispatch) => ({
  close: () => dispatch(chatNavigate(null)),
  closeWindowedChat: () => dispatch(closeWindowedChat()),
  setChat: (chat) => dispatch(chatNavigate(chat)),
  toggleComponent: (component) => dispatch(toggleComponent(component)),
  onItemPress: (user) => dispatch(toggleUserProfileModal(!!user, user.id)),
  openImgModal: (img) => dispatch(openImgModal(img)),
  setAlert: (message) => dispatch(setAlert(message, 'success')),
})

const withState = connect(mapStateToProp, mapDispatch)

let subscribed = false

const withChatData = graphql(chatQuery, {
  name: 'chatData',
  skip: ({ chat }) => !chat || !chat.id,
  options: ({ chat, currentUser }) => ({
    variables: chat ? { userId: currentUser ? currentUser.id : null, id: chat.id, cursor: null, withMe: false } : {},
    fetchPolicy: 'network-only',
  }),
  props: ({ chatData, ownProps }) => ({
    chatData,
    loadOlder: () => {
      try {
        const { messages } = chatData.chat.messages
        if (!messages || messages.length === 0) {
          return null
        }
        return chatData.fetchMore({
          variables: {
            cursor: messages[0].id,
            id: ownProps.chat.id,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (fetchMoreResult && fetchMoreResult.chat) {
              return update(prev, {
                chat: {
                  messages: {
                    hasMore: { $set: fetchMoreResult.chat.messages.hasMore },
                    cursor: { $set: fetchMoreResult.chat.messages.cursor },
                    messages: {
                      $unshift: fetchMoreResult.chat.messages.messages,
                    },
                  },
                },
              })
            }
            return prev
          },
        })
      } catch (e) {
        return null
      }
    },
    subscribeToMore: () => {
      if (!subscribed) {
        subscribed = true

        const handle = chatData.subscribeToMore({
          document: chatMessageAddedSubscription,
          variables: {},
          updateQuery: (
            prev,
            {
              subscriptionData: {
                data: { messageAdded },
              },
            }
          ) => {
            console.log('messageAdded in contaienr', messageAdded)
            return update(prev, {
              chat: {
                messages: {
                  hasMore: { $set: true },
                  cursor: { $set: null },
                  messages: {
                    $push: [messageAdded],
                  },
                },
              },
            })
          },
        })

        return () => {
          handle()
          subscribed = false
        }
      }
      return null
    },
  }),
})

const withNotifyChatReadStatus = graphql(notifyChatReadStatusMutation, {
  props: ({ ownProps, mutate }) => ({
    notifyReadStatus: (userId, chatId, messageId) =>
      mutate({
        variables: {
          userId,
          chatId,
          messageId,
        },
        refetchQueries: [{ query: totalUnreadCountQuery, variables: { userId } }],
        update: (store, { data: notifyChatReadStatus }) => {
          try {
            const chatFragment = gql`
              fragment ChatFields on Chat {
                id
                unreadMessagesCount
              }
            `
            const identifier = `Chat:${chatId}`

            const chatState = store.readFragment({
              fragment: chatFragment,
              id: identifier,
            })

            chatState.unreadMessagesCount = notifyChatReadStatus.notifyChatReadStatus.chat.unreadMessagesCount

            store.writeFragment({
              fragment: chatFragment,
              id: identifier,
              data: chatState,
            })
          } catch (e) {
            // nothing to do here
          }

          try {
            const unreadState = store.readQuery({
              query: totalUnreadCountQuery,
              variables: {},
            })

            unreadState.unreadChatMessagesCount = notifyChatReadStatus.notifyChatReadStatus.unreadChatMessagesCount

            store.writeQuery({
              query: totalUnreadCountQuery,
              variables: {},
              data: unreadState,
            })
          } catch (e) {
            // nothing to do here
          }
        },
      }),
  }),
})

const withAddFriendsMutation = graphql(addFriendsToChatMutation, {
  props: ({ ownProps, mutate }) => ({
    addFriends: (userId, chatId, users) =>
      mutate({
        variables: {
          userId,
          chatId,
          usersIds: users.map((user) => user.id),
        },
        // refetchQueries: ['chat']
      }),
  }),
})

const withSendMessageMutation = graphql(sendMessageMutation, {
  props: ({ ownProps, mutate }) => ({
    sendMessage: (userId, messageType, content, chatId, imageData = null) => {
      return mutate({
        variables: {
          userId,
          chatId,
          messageType,
          ...(messageType === 'IMAGE' ? { imageData: content } : { content }),
        },
        // optimisticResponse: {
        //   __typename: 'Mutation',
        //   sendMessage: {
        //     id: messageId,
        //     done: messageType !== 'IMAGE',
        //     author: {
        //       __typename: 'User',
        //       ...ownProps.currentUser,
        //       profilePictureThumbUrl: ownProps.currentUser.profilePictureUrl,
        //       canRemoveConnection: false,
        //       friendRequestStatus: null,
        //       friendsSince: null,
        //       appSupportChat: true,
        //       userYoutubeVideoId: null
        //     },
        //     createdAt: moment().format('LT'),
        //     createdAtDay: moment().format('ll'),
        //     serverMessage: false,
        //     messageType,
        //     content: messageType === 'IMAGE' ? imageData : content,
        //     imageThumb: imageData,
        //     __typename: 'ChatMessage'
        //   }
        // },
        // update: (store, { data: { sendMessage } }) => {
        //   const chatState = store.readQuery({
        //     query: chatQuery,
        //     variables: {
        //       id: chatId,
        //       cursor: null
        //     }
        //   });

        //   if (
        //     !chatState.chat.messages.messages.find(m => m.id === sendMessage.id)
        //   ) {
        //     const messages =
        //       messageType === 'TEXT'
        //         ? chatState.chat.messages.messages
        //         : chatState.chat.messages.messages.filter(
        //             message => !message.id.startsWith('temp_')
        //           );
        //     messages.push({
        //       ...sendMessage,
        //       id: messageType === 'TEXT' ? sendMessage.id : messageId,
        //       imageThumb: imageData,
        //       done: true
        //     });
        //     chatState.chat.messages.messages = messages;

        //     store.writeQuery({
        //       query: chatQuery,
        //       variables: {
        //         id: chatId,
        //         cursor: null
        //       },
        //       data: chatState
        //     });
        //   }

        //   const myChatsState = store.readQuery({
        //     query: myChatsQuery
        //   });

        //   const currentChatIndex = myChatsState.chats.findIndex(
        //     chat => chat.id === chatId
        //   );

        //   myChatsState.chats[currentChatIndex] = {
        //     ...myChatsState.chats[currentChatIndex],
        //     lastUpdate: String(Date.now()),
        //     lastMessage: {
        //       __typename: 'ChatMessage',
        //       messageType,
        //       content: sendMessage.content,
        //       createdAt: sendMessage.createdAt
        //     }
        //   };

        //   store.writeQuery({
        //     query: myChatsQuery,
        //     data: myChatsState
        //   });
        // }
      })
    },
  }),
})

const withError = withErrorHandler({
  apollo: ['chatData'],
})

export const ChatScreen = compose(
  withState,
  withChatData,
  withCreateChatMutation,
  withAddFriendsMutation,
  withSendMessageMutation,
  withNotifyChatReadStatus,
  withError
  // withPermissionsState,
)(ChatScreenView)
