import React from 'react';
import ChatBot from 'react-simple-chatbot';
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment'
import { ThemeProvider } from 'styled-components';
import Yogi from '../assets/images/Yogi.png'
import { apolloClient } from '../apollo-subs';
import { createYocasReminderMutation } from './graphql/createReminder.mutation';

const width = window.innerWidth

const theme = {
    background: '#f5f8fb',
    headerBgColor: '#f2e8dc',
    headerFontColor: 'black',
    headerFontSize: '15px',
    botBubbleColor: '#f2e8dc',
    botFontColor: 'black',
    userBubbleColor: '#fff',
    userFontColor: '#black',
};

const selectDateSubmitBtn = {
    backgroundColor: 'transparent',
    color: "black",
    border: '1px solid #978689'
}

const LinksToYogaSessionsContainer = {
    display: 'flex',
    flexDirection: 'column'
}

const LinkStyle = {
    color: 'blue',
    borderBottom: '1px solid blue',
    width: 'fit-content'
}

const hideYogi = {
    display: 'none'
}

const avatarStyle={
    objectFit:'contain'
}

const Chatbot = props => {
    
    const firstSelectionNo = [
        {
            id: 'noAnswer1',
            message: `We have fantastic news! 
            Scientific research has shown that yoga can help relieve side effects from cancer and its treatment. 
            Would you like to know more about it? `,
            trigger: 'yesNotNow1',
        },
        {
            id: 'yesNotNow1',
            options: [
                { value: 'yes', label: 'Yes', trigger: 'knowMoreAboutIt' },
                { value: 'not now', label: 'Not now', trigger: 'Understandable' },
            ],
        },
        {
            id: 'Understandable',
            message: `Understandable!\n
            You can come back any time to learn how yoga can help you during your survivorship!`,
            trigger: 'weKnowYouAreBusy',
        },
        {
            id: 'knowMoreAboutIt',
            message: `
            Click to learn the “Effects of Yoga on Cancer-related Side Effects”\n
            Click to listen to ”Scientists Share Their Research Findings on Yoga”\n
            Click to listen to “Oncologists and Care Providers’ Recommendations”
            `,
            trigger: 'trySomeYogaNow',
        },
        {
            id: 'trySomeYogaNow',
            message: `
            Would you like to try some yoga now?
            `,
            trigger: 'yesNo2',
        },
        {
            id: 'yesNo2',
            options: [
                { value: 'yes', label: 'Yes', trigger: 'YesAnswer1' },
                { value: 'no', label: 'No', trigger: 'weKnowYouAreBusy' },
            ],
        },
    ]

    const firstSelectionYes = [
        {
            id: 'YesAnswer1',
            message: 'What would you like yoga to help you with today?',
            trigger: 'symptomSelection',
        },
        {
            id: 'symptomSelection',
            options: [
                { value: 'Difficulty Sleeping', label: 'Difficulty Sleeping', trigger: 'tellUsAboutYourSeverity' },
                { value: 'Fatigue', label: 'Fatigue', trigger: 'tellUsAboutYourSeverity' },
                { value: 'Memory Difficulty ', label: 'Memory Difficulty ', trigger: 'tellUsAboutYourSeverity' },
                { value: 'Pain', label: 'Pain', trigger: 'tellUsAboutYourSeverity' },
                { value: 'Low Mood', label: 'Low Mood', trigger: 'tellUsAboutYourSeverity' },
                { value: 'Depression', label: 'Depression', trigger: 'tellUsAboutYourSeverity' },
                { value: 'Anxiety', label: 'Anxiety', trigger: 'tellUsAboutYourSeverity' },
                { value: 'Physical Function', label: 'Physical Function', trigger: 'tellUsAboutYourSeverity' },
                { value: 'Other', label: 'other', trigger: 'tellUsAboutYourSeverity' },
            ],
        },
        {
            id: 'tellUsAboutYourSeverity',
            message: 'Please tell us about the severity of your {previousValue}: [1=mild, 10= as severe as you can imagine]',
            trigger: 'symptomSeverity',
        },
        {
            id: 'symptomSeverity',
            options: [
                { value: '1', label: '1', trigger: 'thankYouImSorry' },
                { value: '2', label: '2', trigger: 'thankYouImSorry' },
                { value: '3', label: '3', trigger: 'thankYouImSorry' },
                { value: '4', label: '4', trigger: 'thankYouImSorry' },
                { value: '5', label: '5', trigger: 'thankYouImSorry' },
                { value: '6', label: '6', trigger: 'thankYouImSorry' },
                { value: '7', label: '7', trigger: 'thankYouImSorry' },
                { value: '8', label: '8', trigger: 'thankYouImSorry' },
                { value: '9', label: '9', trigger: 'thankYouImSorry' },
                { value: '10', label: '10', trigger: 'thankYouImSorry' },
            ],
        },
        {
            id: 'thankYouImSorry',
            component: <ThankYouAndImSorry />,
            asMessage: true,
            trigger: 'yesNo3',
        },
        {
            id: 'yesNo3',
            options: [
                { value: 'yes', label: 'Yes', trigger: 'awesomeChooseYogaClass' },
                { value: 'no', label: 'Not now', trigger: 'weKnowYouAreBusy' },
            ],
        },
    ]

    const afterLastYes = [
        {
            id: 'awesomeChooseYogaClass',
            message: `
            Awesome! Click on the yoga class you prefer! Enjoy!
            `,
            trigger: 'linksToYogaClasses',
        },
        {
            id: 'linksToYogaClasses',
            component: (
                <LinksToYogaSessions {...props} />
            ),
            trigger: 'didYouEnjoy',
        },
        {
            id: 'didYouEnjoy',
            message: `
            Awesome! Click on the yoga class you prefer! Enjoy!
            `,
            end: true
        },
    ]

    const afterLastNo = [
        {
            id: 'weKnowYouAreBusy',
            message: `
            We know you are busy. Would you like to schedule your yoga at different time?
            `,
            trigger: 'yesNotSure',
        },
        {
            id: 'yesNotSure',
            options: [
                { value: 'yes', label: 'Yes', trigger: 'whatDayAndTime' },
                { value: 'notSure', label: 'I am not sure', trigger: '4' },
            ],
        },
        {
            id: 'whatDayAndTime',
            message: `
            What day and time would you like to do your yoga? 
            `,
            trigger: 'date',
        },
        //TimePickerInChatbot
        {
            id: 'date',
            component: (
                <TimePickerInChatbot currentUser={props.currentUser} />
            ),
            asMessage: true,
            waitAction: true,
            trigger: '2',
        },

    ]

    const steps = [
        {
            id: '0',
            message: `${props.currentUser.talkedWithYogi ? 'Welcome back ' : ''}${props.currentUser.firstName}, Everyone can do yoga. Do you know yoga can help you manage the side effects of cancer and its treatments?`,
            trigger: 'yesNo1',
        },
        {
            id: 'yesNo1',
            options: [
                { value: 'yes', label: 'Yes', trigger: 'YesAnswer1' },
                { value: 'no', label: 'No', trigger: 'noAnswer1' },
            ],
        },
        ...firstSelectionNo,
        ...firstSelectionYes,
        ...afterLastYes,
        ...afterLastNo,
        {
            id: '2',
            message: `
            Congratulations, yogi!
            Your upcoming yoga session at {previousValue}
            `,
            end: true,
        },
        {
            id: '3',
            message: 'END',
            end: true,
        },
        {
            id: '4',
            component: <ItsOK />,
            asMessage: true,
            end: true
        }
    ];
    return (
        <div style={width < 500 ? { width: '90%', margin: 'auto' }: {
                position: 'fixed',
                zIndex: 100,
                bottom: '20px',
                right: '20px',
            }}>
            <ThemeProvider theme={theme}>
                {
                    !props.isYogiOpen ? 
                    <div></div>
                    : 
                    props.profilePictureUrl !== null ? 
                    <ChatBot botAvatar={Yogi} userAvatar={props.profilePictureUrl} avatarStyle={avatarStyle}  headerTitle='Yogi' steps={steps} />
                    :
                    <ChatBot botAvatar={Yogi}  avatarStyle={avatarStyle}  headerTitle='Yogi' steps={steps} />
                }
            </ThemeProvider>
        </div>
    )
}

const TimePickerInChatbot = props => {
    const [selectedDate, setSelectedDate] = React.useState(null);
    const { steps, triggerNextStep } = props;
    const { symptomSelection } = steps;

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const submitDate = () => {
        const selectedDateMoment = moment(selectedDate)
        let messageToNextStr = `${selectedDateMoment.format('HH:mm')}
        on ${selectedDateMoment.format('MM/DD/YYYY')}`
        if (symptomSelection) {
            messageToNextStr += ` will help your ${symptomSelection.value}.`
        }
        else {
            messageToNextStr += `.`
        }
        triggerNextStep({ value: messageToNextStr, trigger: '2' })

        let reminder = {
            userId: props.currentUser.id,
            name: props.currentUser.name,
            phoneNumber: props.currentUser.phoneNumber,
            notification: messageToNextStr,
            time: {
                timeType: selectedDate,
                index: true
            }

        }

        console.log('reminder =>', reminder)

        apolloClient.mutate({
            mutation: createYocasReminderMutation,
            variables: {
                userId: props.currentUser.id,
                reminder
            }
        })
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around">
                <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Date picker"
                    autoOk={true}
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                <KeyboardTimePicker
                    margin="normal"
                    id="time-picker"
                    label="Time picker"
                    variant="inline"
                    ampm={false}
                    autoOk={true}
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change time',
                    }}
                />
                <button style={selectDateSubmitBtn} onClick={submitDate}>Save</button>
            </Grid>
        </MuiPickersUtilsProvider>
    )
}

const LinksToYogaSessions = props => {
    const { steps, history } = props;

    const linkCliked = (yocasType, duration) => {
        history.push({
            pathname: 'videos',
            state: {
                yocasType: yocasType,
                duration: duration
            }
        });
    }

    return (
        <div style={LinksToYogaSessionsContainer}>
            <span style={LinkStyle} onClick={() => { linkCliked('session', '5-10') }}>Link to a 5-10 minutes yoga session</span>
            <span style={LinkStyle} onClick={() => { linkCliked('session', '15-30') }}>Link to a 15-30 minutes yoga session</span>
            <span style={LinkStyle} onClick={() => { linkCliked('session', '60-75') }}>Link to a 60-75 minutes yoga session</span>
        </div>
    )
}


const ItsOK = props => {
    const { steps } = props;
    const { symptomSelection } = steps;

    if(symptomSelection !== null && symptomSelection !== undefined) return (
    <div>
       That’s ok.

        We are here for
        you all-day
        everyday.

        You can come
        back whenever
        you need help
        with 
        your {symptomSelection.value}
    </div>
    )
    else return (
    <div>
       That’s ok.

        We are here for
        you all-day
        everyday.

        You can come
        back whenever
        you need help
    </div>
    )
}

const ThankYouAndImSorry = props => {
    const { steps } = props;
    const { symptomSelection } = steps;

    return (
        <div>
            Thanks! I am sorry that you are having trouble with {symptomSelection.value}.
        Even just a few minutes of yoga can improve your {symptomSelection.value}!
                                                Would you like to do some yoga now?
        </div>
    )
}


export default Chatbot
