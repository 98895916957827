import React, { Fragment, useEffect, useState } from 'react';
import UserInfo from './user-info';
import { UserPhotos } from '../gallery/user-photos.container';
import { LangData } from '../../../assets/locales/localization';
import Fade from 'react-reveal/Fade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import {
  faUserTimes,
  faTimes,
  faUserPlus,
  faUserClock,
  faUserFriends
} from '@fortawesome/free-solid-svg-icons';
import { Redirect } from 'react-router';
import { Theme } from '../../../common/providers/colors';
import LoadingSpinner from '../../../common/components/loading-spinner.rn';

const urls = {
  instagram: username => `instagram://user?username=${username}`,
  snapchat: username => `snapchat://add/${username}`,
  twitter: username => `twitter://user?screen_name=${username}`
};

const width = window.innerWidth;

const UserProfileModalView = props => {
  const [user, setUser] = useState(null);
  const [renderStatus, setRenderStatus] = useState(false);
  const [userStatus, setUserStatus] = useState(null);

  useEffect(() => {
    if (props.user && !userStatus) setUserStatus(props.user.friendRequestStatus);
    if (props.user) setUser(props.user);
  }, [props.user])

  const onSocialClick = (socialType, username) => {
    const link = urls[socialType](username);
  };

  const chat = () => {
    if( width < 500 ) {
      props.navigateToChat(props.user, () => props.toggleModal(true, null));
      props.toggleComponent('ChatScreen');
      props.history.push('chatScreen');
    }else {
      props.openChat();
      props.chatWindowed(props.user)
    }
  };

  const renderFriendView = user => {
    // if(!userStatus) return (
    //   <LoadingSpinner/>
    // )
    if (userStatus === 'APPROVED') {
      return (
        <div style={styles.buttonContainer}>
          {/* <div
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              width: '46%',
              cursor: 'pointer',
            }}
            onClick={() => {chat(); props.closeProfileModal()}}
          >
            <CustomButton>Chat</CustomButton>
          </div> */}

          {user.canRemoveConnection ? (
            <div style={{ marginLeft: 15 }} onClick={() => removeConnection()}>
              <CustomButton>Remove Friend</CustomButton>
            </div>
          ) : null}
        </div>
      );
    }

    return null;
  };

  const renderPendingAndIgnored = user => {
    // if(!userStatus) return (
    //   <LoadingSpinner />
    // )

    const ignored = userStatus === 'IGNORED';
    const pending = userStatus === 'PENDING';

    if (ignored || pending) {
      return (
        <div style={styles.buttonContainer}>
          <div
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              width: '100%'
            }}
          >
            <button style={styles.connectBtn} onClick={() => approve()}>
              <FontAwesomeIcon
                icon={faUserFriends}
                style={styles.iconPlusStyle}
              />

              {LangData.userProfileModal.approve}
            </button>

            {!ignored ? (
              <div
                style={{
                  padding: 4,
                  alignSelf: 'stretch',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  fontSize: 13,
                  fontWeight: 'bold'
                }}
                onClick={() => {
                  ignore();
                }}
              >
                {LangData.userProfileModal.ignore}
                <FontAwesomeIcon
                  icon={faUserTimes}
                  style={{ marginLeft: '2%' }}
                />
              </div>
            ) : (
              <p
                style={{
                  fontSize: 13,
                  color: Theme.TEXT_COLOR,
                  marginLeft: 5
                }}
              >
                {LangData.userProfileModal.ignored}
              </p>
            )}
          </div>
        </div>
      );
    }

    return null;
  };

  const renderNonFriend = user => {
    if (!userStatus) {
      return (
        <div style={styles.buttonContainer}>
          <button
            style={styles.connectBtn}
            onClick={() => props.connect(props.currentUser, props.user.id)}
          >
            <FontAwesomeIcon icon={faUserPlus} style={styles.iconPlusStyle} />
            {LangData.userProfileModal.connect}
          </button>
        </div>
      );
    }
    
    return null;
  };

  const removeConnection = () => {
    props.removeConnection(props.currentUser, props.user.id);
  };

  const renderRequestSent = user => {
    if(!userStatus) return

    if (userStatus === 'SENT') {
      return (
        <div style={styles.buttonContainer}>
          <button
            disabled
            style={Object.assign({ opacity: 0.5 }, styles.connectBtn)}
          >
            <FontAwesomeIcon icon={faUserClock} style={styles.iconPlusStyle} />
            {LangData.userProfileModal.request}
          </button>
          <div
            style={{ marginLeft: 15 }}
            onClick={() => {
              removeConnection();
              setRenderStatus(!renderStatus);
            }}
          >
            <FontAwesomeIcon icon={faUserTimes} />
          </div>
        </div>
      );
    }

    return null;
  };

  // const renderGalleryHeader = () => {
  //   return (
  //     <div style={styles.galleryHeader}>
  //       <div style={styles.hr} />
  //       <p style={styles.galleryHeaderTxt}>
  //         {props.user.firstName + LangData.userProfileModal.gallery}
  //       </p>
  //       <div style={styles.hr} />
  //     </div>
  //   );
  // };

  const approve = () => {
    props.respond(props.currentUser.id, 'APPROVED', props.user.id).then(() => {
      props.setAlert('Connection request approved!');
      props.toggleComponent('Friends');
      props.history.push('/');
    });
  };

  const ignore = () => {
    props.respond(props.currentUser.id, 'IGNORE', props.user.id).then(() => {
      alert(LangData.userProfileModal.connection);
      props.setAlert(LangData.userProfileModal.connection);
    });
  };

  const shouldDisplayYouTube = () => {
    return !!props.user.userYoutubeVideoId;
  };

  if (!props.currentUser) {
    return <Redirect to='/login' />;
  }

  if(!user){
    return <div style={styles.pageContainer}><LoadingSpinner /></div>;
  }

  const onModalClose = () => {
    if(width < 500){
      if (props.prevComponent !== 'ImageGalleryViewerModal') {
        props.toggleComponent(props.prevComponent);
      }

      if(props.fromSug !== null && props.fromSug === true) {
        props.toggleModal()
        return
      }
      if (
        props.prevComponent === 'Chat' ||
        props.prevComponent === 'News_Feed' ||
        props.prevComponent === 'Notifications'
      ) {
        props.history.push('/');
      }

      if (props.prevComponent === 'ChatScreen') {
        props.history.push('chatScreen');
      }
      if (props.prevComponent === 'Profile') {
        props.history.push('profile');
      }
      if (props.prevComponent === 'Courses') {
        props.history.push('courses');
      }
      if (props.prevComponent === 'Friends') {
        props.history.push('/');
      }

      props.toggleModal(false);
    }else {
      if (props?.history?.location?.pathname === '/userProfile') {
        props.history.goBack();
      } else {
        props.closeProfileModal();
      }
    }
  }

  return (
    <div style={styles.pageContainer}>
      {props.isOpen ? (
        <Fade bottom>
          <Fragment>
            <div style={styles.optionsRow}>
              <RowOptions onClick={onModalClose}>
                <RowIcon
                  icon={faTimes}
                  style={width > 500 ? styles.closeIcon : {}}
                />
                <span style={styles.closeLabel}>Close</span>
              </RowOptions>
            </div>

            <UserInfo
              user={props.user}
              onSocialClick={(type, username) => onSocialClick(type, username)}
              history={props.history}
            />
            {renderStatus ? (
              <Fragment>
                {renderFriendView(props.user)}
                {renderPendingAndIgnored(props.user)}
                {/* {renderNonFriend(props.user)} */}
                {renderRequestSent(props.user)}{' '}
              </Fragment>
            ) : (
              <Fragment>
                {renderFriendView(props.user)}
                {renderPendingAndIgnored(props.user)}
                {/* {renderNonFriend(props.user)} */}
                {renderRequestSent(props.user)}
              </Fragment>
            )}

            {/* {!shouldDisplayYouTube() ? renderGalleryHeader() : null} */}

            {/* <div style={styles.section}>
              {shouldDisplayYouTube() ? null : (
                // <YouTube
                //   videoId={props.user.userYoutubeVideoId}
                //   play={false}
                //   fullscreen={true}
                //   style={{ alignSelf: 'stretch', height: 300 }}
                // />
                <UserPhotos {...props} user={props.user} currentUser={props.currentUser} />
              )}
            </div> */}
          </Fragment>
        </Fade>
      ) : null}
    </div>
  );
};

export default UserProfileModalView;

const SPACE_SIZE = 25;
const ICON_SIZE = 20;

const styles = {
  closeIcon: {
    cursor: 'pointer',
    width: '20px',
    height: '20px',
  },
  closeLabel: {
    cursor: 'pointer',
    fontWeight: 'bold'
  },
  pageContainer: {
    minHeight: window.innerHeight,
    backgroundColor: '#fff'
  },
  optionsRow: {
    display: 'flex',
    padding: width < 500 ? '5%' : '20px',
    justifyContent: 'space-between'
  },
  buttonContainer: {
    justifyContent: 'space-between',
    display: 'flex',
    padding: '0 5%',
    color: Theme.TEXT_COLOR
  },
  mainContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center'
  },
  innerContainer: {},
  topBar: {
    flex: 0,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  closeBtn: {
    flex: 0,
    width: ICON_SIZE,
    height: ICON_SIZE
  },
  hr: {
    flex: 1,
    height: 2,
    backgroundColor: Theme.TEXT_COLOR
  },
  menuContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  section: {
    marginTop: SPACE_SIZE,
    flex: 1
  },
  textBtnStyle: {
    marginLeft: 5,
    backgroundColor: 'transparent',
    overflow: 'hidden',
    textAlign: 'center',
    fontSize: 13
  },
  galleryHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    padding: '0 3%'
  },
  galleryHeaderTxt: {
    marginHorizontal: SPACE_SIZE,
    color: Theme.TEXT_COLOR,
    fontSize: 13,
    padding: '0 2%'
  },
  connectBtn: {
    flexGrow: '1',
    borderRadius: '20px',
    color: Theme.SECONDARY_TEXT_COLOR,
    backgroundColor: Theme.SECONDARY_BACKGROUND_COLOR,
    border: 'none',
    padding: '2%',
    fontWeight: 'bold',
    fontFamily: 'inherit',
    WebkitBoxShadow: '0px 0px 25px -10px rgba(0,0,0,0.75)',
    MozBoxShadow: '0px 0px 25px -10px rgba(0,0,0,0.75)',
    boxShadow: '0px 0px 25px -10px rgba(0,0,0,0.75)'
  },
  iconPlusStyle: {
    marginRight: '5px'
  }
};

const RowIcon = styled(FontAwesomeIcon)`
  font-size: ${window.innerWidth / 18}px;
`;

const RowOptions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${Theme.TEXT_COLOR};
  &:hover {
    color: #286cc0;
  }
  &:focus {
    color: #286cc0;
  }
`;

const CustomButton = styled.button`
  background-color: ${Theme.DARK_BLUE_COLOR};
  justify-content: center;
  align-items: center;
  border: none;
  color: ${Theme.WHITE_COLOR};
  font-weight: bold;
  border-radius: 15px;
  padding: 1% 4%;
  height: 30px;
  white-space: nowrap;
  text-align: center;

  &:focus {
    outline: none;
    border: none;
  }
`;
