import { FindChatsBarView } from './find-chats-bar.rn';
import { connect } from 'react-redux';
import { closeChatListModal, openCreateChatModal, setSearchTerm } from '../../chat.actions';

const mapStateToProp = ({ chat }) => ({
  searchTerm: chat.searchTerm,
});

const mapDispatch = dispatch => ({
  setSearchTerm: search => dispatch(setSearchTerm(search)),
  openCreateChatModal: () => dispatch(openCreateChatModal()),
  closeChatModal: () => dispatch(closeChatListModal()),
});

const withState = connect(mapStateToProp, mapDispatch);

export const FindChatsBar = withState(FindChatsBarView);
