import React, { Component } from 'react';
import { feedItemByIdQuery } from '../../news-feed/graphql/feed-item-by-id.query';
import { apolloClient } from '../../apollo-subs';
import LoadingSpinner from '../../common/components/loading-spinner.rn';
import { Theme } from '../../common/providers/colors';
import { FeedNewsItem } from '../../news-feed/components/feed-list/feed-news-item/feed-news-item-container';
import { refreshByUserId } from '../../account/account.util';
import { NewFeedItemUpdatedSubscription } from  '../../news-feed/graphql/news-feed-item-updated.subscription';

const width = window.innerWidth

class SinglePostView  extends Component  {
    constructor(props) {
        super(props)

        this.state = {
            post: null
        }
    }

    subscribeToUpdates = () => {
        apolloClient.subscribe({
          query: NewFeedItemUpdatedSubscription,
          variables: {}
        })
        .subscribe({
          next: (data) => {
            console.log('item updated subs =>', data)
            let updatedData = data.itemUpdated
            if(updatedData !== null){
              if(this.state.post.id.toString() === updatedData.id.toString()){
                  this.setState({ post: updatedData })
              }
            }
          }
        })
      }


    componentWillMount = async () => {
        this.subscribeToUpdates()
        console.log('props state', this.props.match.params)
        const { postId, userId } = this.props.match.params
        let user = await refreshByUserId(userId);
        console.log('user1 =>', user)
        if (user) {
          this.props.setUser(user);
          console.log('update user!')
        }
        apolloClient.query({
            query: feedItemByIdQuery,
            variables: {
                id: postId
            }
        })
        .then(res => {
            console.log('got post =>', res.data.feedItem)
            this.setState({ post: res.data.feedItem })
        })
    }

    openImageModal = imageUrl => {
        this.props.openImgModal(imageUrl);
        this.props.history.push('/fullScreenImageModal');
        this.props.toggleComponent('FullScreenImageModal');
      };


    render() {
        if(this.state.post === null) return (
            <LoadingSpinner />
        )
        else return (
            <div>
                <div style={styles.mainContainer}>
                 <div style={styles.optionsRow}>
                        <div
                            onClick={() => {
                                this.props.history.push('/');
                                // this.props.toggleComponent('Notifications');
                            }}
                        >
                            <span style={styles.goBack}>{'< Back'}</span>
                        </div>
                </div>
                <div style={styles.postCont}>
                    <div
                    id='row223'
                    key={this.state.post.id}
                    style={
                        this.state.post.author && this.state.post.author.admin
                        ? styles.itemContainerAdmin
                        : styles.itemContainer
                    }
                    disabled={this.state.post.__typename === 'NewsFeedPollItem'}
                    onClick={() => this.handleItemPressed(this.state.post)}
                    >
                    {this.state.post.author && this.state.post.author.admin && (
                        <p style={{ textAlign: 'center', fontWeight: 'bold' }}>
                        Yocas Team
                        </p>
                    )}
                        <FeedNewsItem
                            onPicClicked={imageUrl => this.openImageModal(imageUrl)}
                            news={this.state.post}
                            history={this.props.history}
                        />
                    </div>
                  
                </div>
                </div>
            </div>
        )
    }

}

export default SinglePostView;


const styles = {
    postCont: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        marginTop: '5vh',
    },
    mainContainer: {
      width: window.innerWidth,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minHeight: window.innerHeight,
      backgroundImage: 'linear-gradient(to bottom, rgb(231,228,216,0.95), rgb(237,204,181,0.95))',
      color: Theme.TEXT_COLOR
    },
    optionsRow: {
        display: 'flex',
        padding: '5%',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: Theme.TEXT_COLOR,
        fontWeight: 'bold',
        width: '100%',
    },
    goBack:{
        borderRadius:'10px',
        display: 'block',
        marginTop: width < 500 ? 0 : '10px',
        position:'absolute',
        textAlign:'center',
        cursor: 'pointer',
        backgroundColor:'#4D4B62',
        color:'floralwhite',
        whiteSpace: 'nowrap',
        padding: "3px 7px"
    },
    itemContainer: {
        width:width<500?'100%':'70%',
        margin:width<500?'2%':'2% auto',
        padding: '10px',
        backgroundColor: '#f3f2ef',
        borderRadius: '20px',
        position: 'relative'
        // opacity: 0.8
      },
      itemContainerAdmin: {
        width:width<500?'unset':'70%',
        padding: '10px',
        backgroundColor: '#eae9e9',
        margin:width<500?'2%':'2% auto',
        borderRadius: '20px',
        position: 'relative'
      },
}
