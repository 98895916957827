import React, { useCallback, useEffect, useRef } from 'react'

export const SideModalWrapper = (props) => {
  const scrollHandler = (e) => {
    if (e.target.scrollHeight - e.target.offsetHeight - e.target.scrollTop < 20 ) {
      console.log('scrolled to bottom')
      props.setGetMore && props.setGetMore(true)
    }
  }

  return (
    <div style={styles.modal} onScroll={scrollHandler}>
      {props.children}
    </div>
  )
}

const styles = {
  modal: {
    position: 'absolute',
    width: '30vw',
    height: '50vh',
    right: '20px',
    top: '124px',
    zIndex: '11',
    borderRadius: '16px',
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.40)',
    overflowX: 'hidden',
    overflowY: 'auto',
    background: 'rgb(241, 233, 224)',
  },
}
