import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from '../../../common/components/loading-spinner.rn';

export class AutoSizeImage extends PureComponent {
  static propTypes = {
    source: PropTypes.any,
    maxDimension: PropTypes.number
  };

  constructor(props) {
    super(props);

    this.state = {
      height: null,
      width: null,
      loaded: false
    };
  }

  componentWillMount() {
    this.handleImage(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.source &&
      nextProps.source.uri &&
      this.props.source &&
      this.props.source.uri &&
      this.props.source.uri !== nextProps.source.uri
    ) {
      this.handleImage(nextProps);
    }
  }

  handleImage(props) {
    if (props.source && props.source.uri) {
      try {
        let image = new Image();
        image.src = props.source.uri;

        image.onload = () => {
          let originWidth = image.naturalWidth;
          let originHeight = image.naturalHeight;
          let relevantDimension = originWidth;
          if (originHeight > originWidth) {
            relevantDimension = originHeight;
          }
          const factor =
            relevantDimension / (this.props.maxDimension || relevantDimension);
          const newHeight = originHeight / factor;
          const newWidth = originWidth / factor;

          this.setState({
            height: newHeight,
            width: newWidth
          });
        };
      } catch (e) {
        // nothing...
      }
    }
  }

  render() {
    if (!this.props.source || this.props.source.uri === null) {
      return null;
    }

    const resultProps = Object.assign({}, this.props);
    delete resultProps.maxDimension;
    delete resultProps.style;

    return (
      <Fragment>
        <img
          alt=""
          style={
            !this.state.loaded
              ? { display: 'none' }
              : this.state.height && this.state.width
              ? {
                  height: this.state.height,
                  width: this.state.width,
                  marginBottom: 14,
                  borderRadius: 7
                }
              : {
                  marginBottom: 14,
                  height: 160,
                  width: 160,
                  borderRadius: 7
                }
          }
          src={this.props.source.uri}
          onLoad={() => this.setState({ loaded: true })}
        />
        {!this.state.loaded && (
          <div
            style={{
              marginBottom: 14,
              height: 160,
              width: 160,
              borderRadius: 7,
              position: 'relative'
            }}
          >
            <LoadingSpinner height='100%' width='100%' />
          </div>
        )}
      </Fragment>
    );
  }
}
