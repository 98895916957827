const config = {
  server: {
    protocol: 'https',
    host: 'be.yocas.life', //'31.133.102.222' // localhost
    port: 4000,
    path: '/graphql'
  },
  cleanStorage: false
};

// const config = {
//   server: {
//     protocol: 'http',
//     host: 'localhost', //'31.133.102.222' // localhost
//     port: 4000,
//     path: '/graphql'
//   },
//   cleanStorage: false
// };

export default config;
