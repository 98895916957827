import React from 'react';

import { MyNotifications } from "./my-notifications.container";
import { SideModalWrapper } from "../../main/side-modal-wrapper";

export const MyNotificationsModal = props => {
  return (
    <SideModalWrapper setGetMore = {props.setGetMore}>
      <MyNotifications {...props} />
    </SideModalWrapper>
  )
}
