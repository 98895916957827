import React from 'react';
import { useSelector } from "react-redux";

import { selectIsCreateChatModalOpen } from "../../chat.reducer";
import { NewChat } from "../new-chat/new-chat.container";


export const CreateChatModal = props => {
  const isModalOpen = useSelector(selectIsCreateChatModalOpen);

  return isModalOpen ? (
    <div style={styles.modal}>
      <NewChat inModal {...props} />
    </div>
  ) : null
}

const styles = {
  modal: {
    position: 'absolute',
    zIndex: '100000000',
    width: '80%',
    left: '10%',
    top: '120px',
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.40)',
  }
}
