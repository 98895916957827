import React, { Component, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import LoadingSpinner from '../../../common/components/loading-spinner.rn'
import { ProfilePictureNotificationItem } from './items/profile-picture-notification.rn'
import { SocialNetworkNotificationItem } from './items/social-network-notification.rn'
import { TextNotificationItem } from './items/text-notification.rn'
import { EventWallApprovalNotificationItem } from './items/event-wall-picture-approval-notification.rn'
import { UserGalleryNotificationItem } from './items/user-gallery-approval-notification.rn'
import { FeedEventModal } from '../../../news-feed/components/feed-event-modal/feed-event-modal.container'
import { classmateItemFragment } from '../../../classmates/graphql/classmate-item.fragment'
import debounce from 'lodash.debounce'
import EmptyNotice from '../../../common/components/empty-notice.rn'
import { LangData } from '../../../assets/locales/localization'
import { Theme } from '../../../common/providers/colors'
import { NotificationAddedSubscription } from '../../graphql/notification-added.subscription'
import { apolloClient } from '../../../apollo-subs'
import { NotificationUpdatedSubscription } from '../../graphql/notification-updated.subscription'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'

const width = window.innerWidth

export class NotificationsListView extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    notifications: PropTypes.array,
    loadOlder: PropTypes.func,
    reload: PropTypes.func,
    close: PropTypes.func,
    notifyReadStatus: PropTypes.func,
    client: PropTypes.any,
  }

  constructor(props) {
    super(props)

    this.state = {
      refreshing: false,
      userModal: null,
      profileModalOpen: false,
      eventModal: null,
      scrollPosition: 0,
      myNotifications: null,
      newExist: false,
      enterComponent: false,
      isNotified: false,
      unreadNotifications: [],
    }

    this.notifyReadStatus = debounce(this.notifyReadStatus.bind(this), 100)
  }

  subscribeToUpdates = () => {
    apolloClient
      .subscribe({
        query: NotificationUpdatedSubscription,
        variables: {},
      })
      .subscribe({
        next: (data) => {
          let updatedNotification = data.notificationUpdated
          if (updatedNotification !== null) {
            let currentId = localStorage.getItem('userId')
            if (currentId.toString() === updatedNotification.userId.toString()) {
              let idx = this.state.myNotifications.findIndex(
                (item) => item.id.toString() === updatedNotification.id.toString()
              )
              if (idx !== -1) {
                let cpy = [...this.state.myNotifications]
                cpy[idx] = updatedNotification
                this.setState({ myNotifications: cpy })
              }
            }
          }
        },
      })
  }

  subscribeToData = () => {
    apolloClient
      .subscribe({
        query: NotificationAddedSubscription,
        variables: {},
      })
      .subscribe({
        next: (data) => {
          let newItem = data.notificationAdded
          if (this.props.inComponent) {
            newItem.read = false
          }
          this.setState({ myNotifications: [newItem, ...this.state.myNotifications] })
        },
      })
  }

  loadMoreData() {
    this.props.loadOlder()
    this.props.setGetMore(false)
  }

  notifyReadStatus(userId, notificationId) {
    if (notificationId) {
      this.props.notifyReadStatus(userId, notificationId).catch(() => null)
      let cpy = [...this.state.myNotifications]
      const newItems = cpy.map((elem) => ({ ...elem, reed: true }))
      this.setState({ myNotifications: newItems })
    }
  }

  componentWillMount = () => {
    this.subscribeToData()
    this.subscribeToUpdates()
  }

  componentDidMount = () => {
    if (this.props.notifications && this.props.notifications.length > 0 && this.props.myNotifications === null) {
      this.setState({ myNotifications: this.props.notifications })
    }
  }

  componentDidUpdate = () => {
    if (this.props.inComponent && !this.state.enterComponent) {
      this.setState({ enterComponent: true })
      // setTimeout(() => {

      // }, 5000)
    }
    // if (!this.props.inComponent && this.state.enterComponent) {
    //   let currentId = localStorage.getItem('userId')
    //   if (this.state.myNotifications.length > 0 && !this.state.isNotified) {
    //     this.notifyReadStatus(currentId, this.state.myNotifications[0].id)
    //     this.setState({ isNotified: true })
    //   }
    // }
  }

  componentWillUnmount = () => {
    if (this.state.unreadNotifications.length) {
      const unread = this.state.unreadNotifications.concat().sort((item1, item2) => {
        if (item1.id > item2.id) {
          return -1
        } else {
          return 1
        }
      })
      const currentId = localStorage.getItem('userId')
      this.props.notifyReadStatus(currentId, unread[0].id)
    }
  }

  componentWillReceiveProps(newProps) {
    // console.log('here')
    // if (
    //   (newProps.notifications && this.state.myNotifications === null) ||
    //   (newProps.notifications && this.state.myNotifications.length === 0)
    // ) {
    //   console.log(newProps)
    //   this.setState({ myNotifications: newProps.notifications })
    // }
    if (newProps.notifications?.length) {
      let uniqueNotifications = [...new Map(newProps.notifications.map((item) => [item['id'], item])).values()].sort(
        (item1, item2) => {
          if (!item1.read && item2.read) {
            return -1
          } else {
            return 1
          }
        }
      )
      let unread = uniqueNotifications.filter((item) => !item.read)
      // console.log('new unique notifications')
      // console.log('unread', unread)
      this.setState({ unreadNotifications: unread })
      this.setState({ myNotifications: uniqueNotifications })
    }
    if (newProps.getMore) {
      // console.log('here we gonna call to fetch older')
      this.loadMoreData()
    }

    if (this.state.userModal) {
      const updatedUser = this.props.client.readFragment({
        id: `User:${this.state.userModal.id}`,
        fragment: classmateItemFragment,
      })

      this.setState({
        userModal: updatedUser,
      })
    }
  }

  renderItem(item) {
    if (item.__typename === 'ListTitle') {
      return (
        <span style={styles.titleStyle} key={item.id}>
          {item.title}
        </span>
      )
    }

    let content = null
    let isDisabled = false
    let onClick = () => null

    if (item.__typename === 'ProfilePictureNotification') {
      content = <ProfilePictureNotificationItem item={item} />
      onClick = () => {
        this.props.toggleComponent('Profile')
        this.props.history.push('/profile')
      }
    } else if (item.__typename === 'BaseNotificationType') {
      content = <SocialNetworkNotificationItem item={item} />
      onClick = () => {
        this.props.toggleModal(true, item.otherUser.id)
        if (item.content.includes('like') || item.content.includes('comment')) {
          // this.props.toggleComponent('News_Feed');
          // this.props.history.push('/');
          let currentId = localStorage.getItem('userId')
          this.props.history.push(`/show-post/${item.relatedFeedItemId}/${currentId}`)
        } else {
          this.props.toggleComponent('UserProfile')
          this.props.history.push('/userProfile')
        }
      }
    } else if (item.__typename === 'TextNotification') {
      content = <TextNotificationItem item={item} />
      isDisabled = true
    } else if (item.__typename === 'EventWallPictureApprovalNotification') {
      content = <EventWallApprovalNotificationItem item={item} />
      onClick = () => {
        this.setState({ eventModal: item.event })
        let position = document.documentElement.scrollTop
        document.body.style.position = 'fixed'
        this.setState({
          scrollPosition: position,
        })
      }
    } else if (item.__typename === 'UserGalleryNotification') {
      content = <UserGalleryNotificationItem item={item} />
      onClick = () => {
        this.props.toggleComponent('Profile')
        this.props.history.push('/profile')
      }
    }

    if (content && content !== null) {
      return (
        <div disabled={isDisabled} onClick={onClick} key={item.id}>
          {content}
        </div>
      )
    }

    return null
  }

  onRefresh() {
    this.setState({
      refreshing: true,
    })

    this.props
      .reload()
      .catch(() => null)
      .then(() => {
        this.setState({
          refreshing: false,
        })
      })
  }

  get notificationsArray() {
    const notifications = Array.from(this.state.myNotifications || [])
    const firstUnreadItemIndex = notifications.findIndex((notification) => !notification.read)

    if (firstUnreadItemIndex >= 0) {
      notifications.unshift({
        __typename: 'ListTitle',
        id: 'new',
        // title: 'New',
      })
    }
    const firstReadItemIndex = notifications.findIndex((notification) => notification.read)

    if (firstReadItemIndex >= 0) {
      notifications.splice(firstReadItemIndex, 0, {
        __typename: 'ListTitle',
        id: 'earlier',
        // title: 'Earlier',
      })
    }

    return notifications
  }

  render() {
    if (this.props.loading) {
      return <LoadingSpinner left={window.innerWidth * 3} />
    }

    const data = this.notificationsArray
    console.log(data)

    if (data && data.length === 0) {
      return (
        <EmptyNotice emoji='⚒' subTitle={LangData.notificationsList.soon} title={LangData.notificationsList.nothing} />
      )
    }

    return (
      <Container id='notif-container' style={styles.container}>
        <FontAwesomeIcon className='close-chat-button' icon={faTimes} onClick={this.props.closeNotifyModal} />
        <FeedEventModal
          onClose={() => {
            document.body.style.position = ''
            window.scrollTo(0, this.state.scrollPosition)
            this.setState({ eventModal: null })
          }}
          event={this.state.eventModal}
          history={this.props.history}
        />

        {this.state.refreshing && <LoadingSpinner left={window.innerWidth * 3} />}

        <div style={styles.list} ref={this.containerRef}>
          {data.map((item) => {
            return this.renderItem(item)
          })}
        </div>
      </Container>
    )
  }
}

const styles = {
  container: {
    flexGrow: 1,
    position: "relative",
  },
  list: {
    flexGrow: 1,
    marginTop: width < 500 ? '18%' : '0',
    maxHeight: width < 500 ? 'unset' : 'unset',
    overflowY: width < 500 ? 'auto' : 'unset',
  },
  titleStyle: {
    fontWeight: 'bold',
    fontSize: 14,
    marginLeft: 24,
    color: Theme.TEXT_COLOR,
  },
}

const Container = styled.div`
  .close-chat-button {
    position: absolute;
    top: 5px;
    right: 7px;
    transition: .2s;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
    @media(max-width: 500px) {
      display: none;
    }
  }
`;
