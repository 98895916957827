import { combineReducers } from 'redux';

import { EMyNotificationsActions } from "./notifications.actions";

export const selectIsNotificationsListModalOpen = state => state.notifications.notificationsListState.isOpen;

function notificationsListState(state = {isOpen: false}, action) {
  switch (action.type) {
    case EMyNotificationsActions.OPEN_NOTIFICATIONS_LIST_MODAL: {
      return {...state, isOpen: true}
    }
    case EMyNotificationsActions.CLOSE_NOTIFICATIONS_LIST_MODAL: {
      return {...state, isOpen: false}
    }
    default:
      return state;
  }
}

export const notifications = combineReducers({
  notificationsListState
})
