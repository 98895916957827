import { combineReducers } from 'redux';
import {
  EFriendsListActions,
  EFriendsListActiveState, EFriendsListModalActions
} from './friends.actions';
import { EMainActions } from '../main/main.actions';

export const selectIsModalOpen = state => state.friends.friendsModalState.friendsModalOpen;

function activeState(state = EFriendsListActiveState.FRIENDS_LIST, action) {
  switch (action.type) {
    case EMainActions.CLOSE_ALL_MODALS:
    case EMainActions.CLOSE_SIDE_MENU:
    case EMainActions.TOGGLE_CORNER_MENU:
      return EFriendsListActiveState.FRIENDS_LIST;
    // case EChatActions.NAVIGATE_TO_FRIENDS_LIST: {
    //   if (action.toPendingRequests === true) {
    //     return EFriendsListActiveState.REQUESTS;
    //   }
    //   return EFriendsListActiveState.FRIENDS_LIST;
    // }
    case EFriendsListActions.TOGGLE_REQUESTS: {
      if (action.newState === false) {
        return EFriendsListActiveState.FRIENDS_LIST;
      }
      return EFriendsListActiveState.REQUESTS;
    }
    case EFriendsListActions.SET_SEARCH_TERM: {
      if (action.search && action.search !== '') {
        return EFriendsListActiveState.GLOBAL_SEARCH;
      }
      return EFriendsListActiveState.FRIENDS_LIST;
    }
    default:
      return state;
  }
}

function showHiddenStudents(state = false, action) {
  switch (action.type) {
    case EFriendsListActions.LOAD_HIDDEN_STUDENTS: {
      return true;
    }
    case EFriendsListActions.SET_SEARCH_TERM: {
      if (action.search === '') {
        return false;
      }
      return state;
    }
    default:
      return state;
  }
}

function searchTerm(state = '', action) {
  switch (action.type) {
    case EFriendsListActions.SET_SEARCH_TERM: {
      return action.search;
    }
    case EMainActions.NAVIGATE_PARENT:
    case EMainActions.NAVIGATE_CHILD:
    case EFriendsListActions.TOGGLE_REQUESTS: {
      return '';
    }
    default:
      return state;
  }
}

function friendsModalState(state = {friendsModalOpen: false}, action) {
  switch (action.type) {
    case EFriendsListModalActions.OPEN_MODAL: {
      return {...state, friendsModalOpen: true}
    }
    case EFriendsListModalActions.CLOSE_MODAL: {
      return {...state, friendsModalOpen: false}
    }
    default:
      return state;
  }
}

export const friends = combineReducers({
  activeState,
  showHiddenStudents,
  searchTerm,
  friendsModalState,
});
