import React, { useState, useEffect, Fragment } from 'react';
import { LangData } from '../../../assets/locales/localization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { graphql } from 'react-apollo-temp';
import { deletePhotoMutation } from '../../graphql/delete-photo.mutation';
import { myPhotosQuery } from '../../graphql/my-photos.query';

const width = window.innerWidth

const ProfileGalleryPhoto = props => {
  const { photo, isFirst, isLast } = props;
  const { approved } = photo;

  return (
    <Fragment>
      {!approved ? (
        <div id='pendingNote' style={styles.relativeDiv}>
          <img alt="" src={photo.imageThumb} style={styles.waitingApprovaImage} />
          {props.showDeleteOption && (
            <FontAwesomeIcon
              icon={faTrashAlt}
              style={styles.trashIcon}
              onClick={e => {
                e.stopPropagation();
                props.deletePhoto(props.currentUser.id, photo.id);
              }}
            />
          )}
          <p style={styles.pendingText}>
            <FontAwesomeIcon icon={faClock} />
            {' ' + LangData.profileGalleryPhoto.pending}
          </p>
        </div>
      ) : (
        <div style={styles.relativeDiv}>
          <img src={photo.imageThumb} style={styles.image} alt=""/>
          {props.showDeleteOption && (
            <FontAwesomeIcon
              icon={faTrashAlt}
              style={styles.trashIcon}
              onClick={e => {
                e.stopPropagation();
                props.deletePhoto(props.currentUser.id, photo.id);
              }}
            />
          )}
        </div>
      )}
    </Fragment>
  );
};

const withMutation = graphql(deletePhotoMutation, {
  props: ({ mutate }) => ({
    deletePhoto: (userId, photoId) =>
      mutate({
        variables: { userId, photoId },
        // update: (store, { data: { deletePhoto } }) => {
        //   if (deletePhoto === false) {
        //     return;
        //   }

        //   const data = store.readQuery({
        //     query: myPhotosQuery
        //   });

        //   const photos = data.photos.filter(photo => photo.id !== photoId);

        //   store.writeQuery({
        //     query: myPhotosQuery,
        //     data: {
        //       photos
        //     }
        //   });
        // }
      }).catch(() => null)
  })
});

export default withMutation(ProfileGalleryPhoto);

const styles = {
  pendingText: {
    color: 'white',
    marginLeft: '20px',
    fontSize: '12px',
    position: 'absolute',
    bottom: width < 500 ? '0' : '10px',
    fontWeight: 'bold'
  },
  relativeDiv: { position: 'relative' },
  waitingApprovaImage: {
    opacity: 0.4,
    borderRadius: window.innerWidth / 20.7 + 'px',
    height: width < 500 ? window.innerWidth / 3.6 + 'px' : '250px',
    width: width < 500 ? window.innerWidth / 3.6 + 'px' : '250px',
    objectFit: 'cover'
  },
  image: {
    borderRadius: window.innerWidth / 20.7 + 'px',
    height: width < 500 ?  window.innerWidth / 3.6 + 'px' : '250px',
    width: width < 500 ? window.innerWidth / 3.6 + 'px' : '250px',
    boxShadow: '10px 0px 43px 0px rgba(0,0,0,0.75)',
    objectFit: 'cover'
  },
  trashIcon: {
    color: 'darkgrey',
    cursor: 'pointer',
    fontSize: window.innerWidth / 30 + 'px',
    position: 'absolute',
    top: window.innerWidth / 60 + 'px',
    right: width < 500 ? window.innerWidth / 36 + 'px' : '20px',
    fontWeight: 'bold'
  }
};
