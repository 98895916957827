import { connect } from 'react-redux';
import { graphql, compose } from 'react-apollo-temp';
import gql from 'graphql-tag';
import UserProfileModalView from './user-profile-modal.rn';
import { toggleUserProfileModal } from '../../profile.actions';
import {chatWindowed, chatWithUser} from '../../../chat/chat.actions';
import { classmateItemFragment } from '../../../classmates/graphql/classmate-item.fragment';
import { removeConnectionMutation } from '../../../friends/graphql/remove-connection.mutation';
import { respondMutation } from '../../../friends/graphql/respond.mutation';
import { myConnectionsQuery } from '../../../friends/graphql/my-connections.query';
import { friendRequestsStatusQuery } from '../../../friends/graphql/friend-requests-status.query';
import { connectMutation } from '../../../friends/graphql/connect.mutation';
import { toggleComponent } from '../../../router/router.actions';
import { store } from '../../../store';
import { closeModals } from '../../../main/main.actions';
import { setAlert } from '../../../alert/alert.actions';
import { openWindowedChat } from "../../../windowedChat/windowedChat.actions";
import {closeFriendsProfileModal} from "../../../friends/friends.actions";

const withState = connect(
  ({ profile }) => profile.userProfileModal,
  dispatch => ({
    toggleModal: (isOpen, userId) =>
      dispatch(toggleUserProfileModal(isOpen, userId))
  })
);

const withChatActions = connect(null, dispatch => ({
  navigateToChat: (user, closeFn) => {
    const existingState = store.getState();
    if (
      existingState &&
      existingState.chat &&
      existingState.chat.currentChat &&
      existingState.chat.currentChat &&
      existingState.chat.currentChat.users.length === 1 &&
      existingState.chat.currentChat.users[0].id === user.id
    ) {
      if (closeFn) {
        closeFn();
      }
    } else {
      if (closeFn) {
        closeFn();
      }

      dispatch(closeModals());

      setTimeout(() => {
        dispatch(chatWithUser(user));
      }, 500);
    }
  }
}));

const localUserQuery = gql`
  query user($currentUserId: ID, $id: String) {
    user(currentUserId:$currentUserId, id: $id) {
      ...ClassmateItem
    }
  }

  ${classmateItemFragment}
`;

const withRespond = graphql(respondMutation, {
  props: ({ mutate, currentUser }) => ({
    respond: (currentId, response, userId) =>
      mutate({
        variables: {
          currentId,
          response,
          userId
        },
        update: (storeProxy, { data: { respond } }) => {
          try {
            storeProxy.writeFragment({
              id: `User:${respond.classmate.id}`,
              fragment: classmateItemFragment,
              data: respond.classmate
            });
          } catch (e) {
            // nothing to do...
          }

          try {
            storeProxy.writeQuery({
              query: friendRequestsStatusQuery,
              data: {
                friendRequestsStatus: respond.friendRequestsStatus
              }
            });
          } catch (e) {
            // nothing to do...
          }

          if (response === 'IGNORE') {
            try {
              const querySelector = {
                query: myConnectionsQuery,
                variables: {  userId: currentUser ? currentUser.id : null, pending: false, sent: false, ignored: true }
              };

              const queryState = storeProxy.readQuery(querySelector);

              queryState.myConnections.unshift(respond.classmate);

              storeProxy.writeQuery({
                ...querySelector,
                data: queryState
              });
            } catch (e) {
              // nothing to do...
            }
          }
        }
      })
  })
});

const withConnect = graphql(connectMutation, {
  props: ({ mutate }) => ({
    connect: (currentUser, userId) =>
      mutate({
        variables: {
          currentUserId: currentUser ? currentUser.id : null,
          userId
        },
        refetchQueries: ['friendRequestsStatus']
      })
  })
});

const withRemoveConnection = graphql(removeConnectionMutation, {
  props: ({ mutate }) => ({
    removeConnection: (currentUser, userId) =>
      mutate({
        variables: {
          currentUserId: currentUser ? currentUser.id : null,
          userId
        },
        refetchQueries: ['friendRequestsStatus', 'connections'],
      //   update: storeProxy => {
      //     const userPhotos = storeProxy.readQuery({
      //       query: photosQuery,
      //       variables: { 
      //         userId }
      //     });

      //     if (userPhotos) {
      //       storeProxy.writeQuery({
      //         query: photosQuery,
      //         variables: { userId },
      //         data: {
      //           photos: null
      //         }
      //       });
      //     }
      //   }
      })
  })
});

const withUserFromLocalCache = graphql(localUserQuery, {
  name: 'localCacheUser',
  skip: ({ userId }) => !userId,
  options: (props) => ({
    fetchPolicy: 'network-only',
    variables: { currentUserId: props.currentUser.id , id: props.userId }
  }),
  props: ({ localCacheUser }) => {
    console.log('localCacheUser', localCacheUser)
    if (localCacheUser && localCacheUser.user) {
      return {
        user: localCacheUser.user
      };
    }
    return {
      user: null
    };
  }
});

const mapStateToProps = ({ navigation, auth }) => ({
  currentComponent: navigation.navigationComponents.component,
  prevComponent: navigation.navigationComponents.prevComponent,
  currentUser: auth.currentUser
});

const mapDispatchToProps = dispatch => ({
  toggleComponent: component => dispatch(toggleComponent(component)),
  setAlert: message => dispatch(setAlert(message, 'success')),
  openChat: () => dispatch(openWindowedChat()),
  chatWindowed: user => dispatch(chatWindowed(user)),
  closeProfileModal: () => dispatch(closeFriendsProfileModal()),
});


const withState1 = connect(mapStateToProps, mapDispatchToProps);
export const StatelessUserProfileModal = compose(
  withChatActions,
  withConnect,
  withState1,
  withRespond,
  withRemoveConnection,
  withUserFromLocalCache
)(UserProfileModalView);

export const UserProfileModal = withState(StatelessUserProfileModal);
