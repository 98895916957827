export const WindowedChatActions = {
  WINDOWED_CHAT_OPEN: 'WINDOWED_CHAT_OPEN',
  WINDOWED_CHAT_CLOSE: 'WINDOWED_CHAT_CLOSE',
};

export const openWindowedChat = (title) => ({
  type: WindowedChatActions.WINDOWED_CHAT_OPEN,
  payload: title
});

export const closeWindowedChat = () => ({
  type: WindowedChatActions.WINDOWED_CHAT_CLOSE,
});
