import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NotificationsNavigationScrollableTabView from './notifications-navigation-top-bar.rn';
import { NotificationsList } from './notifications-list/notifications-list.container';
import { FeedRemindersList } from '../../news-feed/components/feed-list/reminders-list.rn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Fade from 'react-reveal/Fade';
import { LangData } from '../../assets/locales/localization';
import { Redirect } from 'react-router';
import SwipeableViews from 'react-swipeable-views';
import { Theme } from '../../common/providers/colors';

export class MyNotificationsView extends Component {
  static propTypes = {
    // toggleMyNotifications: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      checked: false,
      currIndex: 0
    };
  }

  handleChangeIndex = index => {
    this.setState({ currIndex: index });
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loaded: true });
    }, 50);
  }

  render() {
    if (!this.props.currentUser) {
      return <Redirect to='/login' />;
    }

    if (!this.state.loaded) {
      return null;
    }

    // console.log('Props in my-notifications.rn.js', this.props)

    return (
      <div style={styles.pageContainer} className='notifications-container'>
        <div style={styles.pageContainer} id='pageContainer'>
          {/* Top Row */}
          {/* <div style={styles.optionsRow} id='optionsRow'>
              <div
                onClick={() => {
                  this.props.toggleComponent(this.props.prevComponent);
                  this.props.history.push('/');
                  // this.props.history.go(-1);
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </div>
              <div>
                <span>{LangData.notificationsNavigator.center}</span>
              </div>
              <div></div>
            </div> */}
          <NotificationsList
            // close={() => this.props.toggleMyNotifications(false)}
            getMore = {this.props.getMore}
            setGetMore = {this.props.setGetMore}
            currentUser={this.props.currentUser}
            tabLabel={LangData.notificationsNavigator.notifications}
            history={this.props.history}
            inComponent={this.props.inComponent}
          />
          {/* <NotificationsNavigationScrollableTabView
            index={this.state.currIndex}
            changeIndex={this.handleChangeIndex}
          /> */}
          {/* <SwipeableViews
            index={this.state.currIndex}
            onChangeIndex={this.handleChangeIndex}
          >
            <NotificationsList
              // close={() => this.props.toggleMyNotifications(false)}
              tabLabel={LangData.notificationsNavigator.notifications}
              history={this.props.history}
            />
            <FeedRemindersList
              tabLabel={LangData.notificationsNavigator.reminder}
              history={this.props.history}
            />
          </SwipeableViews> */}
        </div>
      </div>
    );
  }
}

const styles = {
  pageContainer: {
    minHeight: window.innerHeight,
    backgroundColor: Theme.BACKGROUND_COLOR
  },
  toggleContainer: { display: 'flex', width: '100%', justifyContent: 'center' },
  leftToggle: {
    color: 'white',
    fontWeight: 'bold',
    position: 'absolute',
    zIndex: '1',
    left: window.innerWidth / 7.2 + 'px',
    fontSize: window.innerWidth / 30 + 'px',
    bottom: window.innerWidth / 72 + 'px'
  },
  rightToggle: {
    color: 'white',
    fontWeight: 'bold',
    position: 'absolute',
    zIndex: '1',
    left: window.innerWidth / 1.747 + 'px',
    fontSize: window.innerWidth / 30 + 'px',
    bottom: window.innerWidth / 72 + 'px'
  },
  optionsRow: {
    display: 'flex',
    padding: '5%',
    justifyContent: 'space-between',
    color: 'white',
    fontWeight: 'bold'
  },
  checkboxInput: {
    opacity: '0',
    width: '0',
    height: '0'
  },
  iconParent: {
    flex: 1,
    paddingLeft: 20,
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  topBarContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  titleParent: {
    flex: 2,
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    color: 'white',
    fontSize: 15,
    fontWeight: 'bold'
  }
};
