import { connect } from 'react-redux';
import { compose, graphql } from 'react-apollo-temp';
import { ForgotPasswordScreen } from './forgot-password.view';
import { DISMISS_ERROR } from '../login.reducer';
import { emailByPhoneQuery } from '../graphql/email-by-phone';
import { setUser } from "../../auth/auth.actions";

const withEmailQuery = graphql(emailByPhoneQuery, {
  name: 'emailByPhone',
  options: ({ phone }) => {
    return {
      variables: {
        phoneNumber: phone
      }
    };
  }
});

const mapStateToProps = state => ({
  loading: state.intro.isLoading,
  user: state.auth.currentUser,
  showInstallPage: state.navigation.showInstallAppPage.show
  //   appVersion: state.main.appVersion
});

const withState = connect(mapStateToProps, dispatch => ({
  dismissError: () => dispatch(DISMISS_ERROR),
  setUser: user => dispatch(setUser(user)),
}));

export const ForgotPassword = compose(
  withState,
  withEmailQuery
)(ForgotPasswordScreen);
