import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ProfileGalleryView from './profile-gallery.rn';
import LoadingSpinner from '../../../common/components/loading-spinner.rn';

import { apolloClient } from '../../../apollo-subs';
import { photoAddedSubsctiption } from '../../graphql/photoAddedSubscription';
import { photoRemovedSubsctiption } from '../../graphql/photoRemovedSubscription';
export class YourPhotosView extends Component {
  static propTypes = {
    photosData: PropTypes.shape({
      error: PropTypes.any,
      networkStatus: PropTypes.number,
      photos: PropTypes.arrayOf(PropTypes.any)
    })
  };

  constructor(props) {
    super(props);

    this.state = {
      photosData: this.props.photosData ? this.props.photosData : []
    };
  }


  componentWillMount = async () => {
    this.subscribeToNewPhotos()
    this.subscribeToDeletedPhotos()
  }

  componentDidMount() {
    this.setState({
      photosData: this.props.photosData ? this.props.photosData : []
    });
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      photosData: newProps.photosData ? newProps.photosData : []
    });
  }


  subscribeToNewPhotos = async () => {
    apolloClient.subscribe({
      query: photoAddedSubsctiption,
      variables: {}
    })
    .subscribe({
      next: async (data) => {
        console.log('photo added!', data)
        console.log('photosData', this.state.photosData)
        let photosCpy = {...this.state.photosData}
        let photoAdded = data.photoAdded
        let currentUserId = localStorage.getItem('userId')
        if(photoAdded.userId.toString() === currentUserId) {
          photosCpy.photos = [photoAdded, ...photosCpy.photos]
          this.setState({ photosData: photosCpy})
        }
      }
    })
  }

  subscribeToDeletedPhotos = async () => {
    apolloClient.subscribe({
      query: photoRemovedSubsctiption,
      variables: {}
    })
    .subscribe({
      next: async (data) => {
        console.log('photo removed!', data)
        let photosCpy = {...this.state.photosData}
        let photoRemoved = data.photoRemoved
        let currentUserId = localStorage.getItem('userId')
        if(photoRemoved.userId.toString() === currentUserId) {
          let idx = photosCpy.photos.findIndex(elem => elem.id.toString() === photoRemoved.id.toString())
          if(idx > -1) {
            console.log('photos before =>', photosCpy.photos)
            let curPhotos = [...photosCpy.photos]
            console.log('curPhotos', curPhotos)
            curPhotos.splice(idx,1)
            photosCpy.photos = curPhotos
            console.log('photos after =>', photosCpy.photos)
            this.setState({ photosData: photosCpy})
          }
        }
      }
    })
  }

  render() {

    if (this.state.photosData.networkStatus === 1) {
      return <LoadingSpinner />;
    }

    return (
      <ProfileGalleryView
        currentUser={this.props.user}
        photos={this.state.photosData.photos}
        showDeleteOption={true}
        history={this.props.history}
        size={
          this.state.photosData && this.state.photosData.photos
            ? this.state.photosData.photos.length
            : 0
        }
      />
    );
  }
}

export default YourPhotosView;
