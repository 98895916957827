import React, { useState, useEffect, Fragment, useCallback } from 'react'
import { Schedule } from '../schedule-view/components/schedule.container'
import { Redirect } from 'react-router'
import './main-page-view.scss'
import { Menu } from '../menu/menu.container'
import { Theme } from '../common/providers/colors'
import logoSrc from '../assets/images/LogoDark.png'
import ProfileImage from '../common/components/profile-image.rn'
import { refreshUser } from '../account/account.util'
import BurgerMenu from '../burgerMenu/BurgerMenu'
import notificationsLogoLight from '../assets/images/notificationsLogoLight.png'
import friendsLogoLight from '../assets/images/friendsLogoLight.png'
import homeLight from '../assets/images/homeLight.png'
import chatsLogoLight from '../assets/images/chatsLogoLight.png'
import plusLogoLight from '../assets/images/plusLogoLight.png'
import closeLogoLight from '../assets/images/closeLogoLight.png'
import { getClient } from '../apollo'
import { userTalkedWithYogi } from './graphql/userTalkedWithYogi'
import { userCompletedFirstLogin } from './graphql/userCompletedFirstLogin'
import yogiBlob from '../assets/images/yogiBlob.png'
import { NotificationAddedSubscription } from '../my-notifications/graphql/notification-added.subscription'
import { apolloClient } from '../apollo-subs'
import { friendAddedSubscription } from '../friends/graphql/friend-added.subscription'
import { chatAddedSubscription } from '../chat/graphql/chat-added.subscription'
import { chatMessageAddedSubscription } from '../chat/graphql/message-added.subscription'
import { GeneralComponent } from './general-page-container'
import chatsLogo from '../assets/images/chatsLogo.png'
import notificationsLogo from '../assets/images/notificationsLogo.png'
import './main-page-view.scss'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsChatListModalOpen } from '../chat/chat.reducer'
import { closeChatListModal, openChatListModal } from '../chat/chat.actions'
import { closeNotificationsListModal, openNotificationsListModal } from '../my-notifications/notifications.actions'
import { selectIsNotificationsListModalOpen } from '../my-notifications/notifications.reducers'

const width = window.innerWidth

function MainView(props) {
  const [currIndex, setCurrIndex] = useState(
    props.currentComponent === 'Menu'
      ? 4
      : props.currentComponent === 'Notifications'
      ? 3
      : props.currentComponent === 'Chat'
      ? 1
      : props.currentComponent === 'Friends'
      ? 2
      : 0
  )
  const [isMenuOpen, setIsMenuOpen] = useState(width >= 500)
  const [isLowerSubMenuOpen, setIsLowerSubMenuOpen] = useState(false)
  const [updatedTalkWithYogi, setUpdatedTalkWithYogi] = useState(false)
  const [countBubble, setCountBubble] = useState(0)
  const [countFriendsBubble, setCountfriendsBubble] = useState(0)
  const [countChatBubble, setCountChatBubble] = useState(0)
  const [currentChat, setCurrentChat] = useState(null)
  const dispatch = useDispatch()
  const isChatListModalOpen = useSelector(selectIsChatListModalOpen)
  const isNotificationsListOpen = useSelector(selectIsNotificationsListModalOpen)
  const handleChangeIndex = async (index) => {
    setCurrIndex(index)
    if (index === 0) props.toggleComponent('News_Feed')
    if (index === 1) props.toggleComponent('Chat')
    if (index === 2) props.toggleComponent('Friends')
    if (index === 3) props.toggleComponent('Notifications')
    if (index === 4) props.toggleComponent('Schedule')

    scrollToTop()
  }

  useEffect(() => {
    if (props.unreadNotificationsCount) setCountBubble(props.unreadNotificationsCount)
  }, [props.unreadNotificationsCount])

  useEffect(() => {
    console.log('unread chats', props.unreadChatMessagesCount)
    if (props.unreadChatMessagesCount) setCountChatBubble(props.unreadChatMessagesCount)
  }, [props.unreadChatMessagesCount])

  const toggleChatListModal = useCallback(() => {
    if (isChatListModalOpen) {
      dispatch(closeChatListModal())
    } else {
      dispatch(openChatListModal())
      if (isNotificationsListOpen) {
        dispatch(closeNotificationsListModal())
      }
    }
  }, [dispatch, isChatListModalOpen, isNotificationsListOpen])

  const toggleNotificationsListModal = useCallback(() => {
    setCountBubble(0)
    if (isNotificationsListOpen) {
      dispatch(closeNotificationsListModal())
    } else {
      console.log('open notifications', Date.now())
      dispatch(openNotificationsListModal())
      if (isChatListModalOpen) {
        dispatch(closeChatListModal())
      }
    }
  }, [dispatch, isChatListModalOpen, isNotificationsListOpen])

  const updateUserFirstLogin = () => {
    if (props.currentUser && props.currentUser.flags.isFirstLogin) {
      getClient()
        .mutate({
          mutation: userCompletedFirstLogin,
          variables: {
            userId: props.currentUser.id,
          },
        })
        .then((_) => {
          const storedUser = JSON.parse(localStorage.getItem('user'))
          storedUser.flags.isFirstLogin = false
          localStorage.setItem('user', JSON.stringify(storedUser))
        })
    }
  }

  const subscribeToNewFriends = () => {
    apolloClient
      .subscribe({
        query: friendAddedSubscription,
        variables: {},
      })
      .subscribe({
        next: (data) => {
          let newConnection = data.friendAdded
          let currentId = localStorage.getItem('userId')
          if (newConnection.userAdded.toString() === currentId.toString()) {
            setCountfriendsBubble((prevState) => prevState + 1)
          }
        },
      })
  }

  const subscribeToNewMesseages = () => {
    apolloClient
      .subscribe({
        query: chatMessageAddedSubscription,
        variables: {},
      })
      .subscribe({
        next: (data) => {
          console.log('new message sent subs', data)
        },
      })
  }

  const subscribeToNewChats = () => {
    apolloClient
      .subscribe({
        query: chatAddedSubscription,
        variables: {},
      })
      .subscribe({
        next: (data) => {
          let newChat = data.chatAdded
          let usersIds = []
          newChat.users.map((user) => usersIds.push(user.id))
          if (usersIds.includes(this.props.currentUser.id.toString())) {
            setCountChatBubble((prevState) => prevState + 1)
          }
        },
      })
  }

  const subscribeToNewNotifications = () => {
    apolloClient
      .subscribe({
        query: NotificationAddedSubscription,
        variables: {},
      })
      .subscribe({
        next: (data) => {
          let newNotification = data.notificationAdded
          let currentId = localStorage.getItem('userId')
          if (currentId) {
            if (newNotification.userId.toString() === currentId) {
              if (currIndex === 3) setCountBubble(0)
              else setCountBubble((prevState) => prevState + 1)
            }
          }
        },
      })
  }

  useEffect(() => {
    if (props.location?.state?.index) {
      // console.log('here123', props.location)
      handleChangeIndex(1);
    }
  }, [])

  useEffect(() => {
    document.getElementById('zmmtg-root').style.display = 'none'
  }, [])

  useEffect(() => {
    document.getElementById('zmmtg-root').style.display = 'none'
    window.scrollTo(0, 1)
    if (props.currentComponent === 'News_Feed') {
      setCurrIndex(0)
    }
    if (props.currentComponent === 'Schedule') setCurrIndex(4)
    if (props.currentComponent === 'Notifications') setCurrIndex(2)
    if (props.currentComponent === 'Friends') setCurrIndex(1)
    if (props.currentComponent === 'Menu') setCurrIndex(0)
    updateUserFirstLogin()
    subscribeToNewNotifications()
    subscribeToNewFriends()
    subscribeToNewChats()
    subscribeToNewMesseages()
  }, [props.currentComponent])

  const getMessagesObj = () => {
    let messages = { messages: [], hasMore: false }
    try {
      let chat = null
      if (props.chatData && props.chatData.chat && props.chatData.chat.users) {
        setCurrentChat(props.chatData.chat)
        chat = props.chatData.chat
      } else if (props.chat && props.chat.users && props.chat.users.length > 0) {
        setCurrentChat(props.chat)
        chat = props.chat
      } else {
        setCurrentChat(null)
        chat = null
      }
      // eslint-disable-next-line
      messages = chat.messages || messages
    } catch (e) {
      // nothing...
    }

    return messages
  }

  const refreshUserFunction = async () => {
    let user = await refreshUser()
    if (user) {
      props.setUser(user)
    }
  }

  useEffect(() => {
    refreshUserFunction()
  }, [props])

  function scrollToTop() {
    window.scrollTo(0, 0)
  }

  if (!props.currentUser) {
    let url_string = window.location.href
    let url = new URL(url_string)
    let eventId = url.searchParams.get('id')
    let videoId = url.searchParams.get('video')
    if (eventId) localStorage.setItem('eventToDisplay', eventId)
    else localStorage.setItem('eventToDisplay', 'none')
    if (videoId) localStorage.setItem('videoToDisplay', videoId)
    else localStorage.setItem('videoToDisplay', 'none')
    return <Redirect to='/welcome' />
  }

  let videoIdFromUrl = localStorage.getItem('videoToDisplay')
  if (videoIdFromUrl && videoIdFromUrl !== 'none') {
    props.history.push('/videos')
    props.toggleComponent('Videos')
  }

  const userTalkedWithYogiUpdate = () => {
    if (!props.currentUser.talkedWithYogi && !updatedTalkWithYogi) {
      getClient()
        .mutate({
          mutation: userTalkedWithYogi,
          variables: {
            userId: props.currentUser.id,
          },
        })
        .then((_) => {
          setUpdatedTalkWithYogi(true)
        })
    }
  }

  return (
    <div style={styles.mainPage} className='main-page-container'>
      {/* <div style={props.currentComponent === 'Schedule' ? styles.topNavGray : styles.topNav}> */}
      <div style={styles.topNav}>
        <div
          onClick={() => {
            setIsMenuOpen(!isMenuOpen)
          }}
          style={styles.menu}
        >
          <BurgerMenu />
          {/* <FontAwesomeIcon icon={faBars} /> */}
        </div>
        <img src={logoSrc} style={styles.logo} alt='' />
        <div
          className='header-profile-picture-and-chat-container'
          style={{ marginRight: '2%', width: width < 500 ? '13%' : '236px' }}
        >

          {width >= 500 && (
            <div style={styles.chatButtonContainer}>
              <img
                alt='notifications'
                style={{ cursor: 'pointer' }}
                className='header-chat-icon'
                src={notificationsLogo}
                onClick={toggleNotificationsListModal}
              />
              {countBubble && width > 500 ? <div style={styles.notificationBubble}>{countBubble}</div> : null}
            </div>
          )}
          {/* {width >= 500 && (
            <div style={styles.chatButtonContainer}>
              <img
                alt='chat'
                style={{ marginRight: '22%', cursor: 'pointer' }}
                className='header-chat-icon'
                src={chatsLogo}
                onClick={toggleChatListModal}
              />
              {countChatBubble && width > 500 ? <div style={styles.notificationBubble}>{countChatBubble}</div> : null}
            </div>
          )} */}
          <ProfileImage
            showBorder={false}
            withFullSize={false}
            height={60}
            width={60}
            imageUrl={props.currentUser ? props.currentUser.profilePictureUrl : null}
            onClick={() => {
              refreshUserFunction()
              props.toggleComponent('Profile')
              props.history.push('profile')
            }}
          />
        </div>
      </div>
      {isMenuOpen && (
        <Menu
          notificationsCount={countBubble}
          history={props.history}
          isOpen={isMenuOpen}
          closeMenu={() => setIsMenuOpen(false)}
          setIndex={(index) => setCurrIndex(index)}
        />
      )}
      <GeneralComponent currIndex={currIndex} handleChangeIndex={handleChangeIndex} history={props.history} />
      {width < 500 && (
        <div style={styles.outerNav}>
          {isLowerSubMenuOpen && (
            <Fragment>
              <button
                onClick={() => {
                  props.history.push({
                    pathname: 'videos',
                    state: {
                      yocasType: 'Pose',
                    },
                  })
                }}
                style={styles.outerNavSubMenuBtn}
              >
                Yoga Poses
              </button>
              <button
                onClick={() => {
                  props.history.push({
                    pathname: 'videos',
                    state: {
                      yocasType: 'session',
                    },
                  })
                }}
                style={styles.outerNavSubMenuBtn}
              >
                Yoga Session
              </button>
              <button
                onClick={() => {
                  props.history.push({
                    pathname: 'videos',
                    state: {
                      yocasType: 'modification',
                    },
                  })
                }}
                style={styles.outerNavSubMenuBtn}
              >
                Yoga Modifications
              </button>
            </Fragment>
          )}
          <div style={styles.innerMenu}>
            <div
              style={currIndex === 0 ? styles.innerNavSelected : styles.innerNav}
              onClick={() => {
                setCurrIndex(0)
                props.toggleComponent('News_Feed')
              }}
            >
              <img src={homeLight} alt='' style={{ height: '5vh' }} />
            </div>
            <div
              style={currIndex === 1 ? styles.innerNavSelected : styles.innerNav}
              onClick={() => {
                setCurrIndex(1)
                props.toggleComponent('Chat')
              }}
            >
              {countChatBubble > 0 && <div style={styles.chatBubble}>{countChatBubble}</div>}
              <img src={chatsLogoLight} alt='' style={{ height: '5vh' }} />
            </div>
            <div
              style={styles.innerNav}
              onClick={() => {
                setIsLowerSubMenuOpen(!isLowerSubMenuOpen)
              }}
            >
              <img src={!isLowerSubMenuOpen ? plusLogoLight : closeLogoLight} alt='' style={{ height: '5vh' }} />
            </div>
            <div
              style={currIndex === 2 ? styles.innerNavSelected : styles.innerNav}
              onClick={() => {
                setCurrIndex(2)
                props.toggleComponent('Friends')
              }}
            >
              {countFriendsBubble > 0 && <div style={styles.friendsBubble}>{countFriendsBubble}</div>}
              <img src={friendsLogoLight} alt='' style={{ height: '5vh' }} />
            </div>
            <div
              style={currIndex === 3 ? styles.innerNavSelected : styles.innerNav}
              onClick={() => {
                setCountBubble(0)
                setCurrIndex(3)
                props.toggleComponent('Notifications')
              }}
            >
              {countBubble > 0 && <div style={styles.bubble}>{countBubble}</div>}
              <img src={notificationsLogoLight} alt='' style={{ height: '5vh' }} />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default MainView

const styles = {
  bubble: {
    backgroundColor: 'red',
    fontSize: '13px',
    height: 'fit-content',
    padding: '0.7% 2.2%',
    borderRadius: '50%',
    position: 'absolute',
    top: '18%',
    right: '6%',
  },
  mainPage: {
    // minHeight: '100vh',
    backgroundColor: Theme.BACKGROUND_COLOR,
  },
  outerNav: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: window.innerWidth,
    paddingTop: '2%',
    position: 'fixed',
    bottom: 0,
    zIndex: 9,
    backgroundColor: '#4d4b62',
    borderRadius: '20px 20px 0 0',
    padding: '0',
  },
  innerMenu: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%',
  },
  topNav: {
    display: 'flex',
    justifyContent: width < 500 ? 'space-around' : 'space-between',
    alignItems: 'center',
    width: window.innerWidth,
    paddingRight: width < 500 ? 0 : '40px',
    paddingLeft: width < 500 ? 0 : '40px',
    paddingTop: width < 500 ? '2%' : '20px',
    paddingBottom: width < 500 ? '0' : '20px',
    position: 'fixed',
    top: 0,
    height: width < 500 ? 'auto' : '120px',
    zIndex: 99999,
    backgroundColor: Theme.BACKGROUND_COLOR,
  },
  topNavGray: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: window.innerWidth,
    paddingTop: '2%',
    position: 'fixed',
    top: 0,
    zIndex: 99999,
    backgroundColor: Theme.GRAY_COLOR,
  },
  innerNav: {
    textAlign: 'center',
    fontSize: '35px',
    color: Theme.LOWER_NAV_ICONS_COLOR,
    paddingBottom: '1%',
    display: 'flex',
  },
  innerNavSelected: {
    textAlign: 'center',
    fontSize: '35px',
    color: Theme.WHITE_COLOR,
    paddingBottom: '1%',
    borderBottom: `2px solid ${Theme.WHITE_COLOR}`,
  },
  logo: {
    width: width < 500 ? '50%' : 'auto',
    height: width < 500 ? 'auto' : '84%',
    padding: width < 500 ? '1%' : '20px',
    paddingLeft: width < 500 ? '0' : '18%',
  },
  menu: {
    color: Theme.BLACK_COLOR,
    fontSize: '25px',
    marginLeft: 0,
    cursor: 'pointer',
  },
  fullPage: {
    minHeight: window.innerHeight,
    width: window.innerWidth,
    opacity: '0.9',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: '999999',
    textAlign: 'center',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '0',
  },
  msgContainer: {
    width: window.innerWidth / 2 + 'px',
    backgroundColor: 'white',
    fontFamily: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: Theme.TEXT_COLOR,
  },
  btn: {
    color: Theme.TEXT_COLOR,
    backgroundColor: Theme.BACKGROUND_COLOR,
    border: 'none',
    padding: '7%',
    fontWeight: 'bold',
    fontSize: window.innerWidth / 24 + 'px',
    fontFamily: 'inherit',
    width: '50%',
    margin: '1%',
  },
  icon: {
    color: Theme.BACKGROUND_COLOR,
    fontSize: window.innerWidth / 7.2 + 'px',
    marginTop: '5%',
  },
  buttonsRow: {
    width: '100%',
    display: 'flex',
  },
  userAnswerInput: {
    border: '1px solid #dcd7c8',
    fontFamily: 'inherit',
    marginBottom: '10%',
    padding: '5%',
    fontSize: '15px',
    borderRadius: '15px',
  },
  talkWithYourYogiBtn: {
    width: '100%',
    borderRadius: '25px',
    border: 'none',
    padding: '5px 10px',
    backgroundColor: 'transparent',
    color: '#665f70', // margin: '5%'
    display: 'flex',
    flexDirection: 'row-reverse',
    outline: '0',
  },
  exitIcon: {
    position: 'absolute',
    zIndex: 10000,
    top: '18%',
    left: '94%',
    width: '20%',
    fontSize: '18px',
    color: '#978689',
    cursor: 'pointer',
  },
  talkWithYourYogiContainer: {
    // marginTop: width < 500 ? window.innerWidth / 7.2 + 'px' : window.innerWidth / 11 + 'px',
    // width: 'fit-content',
    // display: "flex",
    // justifyContent: "center",
    position: 'absolute',
    zIndex: '10000',
    top: '16%',
    left: '80%',
    width: '20%',
    flexDirection: 'row-reverse',
  },
  outerNavSubMenuBtn: {
    width: '70%',
    borderRadius: '25px',
    padding: '2%',
    backgroundColor: '#e1c2b0',
    color: '#4D4B62',
    fontSize: 'large',
    marginBottom: '2%',
  },
  addNewPostContainer: {
    marginTop: width < 500 ? window.innerWidth / 7.2 - 2 + 'px' : window.innerWidth / 11 + 'px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  addNewPostBtn: {
    outline: 'none',
    width: '80%',
    borderRadius: '25px',
    border: 'none',
    padding: '5px 10px',
    backgroundColor: '#e1c2b0',
    color: '#665f70',
    margin: '5%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  yogiLogo: {
    width: 'inherit',
  },
  yogiBlobStyle: {
    width: '230px',
    height: '100%',
    position: 'absolute',
    top: '-38%',
    backgroundImage: `url(${yogiBlob})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  yogiBlobTextStyle: {
    width: '30%',
    textAlign: 'center',
    fontSize: '11px',
  },
  addPostToggler: {
    display: 'inline-block',
    transform: 'rotate(90deg)',
    fontSize: '20px',
    padding: '4px 8px',
    backgroundColor: '#4D4B62',
    borderRadius: '50%',
    color: '#E1C2B0',
    fontWeight: 'bolder',
  },
  notificationBubble: {
    width: '16px',
    height: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    borderRadius: '50%',
    backgroundColor: 'red',
    fontSize: '13px',
    left: '20px',
    top: '5px',
    position: 'absolute',
  },
  chatButtonContainer: {
    display: 'inline-block',
    marginRight: '36px',
    position: 'relative',
  },
}
