import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { ChatNavBar } from '../common/chat-nav-bar.rn';
import { SendMessageInput } from './send-message-input.container';
import { MessagesList } from './messages-list.rn';

import { NewChat } from '../new-chat/new-chat.container';
import { ChatUsersListModal } from './chat-users-list-modal.rn';
import debounce from 'lodash.debounce';
import {
  ChatListItemMenu,
  ChatListItemMenuView
} from './chat-list-item-menu.rn';
import { StatelessProfileModal } from '../../../profile/components/profile-modal.container';
import { StatelessUserProfileModal } from '../../../profile/components/profile/user-profile-modal.container';
import { LangData } from '../../../assets/locales/localization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { Theme } from '../../../common/providers/colors';
import { apolloClient } from '../../../apollo-subs';
import { chatMessageAddedSubscription } from '../../graphql/message-added.subscription';
import { uploadToAzureMutation } from '../../graphql/upload-to-azure.mutation';

const width = window.innerWidth;

export class ChatScreenView extends Component {
  static propTypes = {
    chat: PropTypes.object,
    chatData: PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.any,
      chat: PropTypes.any,
      networkStatus: PropTypes.number,
      stopPolling: PropTypes.func,
      startPolling: PropTypes.func
    }),
    unreadCount: PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.any,
      networkStatus: PropTypes.number,
      stopPolling: PropTypes.func,
      startPolling: PropTypes.func
    }),
    close: PropTypes.func.isRequired,
    setChat: PropTypes.func.isRequired,
    addFriends: PropTypes.func.isRequired,
    sendMessage: PropTypes.func.isRequired,
    createNewChat: PropTypes.func.isRequired,
    loadOlder: PropTypes.func,
    subscribeToMore: PropTypes.func,
    notifyReadStatus: PropTypes.func,
    currentUser: PropTypes.any.isRequired,
    notificationsEnabled: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this._messagesList = null;
    this.state = {
      addFriendModalOpen: false,
      usersListOpen: false,
      menuChat: null,
      showMenuChat: false,
      userIdModal: null,
      profileModalOpen: false,
      chatMessages: { messages: [], hasMore: false }
    };

    this.messagesQueue = [];
    this._subscriptionHandle = null;
    this.notifyReadStatus = debounce(this.notifyReadStatus.bind(this), 100);

    // if (Platform.OS === 'android') {
    //   this._keyboardDidShowListener = Keyboard.addListener(
    //     'keyboardDidShow',
    //     this.keyboardChanged.bind(this, true)
    //   );
    //   this._keyboardDidHideListener = Keyboard.addListener(
    //     'keyboardDidHide',
    //     this.keyboardChanged.bind(this, false)
    //   );
    // }
  }

  componentWillMount = () => {
    // this.subscribeToNewMessages()
  }

  async componentWillReceiveProps(nextProps) {
    console.log('inside component will receive props', nextProps)
    if (
      nextProps.chatData &&
      nextProps.chatData.chat &&
      nextProps.chatData.chat.messages) {
        console.log('messages in chat!', nextProps.chatData.chat.messages)
        this.setState({ chatMessages: nextProps.chatData.chat.messages})
      }

    if (!this.props.notificationsEnabled && nextProps.chatData) {
      // this.startPolling(nextProps.chatData);
    }

    if (
      nextProps.chatData &&
      nextProps.chatData.chat &&
      nextProps.chatData.networkStatus === 7
    ) {
      this.handleQueue(nextProps.chatData.chat, this.messagesQueue);
      this.messagesQueue = [];
    }

    if (this.props.chat && this.props.chat.id && nextProps.chat) {
      await this.validateChat(nextProps, false);
    }
  }

  _polling = false;

  startPolling(chatsData) {
    if (this._polling) {
      return;
    }

    this._polling = true;
    // chatsData.startPolling(10000);
  }

  async componentDidMount() {
   
   

    await this.validateChat(this.props, false);

    if (
      !this.props.notificationsEnabled &&
      this.props.chatData &&
      this.props.chatData.chat &&
      this.props.chatData.chat.id
    ) {
      // this.startPolling(this.props.chatData);
    }
  }

  componentWillUnmount() {
    if (this._subscriptionHandle) {
      this._subscriptionHandle();
      this._subscriptionHandle = null;
    }
    
    if (!this.props.notificationsEnabled && this.props.unreadCount) {
      this.props.unreadCount.stopPolling();
    }
  }

  componentDidUpdate() {
    console.log('inside componentDidUpdate!')
    const messages = this.state.chatMessages;
    const messagesArr = messages.messages;

    const handle = this.props.subscribeToMore && typeof this.props.subscribeToMore === "function" ? this.props.subscribeToMore() : null;

    if (handle) {
      this._subscriptionHandle = handle;
    }

    const lastMessageRead =
      messagesArr.length > 0 ? messagesArr[messagesArr.length - 1] : null;

    if (
      lastMessageRead &&
      lastMessageRead.id &&
      !lastMessageRead.id.includes('temp_')
    ) {
      this.notifyReadStatus(this.props.currentUser ? this.props.currentUser.id : null, this.props.chat.id, lastMessageRead.id);
    }
  }

  keyboardChanged(isOpen) {
    if (!isOpen) {
      return;
    }

    setTimeout(() => {
      if (this._messagesList) {
        this._messagesList.scrollToListEnd(false, true);
      }
    }, 50);
  }

  handleQueue(chat, messages) {
    messages.forEach(({ content, messageType, imageData }) =>
      this.doSend(chat, messageType, content, imageData)
    );
  }

  get chat() {
    if (
      this.props.chatData &&
      this.props.chatData.chat &&
      this.props.chatData.chat.users
    ) {
      return this.props.chatData.chat;
    } else if (
      this.props.chat &&
      this.props.chat.users &&
      this.props.chat.users.length > 0
    ) {
      return this.props.chat;
    }

    return null;
  }

  get screenTitle() {
    const { chat } = this;

    if (chat) {
      if (!chat.title) {
        return chat.users[0].name;
      }

      return chat.title;
    }

    return '...';
  }

  loadOlderMessages = () => {
    if (this.props && this.props.loadOlder) {
      this.props.loadOlder();
    }
  };

  async validateChat(props, toCreate) {
    if (props.chat) {
      if (props.chat.id === null) {
        const result = await props.createNewChat(
          props.currentUser.id,
          props.chat.users.map(u => u.id),
          null,
          toCreate
        );

        if (
          result.data &&
          result.data.createNewChat &&
          result.data.createNewChat !== null
        ) {
          props.setChat(result.data.createNewChat);
        }
      }
    }
  }

  sendMessage = async (messageType, content, imageData) => {
    requestAnimationFrame(async () => {
      if (!this.props.chat || !this.props.chat.id) {
        this.messagesQueue.push({ content, messageType, imageData });
        await this.validateChat(this.props, true);
      } else {
        this.doSend(this.props.chat, messageType, content, imageData);
      }
    });
  };

  doSend(chat, messageType, content, imageData) {
    this.props
      .sendMessage(this.props.currentUser.id, messageType, content, chat.id, imageData)
      .then(() => {
        setTimeout(
          () =>
            this._messagesList &&
            this._messagesList.scrollToListEnd(true, true),
          100
        );
      });
  }

  getMessagesObj() {
    let messages = { messages: [], hasMore: false };

    try {
      const { chat } = this;
      // eslint-disable-next-line
      messages = chat.messages || messages;
    } catch (e) {
      // nothing...
    }

    return messages;
  }

  notifyReadStatus(userId, chatId, messageId) {
    requestAnimationFrame(() => {
      this.props.notifyReadStatus(this.props.currentUser ? this.props.currentUser.id : null, chatId, messageId).catch(() => null);
    });
  }

  addFriends = () => {
    this.setState({
      addFriendModalOpen: true
    });
  };

  getName(fullName) {
    if (!fullName) {
      return '';
    }

    const splitted = (fullName || '').split(' ');

    return splitted[0];
  }

  getRight(chat) {
    if (!chat) {
      return null;
    }

    return (
      <div
        onClick={() =>
          this.setState({
            menuChat: chat,
            showMenuChat: !this.state.showMenuChat
          })
        }
      >
        <FontAwesomeIcon
          icon={faEllipsisV}
          style={{ color: Theme.SECONDARY_TEXT_COLOR }}
        />
      </div>
    );
  }

  getSubtitle(isGroupChat, users) {
    if (isGroupChat) {
      return users.map(user => user.name).join(', ');
    }

    return '';
  }

  addUsers = users => {
    this.setState({ addFriendModalOpen: false });
    this.props.addFriends(this.props.currentUser.id, this.props.chat.id, users);
  };

  navigateToProfile = () => {
    this.props.toggleComponent('Profile');

    this.props.history.push('Profile');
  };

  navigateToUserProfile = user => {
    this.props.onItemPress(user);
    this.props.toggleComponent('UserProfile');
    this.props.history.push('userProfile');
    this.setState({ userIdModal: user.id });
  };

  renderNavBar = (chat, users, isGroupChat) => (
    <ChatNavBar
      type='chatScreen'
      openUsersList={() => {
        isGroupChat
          ? this.setState({ usersListOpen: true })
          : this.navigateToUserProfile(users[0]);
      }}
      title={this.props.userName}
      onClose={() => {
        if(width < 500) {
          this.props.close();
          this.props.toggleComponent('Chat');
          this.props.history.push('/');
        }else {
          this.props.closeWindowedChat()
        }
      }}
      right={this.getRight(chat)}
      subTitle={this.getSubtitle(isGroupChat, users)}
    />
  );

  subscribeToNewMessages = () => {
    apolloClient.subscribe({
      query: chatMessageAddedSubscription,
      variables: {}
    })
    .subscribe({
      next: (data) => {
        console.log('new message arrived! ', data)
        let newMessage = data.messageAdded
        if(newMessage.chatId.toString() === this.props.chatData.chat.id.toString()) {
          console.log('newMessage =>', newMessage) 
          let messagesCpy = {...this.state.chatMessages}
          let messages = [...messagesCpy.messages]
          let newMessages = [...messages, newMessage]
          messagesCpy.messages = newMessages
          this.setState({ chatMessages: messagesCpy })
        } 
      }
    })
  }

  uploadAzureFile = async (file, tag, base64, chatId) => {
    console.log('in upload azure file =>', file, tag, base64, chatId)
    let res = await apolloClient.mutate({
      mutation: uploadToAzureMutation,
      variables: {
        file, 
        tag, 
        base64, 
        chatId
      }
    })
    console.log('got result from azure upload', res.data.uploadToAzure)
    return res.data.uploadToAzure

  }

  renderChatWindow = (chat, chatEnabled, users, isGroupChat) => {
    const loading =
      this.props.chatData && this.props.chatData.networkStatus === 1;
    const isLoadingMore =
      this.props.chatData && this.props.chatData.networkStatus === 3;
    const messages = this.state.chatMessages

    console.log('messages =>', messages)
    console.log('state messages =>', this.state.chatMessages)

    const otherUserDisplayName = isGroupChat
      ? null
      : this.props.chatData && this.props.chatData.chat
      ? this.getName(this.props.chatData.chat.users[0].name)
      : null;

    return (
      <div style={styles.chatWindow}>
        {!loading && users.some(u => !u.appSupportChat) && (
          <p style={styles.oldAppVersionNote}>
            Note:{' '}
            {isGroupChat
              ? LangData.chatScreen.users
              : LangData.chatScreen.title +
                ' ' +
                this.screenTitle +
                ' ' +
                LangData.chatScreen.version +
                ' ' +
                LangData.chatScreen.ability}
          </p>
        )}
        {
          this.state.chatMessages &&
          <MessagesList
          navigateToProfile={this.navigateToProfile}
          isLoadingMore={isLoadingMore}
          onLoadMore={this.loadOlderMessages}
          ref={ref => (this._messagesList = ref)}
          currentUser={this.props.currentUser}
          messages={ this.state.chatMessages.messages }
          cursor={messages.cursor}
          loading={loading}
          onUserPicked={this.navigateToUserProfile}
          mostRecentDataEventType={messages.eventType}
          hasMore={messages.hasMore}
          openImgModal={this.props.openImgModal}
          setAlert={this.props.setAlert}
        />
        }
      
        {!loading && (
          <SendMessageInput
            chatId={chat.id}
            name={otherUserDisplayName}
            enabled={chatEnabled}
            onSendMessage={this.sendMessage}
            uploadAzureFile={this.uploadAzureFile}
          />
        )}
        {/* {Platform.OS === 'ios' && (
          <KeyboardSpacer onToggle={isOpen => this.keyboardChanged(isOpen)} />
        )} */}
      </div>
    );
  };

  onBeforeMenuAction = async actionType => {
    if (
      actionType === ChatListItemMenuView.LEAVE_CHAT ||
      actionType === ChatListItemMenuView.REMOVE_CHAT
    ) {
      this.props.close();

      return new Promise(resolve => setTimeout(resolve, 400));
    } else if (actionType === ChatListItemMenuView.ADD_FRIENDS) {
      return new Promise(resolve => {
        this.setState(
          {
            menuChat: null
          },
          () => setTimeout(resolve, 500)
        );
      });
    }

    return Promise.resolve();
  };

  renderMenu = chat => {
    if (!chat) {
      return null;
    }

    return (
      <ChatListItemMenu
        currentUser={this.props.currentUser}
        addUsersToChat={this.addFriends}
        showAddFriendsButton={true}
        onBeforeAction={this.onBeforeMenuAction}
        chat={this.state.menuChat}
        onClose={() => {
          this.setState({ menuChat: null, showMenuChat: false });
        }}
        onDone={() => {
          this.props.toggleComponent('Chat');
          this.props.history.push('/');
        }}
        visible={this.state.showMenuChat}
      />
    );
  };

  renderChatRelatedModals = users => (
    <Fragment>
      {!!this.state.userIdModal ? (
        <StatelessUserProfileModal
          isOpen={!!this.state.userIdModal}
          toggleModal={() => this.setState({ userIdModal: null })}
          userId={this.state.userIdModal}
          fromSug={false}
        />
      ) : null}

      {this.state.profileModalOpen ? (
        <StatelessProfileModal
          isOpen={this.state.profileModalOpen}
          toggleModal={() => this.setState({ profileModalOpen: false })}
        />
      ) : null}

      <ChatUsersListModal
        users={users}
        onClose={() => this.setState({ usersListOpen: false })}
        isOpen={this.state.usersListOpen}
      />

      {this.state.addFriendModalOpen ? (
        <div style={styles.addFriendsContainer}>
          <NewChat
            navTitle={LangData.chatScreen.add}
            hideUsers={users}
            onUsersPicked={this.addUsers}
            step={0}
            onClose={() => this.setState({ addFriendModalOpen: false })}
          />
        </div>
      ) : null}
    </Fragment>
  );

  render() {
    const chat = this.chat || {};
    const isGroupChat = !!chat.title;
    const users = chat.users || [];
    const chatEnabled = !!chat.enabled || (!chat.id && chat.users);

    return (
      <Fragment>
        {this.renderChatRelatedModals(users)}
        {this.renderNavBar(chat, users, isGroupChat)}
        {this.renderChatWindow(chat, chatEnabled, users, isGroupChat)}
        {this.renderMenu(chat)}
      </Fragment>
    );
  }
}

const styles = {
  container: {
    flex: 1
  },
  chatWindow: {
    backgroundColor: Theme.BACKGROUND_COLOR,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: width < 500 ? '100%' : 'calc(100% - 48px)'
  },
  rightIcon: {
    marginRight: 10
  },
  oldAppVersionNote: {
    textAlign: 'center',
    fontSize: 13,
    marginHorizontal: 7,
    color: Theme.SECONDARY_TEXT_COLOR,
    marginBottom: 2
  },
  addFriendsContainer: {
    height: '740px',
    width: '100%',
    backgroundColor: 'white',
    zIndex: '999',
    position: 'absolute'
  }
};
