import React, { useRef } from 'react';
import { useSelector } from "react-redux";

import { UserProfileModal } from "../../profile/components/profile/user-profile-modal.container";
import { selectIsModalOpen } from "../friends.reducer";

export const FriendsProfileModal = props => {
  const isModalOpen = useSelector(selectIsModalOpen);
  const wrapperRef = useRef(null);

  return isModalOpen ? (
    <div style={styles.container}>
      <div ref={wrapperRef} style={styles.modal}>
        <UserProfileModal {...props} />
      </div>
    </div>
  ) : null
}

const styles = {
  container: {
    zIndex: '10000000',
    width: '100%',
    height: 'calc(100vh - 126px)',
    top: '126px',
    position: 'absolute'
  },
  modal: {
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.40)',
    width: '80%',
    overflowX: 'hidden',
    margin: '0 auto',
    maxHeight: 'calc(100vh - 150px)',
    overflowY: 'auto',
  }
}
