import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { propType } from 'graphql-anywhere';
import { FeedNewsItemFragment } from '../../../graphql/feed-news-item.fragment';
import ProfileImage from '../../../../common/components/profile-image.rn';
import { LangData } from '../../../../assets/locales/localization';
import Comments from '../feed-event-item/comments.rn';
import moment from 'moment';
import * as commentActions from '../../../comments.actions';
import { Theme } from '../../../../common/providers/colors';

export class FeedNewsItemView extends Component {
  static propTypes = {
    news: propType(FeedNewsItemFragment).isRequired,
    onPicClicked: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      comments: [],
      loadingComments: true,
      lastCommentUpdate: null,
      maxIndex: 0,
      minIndex: 0
    };

    this.actions = commentActions;
    this.scrollIndex = 0;
  }

  openNewsUrl(rawUrl) {
    const newsUrl = (rawUrl || '').trim();
    if (rawUrl !== '') {
      window.open(newsUrl, '_blank');
    }
  }

  copyComment(comment) {
    let copyComment = {};
    copyComment.id = comment.id;
    copyComment.body = comment.body;
    copyComment.email = comment.email;
    copyComment.parentId = comment.parentId;
    copyComment.userId = comment.userId;
    copyComment.userName = comment.userName;
    copyComment.createdAt = comment.createdAt;
    copyComment.postId = comment.postId;
    copyComment.userProfileImg = comment.userProfileImg;
    copyComment.likes = JSON.parse(comment.likes);
    return copyComment;
  }

  updateEventLikes(type) {
    let eventLikes = [];

    let eventItemLikes = JSON.parse(this.props.news.likes);
    if (eventItemLikes && eventItemLikes.length > 0)
      eventLikes = eventItemLikes.slice();

    let exist = eventLikes.filter(
      like => like.givingUser === this.props.currentUser.id
    );
    if (exist.length > 0) {
      const index = eventLikes.findIndex(like => {
        return like.givingUser === this.props.currentUser.id;
      });
      eventLikes.splice(index, 1);
    } else {
      //add new like
      let newLike = {};
      newLike.givedAt = moment().format('YYYY-MM-DD H:mm:ss');
      newLike.userName = this.props.currentUser.name;
      newLike.givingUser = this.props.currentUser.id.toString();
      newLike.userProfileImg = this.props.currentUser.profilePictureUrl;
      newLike.type = type;
      eventLikes.push(newLike);
    }
    let savedLikes = JSON.stringify(eventLikes);

    // send request
    this.props
      .updatePostLikes(this.props.news.id, savedLikes)
      .then(({ data }) => {
        console.log(data);
      })
      .catch(e => console.log(e));
  }

  updateComments(field, comment, text) {
    switch (field) {
      case 'likes':
        let copyComment = this.copyComment(comment);
        let commentLikes = [];
        if (copyComment.likes.length === 0) commentLikes = [];
        else commentLikes = copyComment.likes.slice();
        let exist = commentLikes.filter(
          like => like.givingUser === this.props.currentUser.id
        );
        if (exist.length > 0) {
          const index = commentLikes.findIndex(like => {
            return like.givingUser === this.props.currentUser.id;
          });
          commentLikes.splice(index, 1);
          let savedLikes = JSON.stringify(commentLikes);
          this.props
            .createUpdateComment(this.props.currentUser.id, comment.id, {
              ...copyComment,
              likes: savedLikes
            })
            .then(({ data }) => {
              let copyComment = this.copyComment(comment);
              const objIndex = this.state.comments.findIndex(
                comment => comment.id === copyComment.id
              );

              let currentLikes = [];
              if (data.createUpdateComment.likes.length > 0)
                currentLikes = [data.createUpdateComment.likes];
              const updatedObj = {
                ...this.state.comments[objIndex],
                likes: currentLikes,
                liked: false
              };
              let existComments = [...this.state.comments];
              existComments[objIndex] = updatedObj;
              this.setState({ comments: existComments });
            })
            .catch(e => {
              console.warn(e);
            });
        } else {
          //add new like
          let newLike = {};
          newLike.givedAt = moment().format('YYYY-MM-DD H:mm:ss');
          newLike.givingUser = this.props.currentUser.id.toString();
          commentLikes.push(newLike);
          let savedLikes = JSON.stringify(commentLikes);
          this.props
            .createUpdateComment(this.props.currentUser.id, comment.id, {
              ...copyComment,
              likes: savedLikes
            })
            .then(({ data }) => {
              let copyComment = this.copyComment(data.createUpdateComment);
              const objIndex = this.state.comments.findIndex(
                comment => comment.id === copyComment.id
              );
              let currentLikes = copyComment.likes.slice();
              let modifiedComments = this.state.comments.slice();
              const updatedObj = {
                ...this.state.comments[objIndex],
                likes: JSON.stringify(currentLikes),
                liked: true
              };
              modifiedComments[objIndex] = updatedObj;
              this.setState({ comments: modifiedComments });
            })
            .catch(e => {
              console.warn(e);
            });
        }
        break;
      case 'edit':
        let cpy = this.copyComment(comment);
        this.props
          .createUpdateComment(this.props.currentUser.id, comment.id, {
            ...cpy,
            body: text
          })
          .then(({ data }) => {
            let copyComment = this.copyComment(comment);
            const objIndex = this.state.comments.findIndex(
              comment => comment.id === copyComment.id
            );
            const updatedObj = {
              ...this.state.comments[objIndex],
              body: data.createUpdateComment.body
            };
            let existComments = [...this.state.comments];
            existComments[objIndex] = updatedObj;
            this.setState({ comments: existComments });
          })
          .catch(e => {
            console.warn(e);
          });
        break;
      case 'reported':
        let copy = this.copyComment(comment);
        this.props
          .createUpdateComment(this.props.currentUser.id, comment.id, {
            ...copy,
            reported: !copy.reported
          })
          .then(({ data }) => {
            let copyComment = this.copyComment(comment);
            const objIndex = this.state.comments.findIndex(
              comment => comment.id === copyComment.id
            );
            const updatedObj = {
              ...this.state.comments[objIndex],
              reported: data.createUpdateComment.reported
            };
            let existComments = [...this.state.comments];
            existComments[objIndex] = updatedObj;
            this.setState({ comments: existComments });
          })
          .catch(e => {
            console.warn(e);
          });
        break;
      default:
        break
    }
  }

  deleteComment(comment) {
    let cpy = this.copyComment(comment);
    let existComments = this.state.comments.slice();
    const index = this.state.comments.findIndex(
      comment => comment.id === cpy.id
    );
    existComments.splice(index, 1);
    this.setState({ comments: existComments });
    this.props
      .deleteComment(comment.id)
      .then(res => console.log('got result from deleteComment', res))
      .catch(err => console.log('got error', err));
  }

  saveComment(text) {
    this.props
      .createUpdateComment(this.props.currentUser.id, this.props.editEntityId, {
        parentId: '',
        userId: this.props.currentUser.id,
        userName: this.props.currentUser.name,
        userProfileImg: this.props.currentUser.profilePictureUrl,
        createdAt: moment(new Date()).toDate(),
        body: text,
        email: this.props.currentUser.msjEmail,
        likes: '[]',
        postId: this.props.news.id
      })
      .then(({ data }) => {
        let tempComments = this.state.comments.slice();
        let newComment = data.createUpdateComment;
        tempComments.unshift(newComment);
        tempComments.pop();
        this.setState({ comments: tempComments });
      })
      .catch(e => {
        console.warn(e);
      });
  }

  saveReplay(parentId, text) {
    this.props
      .createUpdateComment(this.props.currentUser.id, this.props.editEntityId, {
        parentId: parentId,
        userId: this.props.currentUser.id,
        userName: this.props.currentUser.username,
        userProfileImg: this.props.currentUser.profilePictureUrl,
        createdAt: Date.now().toString(),
        body: text,
        email: this.props.currentUser.msjEmail,
        postId: this.props.news.id,
        likes: '[]'
      })
      .then(({ data }) => {
        console.log('got data from createUpdateComment', data);
      })
      .catch(e => {
        console.warn(e);
      });
  }

  extractUsername(c) {
    try {
      return c.userName !== '' ? c.userName : null;
    } catch (e) {
      console.log(e);
    }
  }

  extractBody(c) {
    try {
      return c.body && c.body !== '' ? c.body : null;
    } catch (e) {
      console.log(e);
    }
  }

  extractImage(c) {
    try {
      return c.image_id && c.user.image_id !== '' ? c.user.image_id : '';
    } catch (e) {
      console.log(e);
    }
  }

  extractChildrenCount(c) {
    try {
      return c.childrenCount || 0;
    } catch (e) {
      console.log(e);
    }
  }

  extractEditTime(item) {
    try {
      return item.updated_at;
    } catch (e) {
      console.log(e);
    }
  }

  extractCreatedTime(item) {
    try {
      return item.created_at;
    } catch (e) {
      console.log(e);
    }
  }

  likeExtractor(item) {
    if (!item.likes) return false;
    if (item.likes.length === 0) return false;
    let existLikes = [];
    if (typeof item.likes[0] === 'string')
      existLikes = [...JSON.parse(item.likes)];
    else existLikes = item.likes.slice();
    let exist = existLikes.filter(
      like => like.givingUser === this.props.currentUser.id
    );
    if (exist.length > 0) return true;
    return false;
  }

  likeEventExtractor() {
    let eventLikes = JSON.parse(this.props.news.likes);
    if (!eventLikes) return false;
    if (eventLikes.length === 0) return false;
    let existLikes = [];
    if (typeof eventLikes[0] === 'string')
      existLikes = [...JSON.parse(eventLikes)];
    else existLikes = eventLikes.slice();
    let exist = existLikes.filter(
      like => like.givingUser === this.props.currentUser.id
    );
    if (exist.length > 0) return true;
    return false;
  }

  reportedExtractor(item) {
    return item.reported;
  }

  async likesExtractor(item) {
    let itemLikes = JSON.parse(item.likes);
    let users = [];
    if (itemLikes && itemLikes.length > 0) {
      users = itemLikes.map(like => ({
          profilePictureUrl: like.userProfileImg,
          name: like.userName,
          likeType: like.type
        })
      );
    }
    return users;
  }

  isCommentChild(item) {
    return item.parentId !== null;
  }

  render() {
    const { news } = this.props;

    return (
      <Fragment>
        <div
          style={styles.container}
          // onClick={() => this.openNewsUrl(news.link)}
        >
          <div style={styles.detailsContainer}>
            <div style={styles.topContainer}>
              <ProfileImage
                onClick={() => {
                  this.props.onItemPress(news.author);
                  this.props.toggleComponent('UserProfile');
                  this.props.history.push('userProfile', { user: news.author });
                }}
                height={55}
                width={55}
                withFullSize={false}
                imageUrl={news && news.author ? news.author.profilePictureUrl : ""}
              />

              <div style={styles.titleContainer}>
                <p style={styles.titleText} onClick={() => {
                  this.props.onItemPress(news.author);
                  this.props.toggleComponent('UserProfile');
                  this.props.history.push('userProfile', { user: news.author });
                }}>{news.title}</p>
                <p style={styles.dateText}>
                  {news.creationDate}
                </p>
              </div>
            </div>
            <div style={styles.descriptionContainer}>
              <div style={styles.descriptionContainer}>
                <p style={styles.descriptionText}>{news.description}</p>
              </div>
              {news.link !== '' && (
                <p
                  style={styles.linkText}
                  onClick={() => this.openNewsUrl(news.link)}
                >
                  {LangData.feedNewsItem.readMore}
                </p>
              )}
            </div>
          </div>
        </div>
        <div style={styles.imageContainer}>
          {news.posterImageUrl && (
            <div
              style={styles.newsPosterContainer}
              onClick={e => {
                e.stopPropagation();
                this.props.onPicClicked(news.posterImageUrl);
              }}
            >
              <img style={styles.newsImage} src={news.thumbUrl} alt=""/>
            </div>
          )}
        </div>
        <Comments
          data={this.props.news.comments}
          postId={this.props.news.id}
          eventLikes={this.props.news.likes}
          // usersWhoLikedTheEvent={this.getUsers(this.props.eventItem.likes)}
          viewingUserName={this.props.currentUser}
          childPropName={'children'}
          isChild={item => this.isCommentChild(item)}
          //Extract the key indicating comments parent
          parentIdExtractor={item => item.parentId}
          //what prop holds the comment owners username
          usernameExtractor={item => this.extractUsername(item)}
          //when was the comment last time edited
          editTimeExtractor={item => this.extractEditTime(item)}
          //When was the comment created
          createdTimeExtractor={item => this.extractCreatedTime(item)}
          //where is the body
          bodyExtractor={item => this.extractBody(item)}
          //where is the user image
          imageExtractor={item => this.extractImage(item)}
          //Where to look to see if user liked comment
          likeExtractor={item => this.likeExtractor(item)}
          //Where to look to see if user reported comment
          reportedExtractor={item => this.reportedExtractor(item)}
          //Where to find array with user likes
          likesExtractor={item => this.likesExtractor(item)}
          likesEventsExtractUsers={() => this.likesExtractor(this.props.news)}
          likeEventExtractor={() => this.likeEventExtractor()}
          //what to do when user clicks reply. Usually its header height + position (b/c scroll list heights are relative)
          replyAction={offset => {
            this.refs.scrollView.scrollTo({
              x: null,
              y: this.scrollIndex + offset - 300,
              animated: true
            });
          }}
          //what to do when user clicks submits edited comment
          saveAction={(text, parentCommentId) => {

            if (!parentCommentId) {
              this.saveComment(text);

              // this.refs.scrollView.scrollToEnd();
            } else {
              this.saveReplay(parentCommentId, text);
            }
          }}
          //what to do when user clicks submits edited comment
          editAction={(text, comment) => {
            this.updateComments('edit', comment, text);
          }}
          //what to do when user clicks report submit
          reportAction={comment => {
            let comments = this.actions.report(this.state.comments, comment);
            this.setState({
              comments: comments
            });
          }}
          //what to do when user clicks like
          likeAction={comment => {
            this.updateComments('likes', comment, '');
            this.actions.like(this.state.comments, comment);
          }}
          // like event
          likeEventPost={type => {
            this.updateEventLikes(type);
          }}
          //what to do when user clicks like
          deleteAction={comment => {
            this.deleteComment(comment);
          }}
          //Must return promise
          paginateAction={(from_comment_id, direction, parent_comment_id) => {
            //Must return array of new comments after pagination
            this.updatePagination(direction);
          }}
        />
      </Fragment>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row'
  },
  linkText: {
    marginLeft: 2,
    fontSize: 17,
    color: Theme.TEXT_COLOR,
    fontWeight: 'bold',
    marginTop: 5
  },
  newsDescriptionText: {
    color: Theme.TEXT_COLOR,
    fontSize: 15,
    marginLeft: 5
  },
  topContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1
  },
  detailsContainer: {
    flex: 0.7
  },
  newsPosterContainer: {
    flex: 0.3,
    borderLeftWidth: 1,
    borderLeftColor: 'rgba(202, 205, 209, 0.2)',
    paddingLeft: 5
  },
  newsImage: {
    overflow: 'hidden',
    maxHeight: 300,
    display: 'block',
    margin: '0 auto',
    borderRadius: 10,
    minHeight: 120,
    width: '100%',
    objectFit: 'cover'
  },
  titleText: {
    color: Theme.BLACK_COLOR,
    fontSize: 17,
    marginBottom: 0,
    marginTop: 0,
    letterSpacing: "0.2px",
    fontWeight: 'bold'
  },
  dateText: {
    fontSize: 12,
    color: Theme.BLACK_COLOR,
    marginTop: 0,
    marginBottom: 0,
    letterSpacing: "0.2px"
  },
  descriptionContainer: {
    marginTop: 5,
    marginBottom: 7,
    flex: 1
  },
  titleContainer: {
    marginLeft: '12px',
    flex: 1,
    justifyContent: 'center',
    cursor: 'pointer',
  },
  descriptionText: {
    fontSize: 18,
    color: Theme.BLACK_COLOR,
    margin: 0
  }
};
