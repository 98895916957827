import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from '../../../../common/components/loading-spinner.rn';
import ProfileImage from '../../../../common/components/profile-image.rn';
//import { ImageGalleryViewerModal } from '../../../../common/components/image-gallery-viewer-modal';
import { LangData } from '../../../../assets/locales/localization';
import { Theme } from '../../../../common/providers/colors';

class EventLiveFeedView extends Component {
  static propTypes = {
    eventId: PropTypes.string.isRequired,
    ended: PropTypes.bool,
    currentUser: PropTypes.any,
    uploadingData: PropTypes.shape({
      data: PropTypes.any,
      progress: PropTypes.number
    }),
    liveFeedImages: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      networkStatus: PropTypes.number,
      error: PropTypes.any,
      feedItem: PropTypes.any
    })
  };

  constructor(props) {
    super(props);

    this.state = {
      galleryData: null
    };
  }

  openGallery(index) {
    const { feedItem } = this.props.liveFeedImages;

    if (!feedItem) {
      return;
    }

    const images = Array.from(
      feedItem && feedItem.liveFeedImages ? feedItem.liveFeedImages : []
    ).map(item => item.photoUrl);

    this.setState({
      galleryData: {
        title: feedItem.title,
        images,
        startIndex: index
      }
    });
  }

  renderItem(item, index) {
    if (item.id === 'uploading') {
      return (
        <div style={styles.listItem}>
          <div>
            <img style={styles.image} src={item.data} alt="event" />
            <div style={styles.pending}>
              <span style={styles.pendingText}>
                {LangData.formatString(
                  LangData.eventLiveFeed.upload,
                  item.progress.toFixed(0)
                )}
              </span>
            </div>
          </div>
          <div style={styles.detailsContainer} id='detailsContainer'>
            <ProfileImage
              imageUrl={this.props.currentUser.profilePictureUrl}
              size={26}
            />
            <div style={styles.name}>
              <span style={styles.nameText}>{this.props.currentUser.name}</span>
            </div>
          </div>
        </div>
      );
    }

    if (item.id === 'fillFlex') {
      return <div style={{ flex: 0.5 }} />;
    }

    const { thumbUrl } = item;

    return (
      <div
        style={styles.listItem}
        onClick={() => this.openGallery(index)}
        id='EventLiveFeedView row90'
      >
        <div style={styles.innerContainer}>
          <img style={styles.image} src={thumbUrl} alt="event" />
          {item.pending && (
            <div style={styles.pending}>
              <span style={styles.pendingText}>
                {item.id.startsWith('temp_')
                  ? LangData.eventLiveFeed.uploading
                  : LangData.eventLiveFeed.pending}
              </span>
            </div>
          )}
          {!item.approved &&
            item.rejectionReason &&
            !item.id.startsWith('temp_') && (
              <div style={styles.pending}>
                <span style={styles.rejectedText}>
                  {LangData.formatString(
                    LangData.eventLiveFeed.reject,
                    item.rejectionReason
                  )}
                </span>
              </div>
            )}

          <div style={styles.detailsContainer} id='detailsContainer'>
            <ProfileImage
              imageUrl={item.uploadedBy.profilePictureUrl}
              size={26}
            />
            <div style={styles.name}>
              <span style={styles.nameText}>{item.uploadedBy.name}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.props.liveFeedImages.networkStatus === 1) {
      return <LoadingSpinner bgcolor={'rgba(0, 0, 0, 0.2)'} />;
    }

    const { feedItem } = this.props.liveFeedImages;
    const imagesArr = Array.from(
      feedItem && feedItem.liveFeedImages ? feedItem.liveFeedImages : []
    );

    if (imagesArr.length % 2 === 1) {
      imagesArr.push({ id: 'fillFlex' });
    }

    if (this.props.uploadingData && this.props.uploadingData.data) {
      imagesArr.unshift({
        id: 'uploading',
        data: this.props.uploadingData.data,
        progress: this.props.uploadingData.progress
      });
    }

    return (
      <div
        style={styles.container}
        id='container'
        onClick={e => e.stopPropagation()}
      >
        {/* <ImageGalleryViewerModal
          data={this.state.galleryData}
          onClose={() => this.setState({ galleryData: null })} /> */}
        {imagesArr.length === 0 ? (
          this.props.ended ? (
            <span style={styles.zeroUploads}>
              {LangData.eventLiveFeed.noUpload}
            </span>
          ) : (
            <span style={styles.zeroUploads}>
              {LangData.eventLiveFeed.first}
            </span>
          )
        ) : (
          <div style={styles.list} id='list'>
            {imagesArr.map((item, index) => {
              return this.renderItem(item, index);
            })}
          </div>
        )}
      </div>
    );
  }
}

export default EventLiveFeedView;

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflowY: 'scroll',

    position: 'relative',

    maxHeight: '130px'
  },
  list: {
    display: 'grid',
    width: '100%',
    marginTop: '3%',
    gridTemplateColumns:
      window.innerWidth / 2 - 10 + 'px ' + (window.innerWidth / 2 - 10) + 'px '
  },
  listItem: {
    alignItems: 'center',
    marginBottom: '15px',
    height: '100px'
  },
  image: {
    width: '100px',
    borderRadius: 5
  },
  innerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative'
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    position: 'absolute',
    width: '30%',
    left: '0',
    bottom: '-13px'
  },
  name: {
    marginLeft: 5
  },
  nameText: {
    fontSize: 15,
    color: Theme.TEXT_COLOR
  },
  zeroUploads: {
    textAlign: 'center',
    color: Theme.SECONDARY_TEXT_COLOR,
    fontSize: 12,
    fontStyle: 'italic'
  },
  pending: {
    zIndex: 10,
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    top: 0,
    backgroundColor: 'rgba(255,255,255,0.5)'
  },
  pendingText: {
    opacity: 0.9,
    fontWeight: 'bold',
    color: Theme.SECONDARY_TEXT_COLOR,
    marginTop: 10,
    marginLeft: 10,
    fontSize: 12
  },
  rejectedText: {
    opacity: 0.9,
    fontWeight: 'bold',
    color: '#bd5319',
    marginTop: 10,
    marginLeft: 10,
    fontSize: 12
  }
};
