import React, { useEffect, useState, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { Theme } from '../common/providers/colors';
import { logout } from '../account/account.util';
import PromptMessage from '../settings/prompt-message';
import Fade from 'react-reveal/Fade';
import menuBackground from '../assets/images/menuBackground.png'
import yogaSessionsLogo from '../assets/images/yogaSessionsLogo.png';
import yogaPosesLogo from '../assets/images/yogaPosesLogo.png';
import yogaModificationsLogo from '../assets/images/yogaModificationsLogo.png';
import friendsLogo from '../assets/images/friendsLogo.png';
import videoMeeting from '../assets/images/videoMeeting.png';
import notificationsLogo from '../assets/images/notificationsLogo.png';
import home from '../assets/images/home.png';
import chatsLogo from '../assets/images/chatsLogo.png';
import groupsLogo from '../assets/images/groupsLogo.png';
import settingsLogo from '../assets/images/settingsLogo.png';
import logoutLogo from '../assets/images/logoutLogo.png';
import yogaScienceLogo from '../assets/images/yogaScienceLogo.png';
import { useLocation } from "react-router-dom";

const width = window.innerWidth

const MenuView = props => {
  const [promptLogout, setPromptLogOut] = useState(false);
  const location = useLocation();
  console.log('location: ', location.pathname);


  useEffect(() => {
    let button = document.getElementById("show-zendesk")
    if (button) {
      button.click()
    }
    return () => {
      let button = document.getElementById("hide-zendesk")
      if (button) {
        button.click()
      }
    }
  }, [])


  return (
    <Fragment>
      <Fade left>
        <div style={width < 500 ? styles.mainContainer : styles.mainContainerDesktop}>
          <div style={styles.centerContainer}>
            <FontAwesomeIcon
              icon={faTimes}
              onClick={() => props.closeMenu()}
              style={styles.closeContainer}
            />
            <div
              style={styles.linkContainer}
              onClick={() => {
                width<500 && props.closeMenu();
                props.setIndex && props.setIndex(0);
                if(location.pathname !== '/welcome' || location.pathname !== '/'){
                  props.history.push('/');
                }
              }}
            >
              <img src={home} style={styles.icon} alt=""/>
              <span style={styles.title}>Home</span>
            </div>
            <span style={styles.separator}/>
            {width < 500 && <div
              style={styles.linkContainer}
              onClick={() => {
                props.toggleComponent('Notifications');
                props.setIndex && props.setIndex(2);
                width<500 && props.closeMenu();
              }}
            >
              {props.notificationsCount && width > 500 ? <div style={styles.notificationBubble}>
                {props.notificationsCount}
              </div> : null}
              <img src={notificationsLogo} style={styles.icon} alt=""/>
              <span style={styles.title}>Notifications</span>
            </div>}
            <span style={styles.separator}/>

            {/* <div
              style={styles.linkContainer}
              onClick={() => {
                props.toggleComponent('Meetings');
                props.history.push('meetings');
              }}
            >
              <img src={videoMeeting} style={styles.icon} alt=""/>
              <span style={styles.title}>Live Streaming</span>
            </div> */}
            <span style={styles.separator}/>
            <div
              style={styles.linkContainer}
              onClick={() => {
                props.toggleComponent('Videos');
                props.history.push({
                  pathname: 'videos',
                  state: {
                    yocasType: 'session'
                  }
                });
              }}
            >
              <img src={yogaSessionsLogo} style={styles.icon} alt=""/>
              <span style={styles.title}>Yoga Sessions</span>
            </div>
            <span style={styles.separator}/>
            <div
              style={styles.linkContainer}
              onClick={() => {
                props.toggleComponent('Videos');
                props.history.push({
                  pathname: 'videos',
                  state: {
                    yocasType: 'Pose'
                  }
                });
              }}
            >
              <img src={yogaPosesLogo} style={styles.icon} alt=""/>
              <span style={styles.title}>Yoga Poses</span>
            </div>
            <span style={styles.separator}/>
            <div
              style={styles.linkContainer}
              onClick={() => {
                props.toggleComponent('Videos');
                props.history.push({
                  pathname: 'videos',
                  state: {
                    yocasType: 'modification'
                  }
                });
              }}
            >
              <img src={yogaModificationsLogo} style={styles.icon} alt=""/>
              <span style={styles.title}>Yoga Modifications</span>
            </div>
            {/* <span style={styles.separator}/>
            {width < 500 && <div
              style={styles.linkContainer}
              onClick={() => {
                props.toggleComponent('Courses');
                props.history.push('courses');
              }}
            >
              <img src={groupsLogo} style={styles.icon} alt=""/>
              <span style={styles.title}>Groups</span>
            </div>} */}
            <span style={styles.separator}/>
            {/* <div
              style={styles.linkContainer}
              onClick={() => {
                width<500 && props.closeMenu();
                props.toggleComponent('Friends');
                props.setIndex && props.setIndex(1);
                props.history.push({
                  pathname: '/',
                  state: {
                    index: 1
                  }
                })
              }}
            >
              <img src={friendsLogo} style={styles.icon} alt=""/>
              <span style={styles.title}>Friends</span>
            </div> */}
            {/* <span style={styles.separator}/>
            {width < 500 && <div
              style={styles.linkContainer}
              onClick={() => {
                width<500 && props.closeMenu();
                props.toggleComponent('Chat');
                props.setIndex && props.setIndex(3);
              }}
            >
              <img src={chatsLogo} style={styles.icon} alt=""/>
              <span style={styles.title}>Chats</span>
            </div>} */}
            <span style={styles.separator}/>

            <div
              style={styles.linkContainer}
              onClick={() => {
                props.toggleComponent('Manuals');
                props.history.push('manuals');
              }}
            >
              <img src={yogaScienceLogo} style={styles.icon} alt=""/>
              <span style={styles.title}>Yoga Science</span>
            </div>
            <span style={styles.separator}/>
          </div>

          <div style={styles.bottomContainer}>
            <div
              style={styles.linkContainer}
              onClick={() => {
                props.toggleComponent('Settings');
                props.history.push('settings');
              }}
            >
              {/* <img src={settingsLogo} style={styles.icon} alt=""/>
              <span style={styles.title}>Settings</span>
            </div>
            <span style={styles.separator}/>
            <div
              style={styles.linkContainer}
              onClick={() => {
                setPromptLogOut(true);
              }}
            > */}
              <img src={logoutLogo} style={styles.icon} alt=""/>
              <span style={styles.title}>Log Out</span>
            </div>
          </div>
        </div>
      </Fade>
      {promptLogout && (
        <PromptMessage
          message='Are you sure you want to logout?'
          onSubmit={() => {
            setPromptLogOut(false);
            logout();
            props.history.push('/intro');
          }}
          onCancel={() => {
            setPromptLogOut(false);
          }}
        />
      )}
    </Fragment>
  );
};

export default MenuView;

const styles = {
  mainContainer: {
    color: Theme.TEXT_COLOR,
    width: width < 500 ? '60%' : '30%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    minHeight: window.innerHeight,
    zIndex: 999,
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundImage: `url(${menuBackground})`,
    backgroundSize: '100% 100%',
    opacity: 0.95,
    padding: '2%',
    justifyContent: width < 500 ? 'space-between' : 'unset',
    fontWeight: 'bold',
    backgroundRepeat: 'no-repeat'
  },
  
  mainContainerDesktop: {
    color: Theme.TEXT_COLOR,
    height: width < 500 ? '100vh' : 'calc(100vh - 120px)',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    minHeight: width < 500 ? window.innerHeight : '200px',
    zIndex: 999,
    position: 'fixed',
    top: width < 500 ? 0 : '120px',
    left: 0,
    backgroundColor: 'white',
    backgroundSize: '100% 100%',
    opacity: 0.95,
    justifyContent: width < 500 ? 'space-between' : 'unset',
    fontWeight: 'bold',
    backgroundRepeat: 'no-repeat',
    borderRadius: '0 0.75rem 0.75rem 0',
    boxShadow: 'rgba(0, 0, 0, 0.04) 15px 15px 15px, rgba(0, 0, 0, 0.44) 15px 15px 15px'
  },
  pageTitle: {
    width: '100%',
    padding: '1% 5%'
  },
  profileContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '1% 5%'
  },
  userName: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '5%'
  },
  otherLinksContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1% 5%',
    fontSize: '19px'
  },
  linksRow: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '1% 5%'
  },
  linkContainer: {
    width: width < 500 ? '100%' : '15vw',
    color: Theme.NAV_LINK_COLOR,
    display: 'flex',
    flexDirection: width < 500 ? 'row' : 'row',
    alignItems: 'center',
    padding: width < 500 ? '1%' : '3%',
    margin: width < 500 ? '5%' : '10px',
    fontSize: width < 500 ? 'larger' : '15px',
    textAlign: 'initial',
    cursor: 'pointer',
    position: 'relative',
  },
  notificationBubble: {
    width: '16px',
    height: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    borderRadius: '50%',
    backgroundColor: 'red',
    fontSize: '13px',
    left: '20px',
    top: '5px',
    position: 'absolute',
  },
  icon: {
    margin: width < 500 ? 'unset' : '0',
    marginRight: width < 500 ? '5%' : '1vw',
    width: width < 500 ? '13%' : '25px'
  },
  closeContainer: {
    display: width > 500 ? 'none':'unset',
    margin: width < 500 ? '23% 2% 4% 5%' : '2px 5px 10px 5px',
    // marginBottom: '20%',
    borderRadius: "50%",
    padding: "2px 2px",
    width: "20px",
    height: "20px",
    border: "2px solid #545267",
    cursor: 'pointer',
    color: Theme.NAV_LINK_COLOR
  },
  bottomContainer: {
    marginBottom: width < 500 ? '10%' : '0',
    width: width < 500 ? 'unset' : '100%',
  },
  separator: {
    height: '1px',
    width: '100%',
    backgroundColor: '#f3f3f3',
    display: width < 500 ? 'none' : 'block'
  },
  title: {
    fontWeight: 'normal',
    fontSize: width < 500 ? '5vw' : '1.3rem',
  }
};
