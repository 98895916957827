import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { ChatNavBar } from '../common/chat-nav-bar.rn';
import ClassmateGrid from './classmate-grid.rn';
import { ChatFriendsSearchBar } from './chat-friends-search-bar.rn';
import { ChatPickedChips } from './chat-picked-chips.rn';
import EmptyNotice from '../../../common/components/empty-notice.rn';
import LoadingSpinner from '../../../common/components/loading-spinner.rn';
import { LangData } from '../../../assets/locales/localization';
import { Theme } from '../../../common/providers/colors';

const width = window.innerWidth;

export class FindFriendsForChatView extends Component {
  static propTypes = {
    connections: PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.any,
      networkStatus: PropTypes.number,
      myConnections: PropTypes.array
    }),
    onClose: PropTypes.func.isRequired,
    onDone: PropTypes.func.isRequired,
    navigateToChild: PropTypes.func.isRequired,
    onUsersChanged: PropTypes.func,
    users: PropTypes.array,
    hideUsers: PropTypes.array,
    title: PropTypes.string,
    step: PropTypes.number,
    stepsConfig: PropTypes.array
  };

  constructor(props) {
    super(props);

    this.state = {
      term: '',
      loadMoreConnections: true,
      currentSkip: 0
    };
  }

  getConfig() {
    if (this.props.stepsConfig && this.props.stepsConfig.length > 0) {
      return this.props.stepsConfig[this.props.step];
    }

    return {
      showNavBar: true,
      showSearch: true,
      showChips: true,
      usersGrid: true,
      content: null
    };
  }

  getClassmatesGridData() {
    const connections = (this.props.connections.myConnections
      ? this.props.connections.myConnections
      : []
    ).filter(
      classmate => !this.props.hideUsers.find(u => u.id === classmate.id)
    );


    const relevantUsers = connections.filter(classmate =>
      [classmate.name, classmate.username].some(text =>
        {
          if(this.state.term !== null && text) return text.toLowerCase().includes(this.state.term.toLowerCase())
          else return ""
        }
      )
    );

    return {
      loading: false,
      error: this.props.connections.error,
      classmates: relevantUsers
    };
  }

  loadMoreData = () => {
    let currentSkip = this.state.currentSkip
    this.props.loadMoreConnectionsData(currentSkip + 10);
    this.setState({ currentSkip: currentSkip + 10})
    this.getClassmatesGridData();
  };

  toggleFriendToList(friend) {
    let newState = null;

    if (this.props.users.find(i => i.id === friend.id)) {
      newState = this.props.users.filter(i => i.id !== friend.id);
    } else if (friend.username === 'helpdesk') {
      newState = [friend];
    } else {
      newState = [friend, ...this.props.users];

      if (
        newState.find(u => u.username === 'helpdesk') &&
        newState.length > 1
      ) {
        newState = newState.filter(u => u.username !== 'helpdesk');
      }
    }

    this.props.onUsersChanged(newState);
  }

  goToFriendsList() {
    this.props.toggleComponent('Friends');
    this.props.history.push('/');
  }

  done() {
    this.props.onDone(this.props.users);
  }

  renderInner(loading, usersGrid, hasClassmates, data, term) {
    if (loading) {
      return <LoadingSpinner />;
    }

    if (usersGrid) {
      if (hasClassmates) {
        return (
          <Fragment>
            <p style={styles.descriptionText}>
              Please select who you want to chat with (you can choose one or
              more)
            </p>

            <ClassmateGrid
              checkedItems={this.props.users}
              minimal={true}
              onItemPress={friend => this.toggleFriendToList(friend)}
              emptyMessage={LangData.findFriends.empty}
              data={data}
              origin='findFriends'
              handleOnScroll={this.loadMoreData}
            />
          </Fragment>
        );
      }
      return (
        <div
          style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
        >
          {term === '' ? (
            <div style={{ flex: 1 }}>
              <EmptyNotice
                containerStyle={{ flexGrow: 1 }}
                emoji='😯'
                title={LangData.findFriends.noYet}
              />
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <button
                  onClick={() => this.goToFriendsList()}
                  style={{
                    marginTop: '15px',
                    backgroundColor: Theme.BACKGROUND_COLOR,
                    color: 'white',
                    border: 'none',
                    padding: '10px',
                    fontWeight: 'bold',
                    fontFamily: 'inherit',
                    fontSize: '15px',
                    borderRadius: '20px',
                    width: '40%'
                  }}
                >
                  {LangData.findFriends.find}
                </button>
              </div>
            </div>
          ) : (
            <EmptyNotice
              containerStyle={{ flexGrow: 1 }}
              emoji='😯'
              title={LangData.findFriends.noFound}
            />
          )}
        </div>
      );
    }

    return null;
  }

  render() {
    const loading = this.props.connections
      ? this.props.connections.networkStatus === 1
      : false;
    const config = this.getConfig();
    const data = this.getClassmatesGridData();
    const hasClassmates = data && data.classmates && data.classmates.length > 0;
    return (
      <div style={styles.container}>
        {config.showNavBar && (
          <ChatNavBar
            type='findFriends'
            left={config.left}
            right={config.right}
            showSeparator={config.showSearch || config.showChips}
            title={this.props.title}
            onClose={() => {
              if(this.props.inModal) {
                this.props.closeCreateChatModal();
              }else {
                this.props.toggleComponent('Chat');
                this.props.history.push('/');
              }
            }}
            onDone={() => this.done()}
          >
            {config.showSearch && (
              <ChatFriendsSearchBar
                term={this.state.term}
                onTextChange={term => this.setState({ term })}
              />
            )}
            {config.showChips && (
              <ChatPickedChips
                picked={this.props.users}
                onRemove={user => this.toggleFriendToList(user)}
              />
            )}
          </ChatNavBar>
        )}
        <div style={styles.friendsList}>
          {this.renderInner(
            loading,
            config.usersGrid,
            hasClassmates,
            data,
            this.state.term
          )}
          {config.content && config.content()}
          {config.right !== null && (
            <div
              style={styles.submitBtn}
              onClick={() => {
                if (config.right === undefined) {
                  this.done();
                } else {
                  this.props.onDone();
                }
              }}
            >
              Continue
            </div>
          )}
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    backgroundColor: 'white',
    minHeight: width < 500 ? window.innerHeight : '80vh',
  },
  friendsList: {
    flex: 1,
    backgroundColor: 'white',
    marginTop: width < 500 ? '33%' : '0',
    width: '100%'
  },
  submitBtn: {
    position: 'fixed',
    bottom: '0',
    padding: width < 500 ? '5%' : '20px',
    fontFamily: 'inherit',
    fontSize: width < 500 ? window.innerWidth / 18 : 16,
    left: width < 500 ? window.innerWidth / 3.13 : 'unset',
    right: width < 500 ? 'unset' : '20%',
    fontWeight: 'bold',
    borderRadius: '30px',
    border: 'none',
    marginBottom: '5%',
    backgroundColor: Theme.SECONDARY_BACKGROUND_COLOR,
    color:'white',
    cursor: 'pointer'
  },
  descriptionText: {
    padding: width < 500 ? '5%' : '30px',
    textAlign: 'center',
    fontWeight: 'bold',
    marginTop: '0',
    marginBottom: '0',
    color: 'gray'
  }
};
