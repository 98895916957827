import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FeedEventItem } from '../../feed-list/feed-event-item/feed-event-item.container';
import AttendingList from './attending-list.rn';
import { EventLiveFeed } from './event-live-feed.container';
import { UploadEventFeedImage } from './upload-event-image.container';
import LiveDetailsScrollableTabView from './live-details-scrollable-tabview.rn';
import AttendingCornerButton from './attending-corner-button.rn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { analytics } from '../../../../analytics/report';
import { LangData } from '../../../../assets/locales/localization';
import SwipeableViews from 'react-swipeable-views';

class FeedEventLiveModalView extends Component {
  static propTypes = {
    event: PropTypes.any,
    ended: PropTypes.bool,
    me: PropTypes.any,
    children: PropTypes.node,
    onClose: PropTypes.func,
    onRsvpChanged: PropTypes.func,
    onPosterClicked: PropTypes.func,
    goingList: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      error: PropTypes.any,
      eventGoingList: PropTypes.any
    }),
    activeTab: PropTypes.number,
    setActiveTab: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      eventImageViewerData: null,
      uploadingImageData: null,
      uploadingImageProgress: 0
    };

    this._scrollable = null;
  }

  changeRSVP(newState) {
    this.props.onRsvpChanged(newState);
  }

  componentDidMount() {
    if (
      this._scrollable &&
      this.props.activeTab !== undefined &&
      this.props.activeTab !== null
    ) {
      // this._scrollable.goToPage(this.props.activeTab);
    }
  }

  changedActiveTab(index) {
    if (index === 0) {
      analytics.report('Feed', 'NavigateToAttendeeList', this.props.event.id);
    } else {
      analytics.report('Feed', 'NavigateToEventWall', this.props.event.id);
    }

    this.props.setActiveTab(index);
  }

  closeImageViewer() {
    this.setState({ eventImageViewerData: null });
  }

  onStartUpload(imageData) {
    this.setState({
      uploadingImageData: imageData
    });
  }

  onUploadProgress(progress) {
    this.setState({
      uploadingImageProgress: progress
    });
  }

  onUploadDone() {
    this.setState({
      uploadingImageData: null
    });
  }

  render() {
    const rsvpMutual = this.props.goingList.eventGoingList
      ? this.props.goingList.eventGoingList.mutual
      : null;
    const rsvpTotal = this.props.goingList.eventGoingList
      ? this.props.goingList.eventGoingList.total
      : null;

    return (
      <div style={styles.whiteBgContainer} className='whiteBgContainer'>
        <div onClick={() => this.props.onClose()} style={styles.closeContainer}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <FeedEventItem
          onLastImagesClicked={data => {
            analytics.report(
              'Feed',
              'LastImagesGalleryView',
              this.props.event.id
            );
            this.setState({ eventImageViewerData: data });
          }}
          extended={true}
          eventItem={this.props.event}
          onPosterClicked={this.props.onPosterClicked}
          history={this.props.history}
          showComments={false}
        />

        <LiveDetailsScrollableTabView
          rsvpMutual={
            this.props.activeTab === 1 && rsvpMutual > 0 ? rsvpMutual : null
          }
          changeIndex={index => this.changedActiveTab(index)}
          index={this.props.activeTab ? this.props.activeTab : 0}
          ref={ref => (this._scrollable = ref)}
          leftTabLabel={LangData.liveModal.live}
          rightTabLabel={`${
            !this.props.ended
              ? LangData.liveModal.attending
              : LangData.liveModal.attended
          } ${rsvpTotal !== null ? `(${rsvpTotal})` : ''}`}
        />
        <SwipeableViews
          index={this.props.activeTab}
          onChangeIndex={i => this.changedActiveTab(i)}
        >
          <EventLiveFeed
            uploadingData={{
              data: this.state.uploadingImageData,
              progress: this.state.uploadingImageProgress
            }}
            ended={this.props.ended}
            eventId={this.props.event.id}
            tabLabel={LangData.liveModal.live}
          />

          <AttendingList
            onRequestClose={() => this.props.onClose()}
            ended={this.props.ended}
            me={this.props.me}
            goingList={this.props.goingList}
            eventId={this.props.event.id}
            tabLabel={`${
              !this.props.ended
                ? LangData.liveModal.attending
                : LangData.liveModal.attended
            } ${rsvpTotal !== null ? `(${rsvpTotal})` : ''}`}
          />
        </SwipeableViews>

        {this.props.activeTab === 1
          ? !this.props.ended && (
              <AttendingCornerButton
                event={this.props.event}
                changeRSVP={response => this.changeRSVP(response)}
              />
            )
          : !this.props.ended && (
              <UploadEventFeedImage
                onUploadDone={() => this.onUploadDone()}
                onUploadProgress={progress => this.onUploadProgress(progress)}
                onStartUpload={imageData => this.onStartUpload(imageData)}
                event={this.props.event}
              />
            )}
        {/* <ImageGalleryViewerModal
          onClose={() => this.closeImageViewer()}
          data={this.state.eventImageViewerData}
        /> */}
      </div>
    );
  }
}

export default FeedEventLiveModalView;

const styles = {
  closeContainer: {
    margin: '1%'
  },
  whiteBgContainer: {
    backgroundColor: 'white',
    height: 'fit-content',
    margin: '2%',
    padding: '1%',
    borderRadius: '15px',
    width: '95%'
  }
};
