import ApolloClient from 'apollo-client'
import { toIdValue } from 'apollo-utilities'
import { AuthorizationMiddleware } from './authorization-middleware'
import { TimezoneMiddleware } from './timezone-middleware'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { createHttpLink } from 'apollo-link-http'
import { ApolloLink } from 'apollo-link'
import { AppVersionMiddleware } from './app-version-middleware'
import introspectionQueryResultData from './fragmentTypes'
import { withClientState } from 'apollo-link-state'
import uploadToCloudinary from '../client-schema/upload-to-cloudinary'
import { CachePersistor } from 'apollo-cache-persist'
import QueueLink from 'apollo-link-queue'
import { RetryLink } from 'apollo-link-retry'

export const getConnection = (url) => {
  let appState = 'active'
  const queueLink = new QueueLink()

  queueLink.open()

  const httpLink = createHttpLink({ uri: url })
  const middlewareLink = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        ...AuthorizationMiddleware.getHeader(),
        ...TimezoneMiddleware.getHeader(),
        ...AppVersionMiddleware.getHeader(),
      },
    })

    return forward(operation)
  })

  const cache = new InMemoryCache({
    addTypename: true,
    fragmentMatcher: new IntrospectionFragmentMatcher({
      introspectionQueryResultData,
    }),
    cacheRedirects: {
      Query: {
        user: (_, args) => toIdValue(cache.config.dataIdFromObject({ __typename: 'User', id: args.id })),
      },
    },
  })

  const persistor = new CachePersistor({
    cache,
    storage: localStorage,
  })

  const stateLink = withClientState({
    cache,
    resolvers: {
      Mutation: {
        uploadToCloudinary,
      },
      ChatMessage: {
        done: (root) => !!root.done,
      },
    },
  })

  const link = middlewareLink.concat(httpLink)

  const client = new ApolloClient({
    link: ApolloLink.from([stateLink, new RetryLink(), queueLink, link]),
    cache,
  })

  return { client, persistor }
}
