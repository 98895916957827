import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from '../../common/components/loading-spinner.rn';
import { CoursesPicker } from './courses-picker.container';
import { UsersPicker } from './users-picker.container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { Theme } from '../../common/providers/colors';

export class TargetPickerFormView extends Component {
  static propTypes = {
    _setClose: PropTypes.func,
    poll: PropTypes.any,
    type: PropTypes.string,
    targets: PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.any,
      publicationTargets: PropTypes.arrayOf(PropTypes.any)
    }).isRequired,
    updateTargets: PropTypes.func.isRequired,
    editEntityId: PropTypes.string,
    editEntity: PropTypes.object,
    currentLocation: PropTypes.any,
    navigate: PropTypes.func,
    onChange: PropTypes.func,
    hideButtons: PropTypes.bool,
    disableAllUsers: PropTypes.bool,
    groups: PropTypes.any
  };

  constructor(props) {
    super(props);

    this.state = {
      groups: ['all'],
      closedPickers: [],
      isChecked: true
    };
    this.ifExist = false;
    this.checkboxesStatus = [];
    this.pickerPerGroup = {
      users: {
        component: () => (
          <UsersPicker
            checkedItems={this.state.groups || []}
            onItemChange={(id, isChecked) => this.handleGroupsChange(id)}
          />
        ),
        counter: () =>
          (this.state.groups || []).filter(group => group.startsWith('user_'))
            .length,
        teardown: () => {
          this.setState({
            groups: this.state.groups.filter(
              group => !group.startsWith('user_')
            )
          });
        }
      },
      courses: {
        component: () => (
          <CoursesPicker
            checkedItems={this.state.groups || []}
            onItemChange={(id, isChecked) =>
              this.handleGroupsChange(id, isChecked)
            }
          />
        ),
        counter: () =>
          (this.state.groups || []).filter(group => group.startsWith('course_'))
            .length,
        teardown: () => {
          this.setState({
            groups: this.state.groups.filter(
              group => !group.startsWith('course_')
            )
          });
        }
      }
    };
  }

  componentDidMount() {
    if (this.props.disableAllUsers) {
      this.setState({
        groups: []
      });
    }
    if (this.props.groups) {
      this.setState(
        {
          groups: this.props.groups
        },
        () => {}
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.editEntity) {
      const targets = nextProps.editEntity.targets || [];

      this.setState({
        groups: targets
      });
    }

    if (nextProps.groups) {
      this.setState({
        groups: nextProps.groups
      });
    }
  }

  buildCheckBoxObject() {
    let checkboxesStatusTemp = [];
    let tempObj = {};
    console.log(
      'this.props.targets.publicationTargets',
      this.props.targets.publicationTargets
    );
    this.props.targets.publicationTargets.forEach((group, index) => {
      tempObj.groupId = group.id;
      tempObj.isChecked = group.id === 'all';
      tempObj.index = index;
      checkboxesStatusTemp.push(tempObj);
      tempObj = {};
    });
    console.log('checkboxesStatus', checkboxesStatusTemp);
    this.ifExist = true;
    this.checkboxesStatus = checkboxesStatusTemp;
    return this.checkboxesStatus;
  }

  handleGroupsChange(groupId) {
    if (!this.state.groups.includes(groupId)) {
      this.setState(
        {
          groups: [...this.state.groups, groupId]
        },
        () => {
          if (this.props.onChange) {
            this.props.onChange(this.state.groups);
          }
        }
      );
    } else {
      this.setState(
        {
          groups: this.state.groups.filter(id => id !== groupId)
        },
        () => {
          if (this.props.onChange) {
            this.props.onChange(this.state.groups);
          }
          if (this.pickerPerGroup[groupId]) {
            this.pickerPerGroup[groupId].teardown();
          }
        }
      );
    }
  }

  toggleGroupClose(groupId) {
    if (!this.state.closedPickers.includes(groupId)) {
      this.setState({
        closedPickers: [...this.state.closedPickers, groupId]
      });
    } else {
      this.setState({
        closedPickers: this.state.closedPickers.filter(id => id !== groupId)
      });
    }
    console.log('pickerPerGroup', this.pickerPerGroup);
  }

  skip() {
    this.props._setClose(true, 'closeModal');
  }

  save() {
    console.log('type', this.props.type);
    switch (this.props.type) {
      case 'poll':
        this.props
          .updateTargets(this.props.poll.createUpdatePoll.id, this.state.groups)
          .then(() => {
            // this.props._setClose(this.props.poll.createUpdatePoll, 'data');
            // this.props._setClose(true, 'openPlatformPicker');
          })
          .catch(console.log);
        break;
      case 'event':
        console.log('event got', this.props.poll);
        this.props
          .updateTargets(
            this.props.poll.createUpdateEvent.id,
            this.state.groups
          )
          .then(() => {
            // this.props._setClose(this.props.poll.createUpdateEvent, 'data');
            // this.props._setClose(true, 'openPlatformPicker');
          });
        break;
      case 'news':
        this.props
          .updateTargets(this.props.poll.createUpdateNews.id, this.state.groups)
          .then(() => {
            // this.props._setClose(this.props.poll.createUpdateNews, 'data');
            // this.props._setClose(true, 'openPlatformPicker');
          })
          .catch(console.log);
        break;
      default:
        break;
    }
  }

  handleSelectionChange(group) {
    let ind = this.checkboxesStatus.filter(grp => grp.groupId === group.id)[0]
      .index;
    if (
      !this.checkboxesStatus[ind].isChecked &&
      this.state.groups.indexOf(group.id) === -1
    ) {
      let data = this.state.groups;
      data.push(group.id);
      this.setState({ groups: data }, () => {
        if (this.props.onChange) {
          this.props.onChange(this.state.groups);
        }
        if (this.pickerPerGroup[group.id]) {
          this.pickerPerGroup[group.id].teardown();
        }
      });
    } else {
      let data = this.state.groups;
      let index = data.indexOf(group.id);
      data.splice(index, 1);
      let closed = this.state.closedPickers;
      index = closed.indexOf(group.id);
      closed.splice(index, 1);
      this.setState({ closedPickers: closed });
      this.setState({ groups: data }, () => {
        if (this.props.onChange) {
          this.props.onChange(this.state.groups);
        }
      });
    }
    this.checkboxesStatus[ind].isChecked = !this.checkboxesStatus[ind]
      .isChecked;
    let tem = this.checkboxesStatus[
      this.checkboxesStatus.filter(grp => grp.groupId === group.id)[0].index
    ].isChecked;
    this.setState({ isChecked: tem });
    this.props.setGroups(this.state.groups);
  }

  render() {
    if (this.props.targets.loading) {
      return <LoadingSpinner />;
    }

    if (!this.ifExist) this.buildCheckBoxObject();

    return (
      <Fragment>
        <h2
          style={{
            textAlign: 'center',
            fontSize: 16,
            color: Theme.TEXT_COLOR
          }}
        >
          Choose Recipients - Pick relevant groups:
        </h2>
        <div style={{ color: Theme.TEXT_COLOR }}>
          {this.props.targets.publicationTargets.map(group => {
            if (group.id === 'all' && this.props.disableAllUsers) {
              return null;
            }
            // const isChecked = this.pickerPerGroup[group.id] ? this.pickerPerGroup[group.id].counter() > 0 : this.state.groups && this.state.groups.includes(group.id);
            const total =
              group.total === null
                ? this.pickerPerGroup[group.id]
                  ? ` (${this.pickerPerGroup[group.id].counter()} items picked)`
                  : null
                : ` (Total ${group.total} Participants)`;
            let name =
              group.name === 'All Students'
                ? 'All Participants'
                : group.name === 'By Courses'
                ? 'By Groups'
                : group.name;
            let res = this.checkboxesStatus.filter(
              grp => grp.groupId === group.id
            )[0].isChecked;
            console.log('res', res);

            return (
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
                onClick={e => e.preventDefault()}
                key={group.id}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <StyledCheckbox
                    checked={
                      this.checkboxesStatus[
                        this.checkboxesStatus.filter(
                          grp => grp.groupId === group.id
                        )[0].index
                      ].isChecked
                    }
                    color='primary'
                    onChange={() => this.handleSelectionChange(group)}
                  />

                  {name + '' + total}

                  {this.pickerPerGroup[group.id] ? (
                    <FontAwesomeIcon
                      onClick={e => {
                        this.toggleGroupClose(group.id);
                        e.preventDefault();
                      }}
                      icon={
                        !this.state.closedPickers.includes(group.id)
                          ? faSortDown
                          : faSortUp
                      }
                      style={
                        !this.state.closedPickers.includes(group.id)
                          ? { marginBottom: '2%', marginLeft: '2%' }
                          : { marginTop: '2%', marginLeft: '2%' }
                      }
                    />
                  ) : null}
                </div>
                {this.state.closedPickers.includes(group.id) &&
                this.pickerPerGroup[group.id]
                  ? this.pickerPerGroup[group.id].component()
                  : null}
              </div>
            );
          })}
        </div>

        {/* Buttons Row */}
        {/* {!this.props.hideButtons && (
          <div style={styles.flexDisplay}>
            <button onClick={() => this.skip()} style={styles.continueBtn}>
              {LangData.targetPickerForm.skip}
            </button>

            <button
              onClick={() => this.save()}
              disabled={this.state.groups.length === 0}
              style={styles.continueBtn}
            >
              {LangData.targetPickerForm.continue}
            </button>
          </div>
        )} */}
      </Fragment>
    );
  }
}

const styles = {
  flexDisplay: {
    display: 'flex',
    alignItems: 'center'
  },
  continueBtn: {
    color: 'white',
    fontWeight: 'bold',
    fontFamily: 'inherit',
    fontSize: '15px',
    backgroundColor: Theme.SECONDARY_TEXT_COLOR,
    border: 'none',
    padding: '3%',
    borderRadius: '50px',
    width: '150px',
    margin: '5%'
  }
};
const StyledCheckbox = withStyles({
  root: {
    color: Theme.SECONDARY_TEXT_COLOR,
    '&$checked': {
      color: Theme.SECONDARY_TEXT_COLOR
    }
  },
  checked: {}
})(props => <Checkbox color='default' {...props} />);
