import { NotificationModalView } from './notification-modal.component';
import {
  setFcmTokenMutation,
  setUserFcmTokenUpdated
} from '../graphql/set-fcm-token.mutation';
import { getUserFcmToken } from '../graphql/get-user-fcm';
import { graphql } from 'react-apollo-temp';
import { connect } from 'react-redux';
import { compose } from 'redux';

// const withMutation = graphql(setFcmTokenMutation, {
//   props: ({ mutate }) => ({
//     setFcmToken: fcmToken => {
//       if (!fcmToken) {
//         return null;
//       }

//       return mutate({
//         variables: {
//           fcmToken,
//         },
//       }).catch(() => null);
//     },
//   }),
// });

const mapStateToProps = ({ auth }) => ({
  currentUser: auth.currentUser
});

const withState = connect(mapStateToProps, null);

const withGetUserFcm = graphql(getUserFcmToken, {
  props: ({ mutate }) => ({
    getUserFcmToken: userId => mutate({ variables: { userId } })
  })
});

const withMutation = graphql(setUserFcmTokenUpdated, {
  props: ({ mutate }) => ({
    setUserFcmTokenUpdated: fcmToken => {
      if (!fcmToken) {
        return null;
      }

      return mutate({
        variables: {
          userId: fcmToken.userId,
          endpoint: fcmToken.endpoint,
          expirationTime: fcmToken.expirationTime,
          p256dh: fcmToken.keys.p256dh,
          auth: fcmToken.keys.auth
        }
      }).catch(() => null);
    }
  })
});

export const NotificationModal = compose(
  withState,
  withMutation,
  withGetUserFcm
)(NotificationModalView);
