import React from 'react';

import { ChatList } from "../chats-list/chat-list.container";
import { SideModalWrapper } from "../../../main/side-modal-wrapper";

export const ChatsModal = props => {
  return (
    <SideModalWrapper>
      <ChatList inModal {...props} />
    </SideModalWrapper>
  )
}
