import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LangData } from '../../../assets/locales/localization';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { Theme } from '../../../common/providers/colors';

const width = window.innerWidth;

export class SendMessageInputView extends Component {
  static propTypes = {
    onSendMessage: PropTypes.func.isRequired,
    createImageMessage: PropTypes.func.isRequired,
    enabled: PropTypes.bool,
    name: PropTypes.string,
    chatId: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      content: '',
      hasText: false,
      inputHeight: 40,
      pickerMenuOpen: false
    };
  }

  updateText(text) {
    this.setState({ content: text }, () => {
      if (this.state.hasText !== (text !== '')) {
        this.setState({ hasText: text });
      }
    });
  }

  updateInputSize(e) {
    const newHeight = Math.min(e.nativeEvent.contentSize.height + 20, 83);

    this.setState({
      inputHeight: newHeight
    });
  }

  async sendMessage(messageType, contentOrFile) {
    if (messageType === 'TEXT') {
      this.updateText('');
      this.props.onSendMessage(messageType, contentOrFile.trim());
    } else if (messageType === 'IMAGE') {
      await this.props.createImageMessage(
        this.props.chatId,
        contentOrFile,
        'chat_image'
      );
    }
  }

  onImgUpload = e => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      this.sendMessage('IMAGE', file);
    }
  };

  onEnterClick = (event) => {
    if(!this.state.hasText){
      return
    }
    if(event.key === 'Enter'){
      this.sendMessage('TEXT', this.state.content)
    }
  }

  render() {
    if (!this.props.enabled) {
      return (
        <div style={styles.container}>
          <p style={styles.disabledText}>
            {LangData.sendMessageInput.chat1 +
              this.props.name +
              LangData.sendMessageInput.chat2}
            .
          </p>
        </div>
      );
    }

    return (
      <div
        style={{ position: 'fixed', bottom: '0', width: '100%', height: 60 }}
      >
        <div style={styles.container}>
          <div
            style={{
              width: '10%',
              alignItems: 'center',
              right: 15,
              left: 15,
              top: 15,
              bottom: 15,
              textAlign: 'center'
            }}
          >
            <input
              type='file'
              accept='image/*'
              onChange={e => this.onImgUpload(e)}
              style={styles.hideInputStyle}
              name='upload_img'
              id='upload_img'
            />
            <label htmlFor='upload_img'>
              <FontAwesomeIcon icon={faCamera} />
            </label>
          </div>
          <TextInput
            type='text'
            placeholder={LangData.sendMessageInput.placeholder}
            // style={styles.searchInput}
            // style={[styles.searchInput, { height: this.state.inputHeight }]}
            // onContentSizeChange={e => this.updateInputSize(e)}
            onChange={e => this.updateText(e.target.value)}
            value={this.state.content}
            onKeyPress={this.onEnterClick}
          />
          <div
            disabled={!this.state.hasText}
            style={{
              width: '10%',
              alignItems: 'center',
              right: 15,
              left: 15,
              top: 15,
              bottom: 15
            }}
            onClick={() => this.sendMessage('TEXT', this.state.content)}
          >
            <FontAwesomeIcon icon={faPaperPlane} />
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  uploadProgressContainer: {
    backgroundColor: Theme.BACKGROUND_COLOR,
    flexDirection: 'row',
    display: 'flex'
  },
  container: {
    width: width < 500 ? '100%' : '400px',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    height: 60
  },
  searchInput: {
    color: Theme.TEXT_COLOR,
    fontSize: 15,
    paddingVertical: 0,
    paddingHorizontal: 0,
    width: '80%',
    fontFamily: 'inherit'
  },
  sendIcon: {
    height: 20,
    width: 20
  },
  disabledText: {
    textAlign: 'center',
    color: 'black',
    fontSize: 13,
    fontStyle: 'italic'
  },
  hideInputStyle: {
    width: '0.1px',
    height: '0.1px',
    opacity: '0',
    overflow: 'hidden',
    position: 'absolute',
    zIndex: '-1'
  }
};

const TextInput = styled.input`
  color: ${Theme.TEXT_COLOR};
  font-size: 15px;
  width: 80%;
  font-family: inherit;
  border: none;

  &:focus {
    outline: none;
    border: none;
  }
`;
