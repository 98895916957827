import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import FullScreenImageModal from '../../../common/components/full-screen-image-modal.rn';
import { MessagesListItem } from './messages-list-item.rn';
import LoadingSpinner from '../../../common/components/loading-spinner.rn';
import debounce from 'lodash.debounce';
import { LangData } from '../../../assets/locales/localization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';
import ReactResizeDetector from 'react-resize-detector';
import { Theme } from '../../../common/providers/colors';
import moment from 'moment';

const LOAD_OLDER_SCROLL_THRESHOLD = 0;

const width = window.innerWidth;

export class MessagesList extends Component {
  static propTypes = {
    messages: PropTypes.array.isRequired,
    cursor: PropTypes.any,
    hasMore: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    isLoadingMore: PropTypes.bool,
    currentUser: PropTypes.any.isRequired,
    mostRecentDataEventType: PropTypes.any,
    onLoadMore: PropTypes.func,
    onUserPicked: PropTypes.func,
    navigateToProfile: PropTypes.func
  };

  constructor(props) {
    super(props);

    this._list = null;

    this.state = {
      isScrollEnd: true,
      imageViewerUrl: null,
      dirtyCheck: Date.now(),
      data: []
    };

    this._lastScrollEvent = null;

    this.infiniteScrollLoadMore = debounce(
      this.infiniteScrollLoadMore.bind(this),
      500
    );
  }

  componentDidMount() {
    // setTimeout(() => this.scrollToListEnd(true, false), 4000);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.dirtyCheck !== nextState.dirtyCheck) {
      setTimeout(() => this.scrollToListEnd(true, false), 50);
    }
    return (
      this.props.isLoadingMore !== nextProps.isLoadingMore ||
      this.props.loading !== nextProps.loading ||
      this.state.data.length !== nextState.data.length ||
      this.state.dirtyCheck !== nextState.dirtyCheck ||
      this.state.isScrollEnd !== nextState.isScrollEnd ||
      this.state.imageViewerUrl !== nextState.imageViewerUrl
    );
  }

  getPrevious(messages, index) {
    if (messages[index - 1]) {
      return messages[index - 1];
    }

    return null;
  }

  getNext(messages, index) {
    if (messages[index + 1]) {
      return messages[index + 1];
    }

    return null;
  }

  componentWillMount() {
    this.scrollToListEnd(true, false);
    if (this.props.messages) {
      this.handleMessagesChange(this.props, true);
    }
  }

  handleMessagesChange(props, setDirty) {
    if (
      props.messages &&
      props.messages.length === 30 &&
      this.state.data &&
      this.state.data.length > 30 &&
      props.messages[29].id === this.state.data[this.state.data.length - 1].id
    ) {
      return;
    }
   
    const currentCursor = this.props.cursor;
    const newData = this.getTransformedMessages(props);
    this.setState(
      {
        data: newData,
        ...(setDirty ? { dirtyCheck: Date.now() } : {})
      },
      async () => {
        if (!currentCursor || currentCursor === props.cursor) {
          setTimeout(() => this.scrollToListEnd(true, false), 50);
        }
      }
    );
  }

  componentWillReceiveProps(nextProps) {
    let lastItemChanged = false;
    if (this.props.loading && !nextProps.loading) {
      setTimeout(() => this.scrollToListEnd(true, false), 50);
    }
    try {
      const prevLastId = this.props.messages[this.props.messages.length - 1].id;
      const nowLastId = nextProps.messages[nextProps.messages.length - 1].id;
   
      lastItemChanged = prevLastId !== nowLastId;
    } catch (e) {
      // nothing to do here
    }

    if (
      (this.props.messages || []).length !==
        (nextProps.messages || []).length ||
      this.props.messages.networkStatus !== nextProps.messages.networkStatus ||
      lastItemChanged
    ) {
      this.handleMessagesChange(nextProps, lastItemChanged);
    }
  }

  getTransformedMessages(props) {
    if (!props.messages) {
      return [];
    }

    const messages = [];

    if (props.hasMore) {
      messages.push({
        id: 'loadMoreButton',
        loadMoreButton: true
      });
    }

    props.messages.forEach((message, index) => {
      if (message.serverMessage) {
        messages.push(message);

        return;
      }

      const prev = this.getPrevious(props.messages, index);

      let hideProfilePic = false;

      if (
        prev &&
        prev.author &&
        message &&
        message.author &&
        prev.author.id === message.author.id
      ) {
        hideProfilePic = true;
      }


      if (
        !prev ||
        (prev &&
          prev.createdAt &&
          moment(prev.createdAt).format('MM/DD/YYYY') !== moment(message.createdAt).format('MM/DD/YYYY'))
      ) {
        messages.push({
          dateIndicator: true,
          date: moment(message.createdAt).format('lll'),
          id: `${message.createdAt}_${index}`
        });
      }

      messages.push({
        ...message,
        hideProfilePic,
        originalIndex: index,
        mine:
          message &&
          message.author &&
          message.author.id === props.currentUser.id
      });
    });

    return messages;
  }

  loadOlder() {
    if (!this.props.isLoadingMore) {
      this.props.onLoadMore();
    }
  }

  openImageViewer(imageUrl) {
    this.setState({
      imageViewerUrl: imageUrl
    });
  }
  renderItem(item, index) {
    if (item.serverMessage) {
      return (
        <p key={index} style={styles.serverMessageText}>
          {item.content}
        </p>
      );
    }

    if (item.loadMoreButton) {
      if (this.props.isLoadingMore) {
        return (
          <p key={index} style={styles.loadMoreText}>
            {LangData.messagesList.loading}
          </p>
        );
      }

      return (
        <div
          key={index}
          onClick={() => this.loadOlder()}
          style={{
            right: 15,
            left: 15,
            top: 15,
            bottom: 15,
            textAlign: 'center'
          }}
        >
          <span style={styles.loadMoreText}>{LangData.messagesList.see}</span>
        </div>
      );
    }

    if (item.dateIndicator) {
      return (
        <div style={styles.dateContainer} key={index}>
          <span style={styles.dateText}>{item.date.toUpperCase()}</span>
          <div style={styles.underline} />
        </div>
      );
    }

    return (
      <MessagesListItem
        duringUpload={item.id.startsWith('temp_') && !item.done}
        navigateToProfile={this.props.navigateToProfile}
        onOpenImageViewer={url => this.openImageViewer(url)}
        onUserPicked={() => this.props.onUserPicked(item.author)}
        imageThumb={item.messageType === 'IMAGE' ? item.imageThumb : null}
        mine={item.mine}
        content={item.content}
        messageType={item.messageType}
        name={item.author.name}
        profilePictureUrl={item.author.profilePictureUrl}
        createdAt={moment(item.createdAt).format('lll')}
        hideProfilePic={item.hideProfilePic}
        key={item.id}
        openImgModal={this.props.openImgModal}
        setAlert={this.props.setAlert}
      />
    );
  }

  handleScrollAndLoadMore(yOffset) {
    if (yOffset <= LOAD_OLDER_SCROLL_THRESHOLD && !this.props.isLoadingMore) {
      this.infiniteScrollLoadMore();
    }
  }

  infiniteScrollLoadMore() {
    if (this.props.isLoadingMore === false) {
      this.props.onLoadMore();
    }
  }

  scrollToListEnd(forced = false, animated = false) {
    if (document.getElementById('messagesContainer')) {
      if (!this.state.isScrollEnd || forced) {
        var elmnt = document.getElementById('messagesContainer');
        elmnt.scrollTop = elmnt.scrollHeight + 100;
        this.setState({
          isScrollEnd: true
        });
      }
    }
  }

  handleScroll = e => {
    let element = e.target;
    this._lastScrollEvent = e.target;

    this.handleScrollAndLoadMore(
      e.target.scrollHeight - (element.scrollHeight - element.scrollTop)
    );

    const isEnd =
      element.scrollHeight - element.scrollTop === element.clientHeight;
    if (!this.state.isScrollEnd && isEnd) {
      this.setState({
        isScrollEnd: true
      });
    } else if (this.state.isScrollEnd && !isEnd) {
      this.setState({
        isScrollEnd: false
      });
    }
  };

  render() {
    return (
      <Fragment>
        {width < 500 ? <div style={{ height: 55 }}/> : null}
        <div
          onScroll={this.handleScroll}
          ref={this.myRef}
          id='messagesContainer'
          style={{
            backgroundColor: Theme.BACKGROUND_COLOR,
            overflowY: 'auto',
            height: width < 500 ? window.innerHeight - 120 : '490px'
          }}
        >
          <FullScreenImageModal
            image={this.state.imageViewerUrl}
            onCloseImgModal={() => this.setState({ imageViewerUrl: null })}
            isOpen={this.state.imageViewerUrl !== null}
          />

          {this.props.loading && <LoadingSpinner />}

          {!this.props.loading && (
            <Fragment>
              {this.state.data.map((item, index) =>
                this.renderItem(item, index)
              )}
            </Fragment>
          )}

          {this.state.isScrollEnd !== true && (
            <div
              onClick={() => this.scrollToListEnd(true, true)}
              style={styles.scrollToBottom}
            >
              <FontAwesomeIcon icon={faAngleDoubleDown} />
            </div>
          )}

          <ReactResizeDetector
            handleWidth
            handleHeight
            onResize={() =>
              setTimeout(() => this.scrollToListEnd(true, false), 100)
            }
          />
        </div>

        <div style={{ height: 60 }}></div>
      </Fragment>
    );
  }
}

const styles = {
  list: {
    flexGrow: 1,
    paddingTop: 10
  },
  dateContainer: {
    alignItems: 'center',
    width: '85px',
    margin: '0 auto',
    textAlign: 'center',
    marginBottom: '5px'
  },
  dateText: {
    color: 'black',
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    paddingHorizontal: 6
  },
  underline: {
    marginTop: 2,
    height: 3,
    alignSelf: 'stretch',
    backgroundColor: 'black'
  },
  loadMoreText: {
    color: 'black',
    fontSize: 13,
    fontWeight: 'bold',
    borderRadius: 5,
    padding: 2,
    textAlign: 'center',
    marginBottom: 5
  },
  serverMessageText: {
    color: 'black',
    fontSize: 13,
    textAlign: 'center',
    fontStyle: 'italic',
    marginVertical: 3
  },
  scrollToBottom: {
    position: 'absolute',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    backgroundColor: 'white',
    bottom: '65px',
    right: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0.6',
    marginBottom: '2%',
    fontSize: '12px',
    color: Theme.TEXT_COLOR
  }
};
