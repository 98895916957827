import { combineReducers } from 'redux';
import { EProfileActions } from './profile.actions';
import { EAuthActions } from '../auth/auth.actions';
import { EMainActions } from '../main/main.actions';

const privacySettings = (state = {}, action) => {
  switch (action.type) {
    case 'js-accounts/SET_USER':
      if (action.payload.user)
        return action.payload.user.privacy;
      break;
    case EProfileActions.UPDATE_PRIVACY:
      return action.tempState;
    case EProfileActions.UPDATE_PRIVACY_SUCCESS:
      return action.privacy;
    case EProfileActions.UPDATE_PRIVACY_FAIL:
      return action.previousPrivacy;
    default:
      return state;
  }
};

const profilePictureLoading = (state = false, action) => {
  switch (action.type) {
    case EAuthActions.USER_LOADED:
      return false;
    case EAuthActions.RELOAD_USER:
    case EProfileActions.CHANGE_PROFILE_PICTURE:
      return true;
    default: {
      return state;
    }
  }
};

const rejectionReason = (state = null, action) => {
  switch (action.type) {
    case EProfileActions.PROFILE_PICTURE_REJECTED:
      return action.reason;
    case EProfileActions.CHANGE_PROFILE_PICTURE:
      return null;
    default:
      return state;
  }
};

const isProfileModalOpen = (state = false, action) => {
  switch (action.type) {
    case EMainActions.CLOSE_ALL_MODALS:
    case 'session/logging_out':
      return false;
    case EProfileActions.TOGGLE_PROFILE_MODAL:
      return action.isOpen;
    case EMainActions.NAVIGATE_CHILD: {
      if (action.routeName === 'Profile') {
        return true;
      }
      return state;
    }
    default:
      return state;
  }
};

const userProfileModal = (state = { isOpen: false, userId: null }, action) => {
  switch (action.type) {
    case EMainActions.CLOSE_ALL_MODALS:
      return {
        isOpen: false,
        userId: null
      };
    case EProfileActions.TOGGLE_USER_PROFILE_MODAL:
      return {
        isOpen: action.isOpen,
        userId: action.userId
      };
    default:
      return state;
  }
};

export const profile = combineReducers({
  privacySettings,
  profilePictureLoading,
  rejectionReason,
  isProfileModalOpen,
  userProfileModal
});
