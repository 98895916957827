import React, { useState, useEffect, useMemo } from 'react';
import { LangData } from '../../assets/locales/localization';
import { Theme } from '../../common/providers/colors';

const width= window.innerWidth
 
const RequestsMenuScrollableTabView = props => {
  const [index, setIndex] = useState(0);
  useEffect(() => {
    setIndex(props.index);
  }, [props]);

  const sliderStyles = useMemo(() => {
    return index === 0 ? styles.sliderLeft
    : index === 1
      ? styles.sliderMiddle
      : styles.sliderRight
  }, [index])

  return (
    <div style={styles.toggleContainer}>
      <label style={styles.switch}>
        <span
          style={styles.leftToggle}
          id='leftToggle'
          onClick={() => props.changeIndex(0)}
        >
          {LangData.friendsList.pending + ' (' + props.pendingCount + ')'}
        </span>
        <span
          style={styles.middleToggle}
          id='middleToggle'
          onClick={() => props.changeIndex(1)}
        >
          {LangData.friendsList.ignored + ' (' + props.ignoredCount + ')'}
        </span>
        <span
          style={styles.rightToggle}
          id='rightToggle'
          onClick={() => props.changeIndex(2)}
        >
          {LangData.friendsList.sent + ' (' + props.sentCount + ')'}
        </span>
        <span
          style={{
            ...sliderStyles, ...styles.slider
          }}
          id='checked'
        />
      </label>
    </div>
  );
};

export default RequestsMenuScrollableTabView;

const styles = {
  toggleContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginTop: '4%'
  },
  leftToggle: {
    position: 'relative',
    zIndex: 1,
    color: Theme.TEXT_COLOR,
    fontWeight: 'bold',
    fontSize: width < 500 ? window.innerWidth / 30 + 'px' : '1rem',
  },
  middleToggle: {
    position: 'relative',
    zIndex: 1,
    color: Theme.TEXT_COLOR,
    fontWeight: 'bold',
    fontSize: width < 500 ? window.innerWidth / 30 + 'px' : '1rem',
  },
  rightToggle: {
    position: 'relative',
    zIndex: 1,
    color: Theme.TEXT_COLOR,
    fontWeight: 'bold',
    fontSize: width < 500 ? window.innerWidth / 30 + 'px' : '1rem',
  },
  optionsRow: {
    display: 'flex',
    padding: '5%',
    justifyContent: 'space-between',
    color: Theme.TEXT_COLOR,
    fontWeight: 'bold'
  },
  switch: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: width < 500 ? window.innerWidth / 1.10299 + 'px' : '100%',
    height: width < 500 ? window.innerWidth / 12 + 'px' : '6vh',
    borderRadius: window.innerWidth / 18 + 'px',
    backgroundColor: Theme.BACKGROUND_COLOR,
    border: `1px solid ${Theme.SECONDARY_BACKGROUND_COLOR}`
  },
  checkboxInput: {
    opacity: '0',
    width: '0',
    height: '0'
  },
  sliderRight: {
    left: width < 500 ? window.innerWidth / 1.82741 + 'px' : '67%',
  },
  sliderLeft: {
    left: width < 500 ? window.innerWidth / 1.82741 + 'px' : '0',
  },
  sliderMiddle: {
    left: width < 500 ? window.innerWidth / 1.82741 + 'px' : '34%',
  },
  slider: {
    position: 'absolute',
    content: '""',
    height: width < 500 ? window.innerWidth / 13.9158 + 'px' : '6vh',
    top: width < 500 ? window.innerWidth / 180 + 'px' : 0,
    width: width < 500 ? window.innerWidth / 3.6 + 'px' : '33.3%',
    backgroundColor: Theme.SECONDARY_BACKGROUND_COLOR,
    transition: 'all 0.4s ease 0s',
    borderRadius: window.innerWidth / 7.5 + 'px',
  }
};
