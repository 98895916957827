import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Theme } from '../../common/providers/colors';

const width = window.innerWidth;
const height = window.innerHeight;

export class FacebookAuthModal extends Component {
  static propTypes = {
    accessToken: PropTypes.any,
    appId: PropTypes.string,
    appState: PropTypes.string,
    redirectUrl: PropTypes.string,
    startAuth: PropTypes.func.isRequired,
    authDone: PropTypes.func.isRequired,
    onInit: PropTypes.func
  };

  constructor() {
    super();
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.onNavigationStateChange = this.onNavigationStateChange.bind(this);

    this.state = {
      open: false
    };

    this._closed = false;
  }

  async componentWillMount() {
    await this.clearCookies();

    if (this.props.onInit) {
      this.props.onInit(this);
    }

    this._closed = false;
  }

  onNavigationStateChange(state) {
    const url = state.url.toLowerCase();

    if (url.startsWith(this.props.returnUrl.toLowerCase())) {
      setTimeout(() => {
        if (!this._closed) {
          this._closed = true;
          this.close();
        }
      }, 1000);
    }
  }

  async clearCookies() {
    try {
      // await Cookie.clear('https://www.facebook.com/');
      // await Cookie.clear('https://facebook.com/');
      // await Cookie.clear('http://facebook.com/');
      // await Cookie.clear('http://api.facebook.com/');
      // await Cookie.clear('https://api.facebook.com');
    } catch (e) {
      // nothing to do
    }
  }

  async open() {
    this._closed = false;
    await this.clearCookies();

    setTimeout(() => {
      this.setState({
        open: true
      });
    }, 500);

    return new Promise(resolve => {
      this.closeResolve = resolve;
    });
  }

  close(skipUserLoad = false) {
    if (!skipUserLoad) {
      this.props.authDone();
    }

    this.setState(
      {
        open: false
      },
      () => {
        if (this.closeResolve) {
          this.closeResolve();
          this.closeResolve = null;
        }
      }
    );
  }

  render() {
    if (
      !this.state.open ||
      !this.props.accessToken ||
      !this.props.returnUrl ||
      !this.props.appId
    ) {
      return null;
    }

    const uri = `https://www.facebook.com/v2.11/dialog/oauth?client_id=${this.props.appId}&redirect_uri=${this.props.returnUrl}&state=${this.props.stateParam}`;

    return (
      <Fragment>
        {this.state.open ? (
          <div
            style={styles.container}
            // onClose={() => this.close()}
          >
            <div style={styles.exitButton} onClick={() => this.close(true)}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
            {this.state.open && (
              <div style={styles.webViewContainer}>
                <iframe
                  title="facebook auth"
                  src={uri}
                  style={{
                    width: width - 20,
                    height: window.innerHeight - 250
                  }}
                  is='x-frame-bypass'
                />
              </div>
            )}
          </div>
        ) : null}
      </Fragment>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: window.innerHeight,
    position: 'fixed',
    top: '0',
    backgroundColor: Theme.BACKGROUND_COLOR,
    zIndex: '9'
  },
  exitButton: {
    position: 'absolute',
    top: 50,
    left: 10,
    color: 'white'
  },
  webViewContainer: {
    borderRadius: 10,
    marginHorizontal: 10,
    height: height * 0.8,
    overflow: 'hidden'
  }
};
