import React, { useState, useEffect } from 'react'
import { FriendsListTopBar } from './friends-list-top-bar.container'
import { FriendsGrid } from './friends-grid.container'
import SwipeableViews from 'react-swipeable-views'
import { EFriendsListActiveState, EFriendsListFilterType } from '../friends.actions'
import RequestsMenuScrollableTabView from './requests-tab-menu.rn'
import { LangData } from '../../assets/locales/localization'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { Redirect } from 'react-router'
import { Theme } from '../../common/providers/colors'
import { getClient } from '../../apollo'
import { totalFriendsQuery } from '../graphql/total-connections.query'
import { apolloClient } from '../../apollo-subs'
import { friendAddedSubscription } from '../graphql/friend-added.subscription'
import { friendRemovedSubscription } from '../graphql/friend-removed.subscription'
import { FriendsProfileModal } from './friends-profile-modal'
import { PortalWrapper } from '../../ProtalWrapper'

const width = window.innerWidth

const FriendsListView = (props) => {
  const [currIndex, setCurrIndex] = useState(0)
  const [totalFriends, setTotalFriends] = useState(0)
  const styles = getStyles(props.inPopup)

  const handleChangeIndex = (index) => {
    setCurrIndex(index)
  }

  const getCount = async () => {
    let client = await getClient()
    let res = await client.query({
      query: totalFriendsQuery,
      variables: {
        userId: props.currentUser ? props.currentUser.id : null,
      },
    })
    setTotalFriends(res.data.totalFriends)
  }

  const subscribeToNewUsers = () => {
    apolloClient
      .subscribe({
        query: friendAddedSubscription,
        variables: {},
      })
      .subscribe({
        next: (data) => {
          let newConnection = data.friendAdded
          let currentId = localStorage.getItem('userId')
          if (
            newConnection.userAdded.toString() === currentId.toString() ||
            newConnection.addedBy.toString() === currentId.toString()
          ) {
            setTotalFriends((prevState) => prevState + 1)
          }
        },
      })
  }

  const subscribeToRemovedUsers = () => {
    apolloClient
      .subscribe({
        query: friendRemovedSubscription,
        variables: {},
      })
      .subscribe({
        next: (data) => {
          let removedConnection = data.friendRemoved
          let currentId = localStorage.getItem('userId')
          if (
            removedConnection.userAdded.toString() === currentId.toString() ||
            removedConnection.addedBy.toString() === currentId.toString()
          ) {
            setTotalFriends((prevState) => prevState - 1)
          }
        },
      })
  }

  useEffect(() => {
    getCount()
    subscribeToNewUsers()
    subscribeToRemovedUsers()
  }, [])

  if (!props.currentUser) {
    return <Redirect to='/login' />
  }

  return (
    <>
      <div style={{ ...styles.container }} id="friends-container">
        <span style={styles.friendsContainer}>
          <span style={styles.friendsListOnDesktop}>
            <div style={props.isFriendsList ? styles.centeredRow : styles.optionsRow} id='optionsRow'>
              {!props.isFriendsList && (
                <div
                  onClick={() => {
                    if (props.isFriendsList) {
                      props.toggleComponent(props.prevComponent)
                      props.history.push('/')
                    } else {
                      props.toggleRequests(false)
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </div>
              )}
              {/* <div>
                <span>My Friends {`(${totalFriends})`}</span>
              </div> */}
            </div>

            <FriendsListTopBar pendingIndicator={props.pendingCount > 0} />
            {props.isFriendsList || props.isGlobalSearch ? (
              <FriendsGrid
                inPopup={props.inPopup}
                currentUser={props.currentUser}
                activeState={
                  props.isGlobalSearch ? EFriendsListActiveState.GLOBAL_SEARCH : EFriendsListActiveState.FRIENDS_LIST
                }
                history={props.history}
              />
            ) : (
              <div style={{ width: '100%', marginTop: '5%' }}>
                <RequestsMenuScrollableTabView index={currIndex} changeIndex={handleChangeIndex} {...props} />
                <SwipeableViews index={currIndex} onChangeIndex={handleChangeIndex}>
                  <FriendsGrid
                    inPopup={props.inPopup}
                    currentUser={props.currentUser}
                    tabLabel={LangData.friendsList.pending + ' ' + props.pendingCount}
                    activeState={EFriendsListFilterType.PENDING}
                    history={props.history}
                  />
                  <FriendsGrid
                    inPopup={props.inPopup}
                    currentUser={props.currentUser}
                    tabLabel={LangData.friendsList.ignored + ' ' + props.ignoredCount}
                    activeState={EFriendsListFilterType.IGNORED}
                    history={props.history}
                  />
                  <FriendsGrid
                    inPopup={props.inPopup}
                    currentUser={props.currentUser}
                    tabLabel={LangData.friendsList.sent + ' ' + props.sentCount}
                    activeState={EFriendsListFilterType.SENT}
                    history={props.history}
                  />
                </SwipeableViews>
              </div>
            )}
          </span>
        </span>
      </div>
      <PortalWrapper>
        <FriendsProfileModal />
      </PortalWrapper>
    </>
  )
}
export default FriendsListView

const getStyles = (inPopup) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: Theme.BACKGROUND_COLOR,
    marginTop: width < 500 ? '15%' : inPopup ? 0 : '120px',
  },
  optionsRow: {
    display: 'flex',
    padding: width < 500 ? '5%' : 0,
    justifyContent: 'space-between',
    color: Theme.TEXT_COLOR,
    fontWeight: 'bold',
    width: '90%',
  },
  centeredRow: {
    display: 'flex',
    padding: width < 500 ? '5%' : 0,
    justifyContent: 'center',
    color: Theme.TEXT_COLOR,
    fontWeight: 'bold',
    width: '90%',
  },
  topNav: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: window.innerWidth,
    padding: '5% 0',
    position: 'fixed',
    top: 0,
    zIndex: 99999,
    backgroundColor: Theme.BACKGROUND_COLOR,
  },
  friendsListOnDesktop: {
    width: '100%',
    marginLeft: width < 500 ? 0 : '15%',
  },
  charInFriendsDesktop: {
    width: '40%',
  },
  friendsContainer: {
    display: 'flex',
    flexDirection: width < 500 ? 'column' : 'row',
    width: '90%',
    height: inPopup ? '334px' : 'calc(100vh - 120px)',
    justifyContent: 'space-between',
  },
  seprator: {
    width: '1px',
    backgroundColor: '#726f7e',
  },
})
