import React, { useState, useEffect, Fragment } from 'react';
import { ProfileGalleryView } from './profile-gallery.rn';
import LoadingSpinner from '../../../common/components/loading-spinner.rn';
import { LangData } from '../../../assets/locales/localization';
import {
  getDataFromFile,
  uploadToCloudinary
} from '../../../cloudinary/utils/upload';
import styled from 'styled-components';
import { placeholder } from '@babel/types';
import { Theme } from '../../../common/providers/colors';

const UploadPhotoMenuView = props => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [label, setLabel] = useState('');
  const [file, setFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  const onImgUpload = e => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      setPreviewImage(URL.createObjectURL(e.target.files[0]));
      setFile(file);
      document.body.style.position = 'fixed';
    }
  };

  const startUpload = async file => {
    let currLabel = label;

    setLabel('');
    setFile(null);
    document.body.style.position = '';
    setProgress(0);
    setLoading(true);

    const fileId = await uploadToCloudinary(
      file,
      progress => {
        setProgress(progress);
      },
      ['profile_photos']
    );
    if (!fileId || fileId.error) {
      console.log('Cloudinary upload failed! fileId:', fileId);
      alert(LangData.uploadPhotoMenu.wrong);

      return;
    }

    props
      .addPhoto(props.currentUser.id, fileId.public_id, currLabel)
      .catch(() => {
        alert(LangData.uploadPhotoMenu.wrong);
      })
      .then(() => {
        setProgress(0);
        setLoading(false);
      });
  };

  return (
    <Fragment>
      {file ? (
        <Fragment>
          <div style={styles.mainMenuContainer}>
            <UploadPicBtn>
              {LangData.profileScreen.upload.toUpperCase()}
            </UploadPicBtn>
          </div>
          <div style={styles.backgroundAddLabelForm}>
            <div style={styles.uploadForm}>
              <img style={styles.previewImage} src={previewImage} />
              <LabelInput
                value={label}
                type='text'
                onChange={e => setLabel(e.target.value)}
                placeholder={LangData.uploadPhotoMenu.write}
              />
              <div style={styles.optionRow}>
                <button
                  style={styles.cancelBtn}
                  onClick={() => {
                    setLabel('');
                    setFile(null);
                  }}
                >
                  {LangData.uploadPhotoMenu.cancel}
                </button>
                <button
                  onClick={() => startUpload(file)}
                  style={styles.uploadBtnForm}
                >
                  {LangData.uploadPhotoMenu.upload}
                </button>
              </div>
            </div>
          </div>
        </Fragment>
      ) : null}

      {loading && !file ? (
        <Fragment>
          <p style={styles.loadingText}>
            {loading
              ? LangData.uploadPhotoMenu.uploadPhoto
              : LangData.uploadPhotoMenu.moment}
          </p>
          <LoadingSpinner />
        </Fragment>
      ) : null}

      {!loading && !file ? (
        <div style={styles.mainMenuContainer}>
          <input
            type='file'
            accept='image/*'
            onChange={e => onImgUpload(e)}
            style={styles.inputStyle}
            name='upload_img'
            id='upload_img'
          />
          <label htmlFor='upload_img'>
            <UploadPicBtn>
              {LangData.profileScreen.upload.toUpperCase()}
            </UploadPicBtn>
          </label>
        </div>
      ) : null}
    </Fragment>
  );
};

export default UploadPhotoMenuView;

const styles = {
  floatBottomMenu: {
    width: '100%'
  },
  uploadLoading: {
    width: '100%'
  },
  loadingText: {
    textAlign: 'center',

    fontSize: 14,
    color: 'white'
  },

  previewImage: {
    borderRadius: 25,
    width: '100%',
    height: window.innerHeight * 0.3
  },
  inputStyle: {
    width: '0.1px',
    height: '0.1px',
    opacity: '0',
    overflow: 'hidden',
    position: 'absolute',
    zIndex: '-1'
  },
  backgroundAddLabelForm: {
    height: window.innerHeight,
    width: window.innerWidth,
    position: 'fixed',
    opacity: '0.97',
    zIndex: '9999999',
    backgroundColor: Theme.BACKGROUND_COLOR,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0
  },
  uploadForm: {
    backgroundColor: 'white',
    fontFamily: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '25px',
    color: Theme.TEXT_COLOR,
    width: '85%',
    padding: '3%'
  },
  optionRow: {
    display: 'flex',
    width: '100%'
  },
  uploadBtnForm: {
    backgroundColor: Theme.SECONDARY_BACKGROUND_COLOR,
    border: 'none',
    color: Theme.SECONDARY_TEXT_COLOR,
    width: '69%',
    marginLeft: '1%',
    borderRadius: '20px',
    padding: '2%',
    fontWeight: 'bold',
    fontFamily: 'inherit',
    fontSize: '15px'
  },
  cancelBtn: {
    backgroundColor: Theme.SECONDARY_BACKGROUND_COLOR,
    border: 'none',
    color: Theme.SECONDARY_TEXT_COLOR,
    width: '30%',
    borderRadius: '20px',
    padding: '2%',
    fontWeight: 'bold',
    fontFamily: 'inherit',
    fontSize: '15px'
  }
};

const UploadPicBtn = styled.div`
  color: ${Theme.SECONDARY_TEXT_COLOR};
  background-color: ${Theme.SECONDARY_BACKGROUND_COLOR};
  padding: ${window.innerWidth < 500 ? window.innerWidth / 36 + "px" : '14px'};
  width: ${window.innerWidth / 2.769}px;
  border-radius: ${window.innerWidth / 18.947}px;
  font-size: 15px;
  text-align: center;
  font-weight: bold;
  width: 100%;
`;

const LabelInput = styled.input`
  width: 100%;
  height: 70px;
  fontsize: 15px;
  color: ${Theme.SECONDARY_TEXT_COLOR};
  border: none;
  margin-top: 2px;
  font-weight: bold;
  font-family: inherit;
  &:focus {
    outline: none;
    border: none;
  }
`;
