import React, { useEffect, useState } from 'react'
import EmptyNotice from '../../common/components/empty-notice.rn'
import { FriendsSuggestions } from './friends-suggestions.container'
import { EFriendsListActiveState, EFriendsListFilterType } from '../friends.actions'
import { LangData } from '../../assets/locales/localization'
import { Theme } from '../../common/providers/colors'
import styled from 'styled-components'
import { friendAddedSubscription } from '../graphql/friend-added.subscription'
import { friendRemovedSubscription } from '../graphql/friend-removed.subscription'
import { apolloClient } from '../../apollo-subs'
import { getUserById } from '../../news-feed/graphql/get-user-by-id'
import Default from './default.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const width = window.innerWidth

const FriendsGridView = (props) => {
  const [userIdModal, setUserIdModal] = useState(null)
  const [users, setUsers] = useState(null)
  const [currentListSize, setCurrentListSize] = useState(0)
  const [loading, setLoading] = useState(true)
  const [hasMore, setHasMore] = useState(false)
  const [loadMore, setLoadMore] = useState(false)
  const [skipCount, setSkipCount] = useState(0)
  const [showButton, setShowButton] = useState(false)
  const [loadingFriend, setLoadingFriend] = useState(false);

  const subscribeToNewUsers = () => {
    apolloClient
      .subscribe({
        query: friendAddedSubscription,
        variables: {},
      })
      .subscribe({
        next: async (data) => {
          let newConnection = data.friendAdded
          let currentId = localStorage.getItem('userId')

          if (
            newConnection.userAdded.toString() === currentId.toString() ||
            newConnection.addedBy.toString() === currentId.toString()
          ) {
            let otherUserId =
              newConnection.addedBy.toString() === currentId.toString()
                ? newConnection.userAdded
                : newConnection.addedBy
            let res = await apolloClient.mutate({
              mutation: getUserById,
              variables: { userId: otherUserId.toString() },
            })
            if (users) {
              let cpy = [...users]
              let idx = cpy.findIndex((user) => user.id.toString() === otherUserId.toString())
              if (idx === -1) setUsers((prevState) => [res.data.userById, ...prevState])
            }
          }
        },
      })
  }

  const subscribeToRemovedUsers = () => {
    apolloClient
      .subscribe({
        query: friendRemovedSubscription,
        variables: {},
      })
      .subscribe({
        next: (data) => {
          console.log('friend removed!', data)
          const removedConnection = data.friendRemoved
          const currentId = localStorage.getItem('userId')
          if (
            removedConnection.userAdded.toString() === currentId.toString() ||
            removedConnection.addedBy.toString() === currentId.toString()
          ) {
            let otherUserId =
              removedConnection.addedBy.toString() === currentId.toString()
                ? removedConnection.userAdded
                : removedConnection.addedBy
            if (users) {
              const cpy = [...users]
              const idx = cpy.findIndex((user) => {
                return user.id.toString() === otherUserId.toString()
              })
              if (idx > -1) {
                cpy.splice(idx, 1)
                setUsers([...cpy])
              }
            } else {
              const cpy = props.connections.myConnections ? [...props.connections.myConnections] : []
              const idx = cpy.findIndex((user) => user.id.toString() === otherUserId.toString())
              if (idx > -1) {
                cpy.splice(idx, 1)
                setUsers([...cpy])
              }
            }
          }
        },
      })
  }

  useEffect(() => {
    subscribeToNewUsers()
    subscribeToRemovedUsers()
  }, [users])

  // const normalizeImgSrc = (src) => {
  //   return width < 500 ? src.replace('h_100,w_100', 'h_60') : src.replace('h_100,w_100', 'h_150')
  // }

  useEffect(() => {
    // console.log('friends', props)
    if (props.isGlobalSearch || props.activeState === 'GLOBAL_SEARCH') {
      setUsers(
        (props.searchResults && props.searchResults.searchStudents && props.searchResults.searchStudents.items) || []
      )

      setLoading(props.searchResults.networkStatus === 1)
      setHasMore(
        props.searchResults && props.searchResults.searchStudents && props.searchResults.searchStudents.hasMore
      )
    } else {
      if (props.connections && props.connections.myConnections) {
        setUsers(props.connections.myConnections)
        setCurrentListSize(props.connections.myConnections)
      }
      setLoading(props.connections.networkStatus === 1)
    }
  }, [props])

  useEffect(() => {
    if (!props.isGlobalSearch) {
      if (props.connections.myConnections) {
        setUsers(props.connections.myConnections)
      }
      if (props.connections.myConnections && props.connections.myConnections.length !== currentListSize) {
        setLoadMore(false)
        setCurrentListSize(props.connections.myConnections.length)
      }
      if (props.connections.myConnections && props.connections.myConnections.length % 10 !== 0) {
        setLoadMore(false)
      }
    }
  }, [props.connections])

  function renderItem(item) {
    return (
      <div
        key={item.id}
        onClick={() => {
          // TODO: refactor this logic ABA
          setUserIdModal(item.id)
          props.onItemPress(item)
          props.toggleComponent('UserProfile')
          if (width < 500) {
            props.history.push('userProfile', { user: item })
          } else {
            props.onModalOpen()
          }
        }}
        style={styles.friendContainer}
      >
        {/* <div style={styles.imageContainer}>
          {
            item.profilePictureUrl&&
            <img
            src={normalizeImgSrc(item.profilePictureUrl)}
            style={styles.profileImg}
            alt=""
          />
          }
        </div> */}
        <img src={item.profilePictureUrl || Default} style={styles.image} alt='' />
        {/* <div
          style={{
            ...styles.usernameContainer,
            borderRadius: `${!item.profilePictureUrl && width > 500 ? '15px' : '0px 15px 15px 0px'}`,
          }}
        >
          <p style={styles.username}>{item.name}</p>
        </div> */}
        <div style={styles.user}>{item.name}</div>
      </div>
    )
  }

  function renderNote(totalItems) {
    if (totalItems === 0) {
      return null
    }

    let text = ''
    if (props.activeState === EFriendsListActiveState.GLOBAL_SEARCH) {
      text = LangData.friendsGrid.totalOf + ' ' + totalItems + ' ' + LangData.friendsGrid.students
    } else if (props.activeState === EFriendsListActiveState.FRIENDS_LIST) {
      text = LangData.friendsGrid.totalOf + ' ' + totalItems + ' ' + LangData.friendsGrid.connection
    }
    if (text === '') {
      return null
    }
    return <p style={styles.noteText}>{text}</p>
  }

  function renderLoadMore(text = LangData.friendsGrid.search) {
    return (
      <button style={styles.renderMoreBtn} onClick={() => props.lookForMore()}>
        {text}
      </button>
    )
  }

  function renderNoItemsNote(totalItems, loading, hasMore) {
    if (totalItems > 0 || loading) {
      return null
    }

    if (props.activeState === EFriendsListFilterType.IGNORED) {
      return <EmptyNotice title={LangData.friendsGrid.title} text={LangData.friendsGrid.looks} emoji='😎' />
    } else if (props.activeState === EFriendsListFilterType.PENDING) {
      return <EmptyNotice title={LangData.friendsGrid.pending} text={LangData.friendsGrid.expand} emoji='😮' />
    } else if (props.activeState === EFriendsListFilterType.SENT) {
      return <EmptyNotice title={LangData.friendsGrid.sent} text={LangData.friendsGrid.expand} />
    } else if (props.activeState === EFriendsListActiveState.GLOBAL_SEARCH) {
      if (hasMore) {
        return (
          <div style={{ flex: 1 }}>
            <EmptyNotice
              title={LangData.friendsGrid.noFound}
              text={LangData.friendsGrid.sorryLong}
              subTitle={LangData.friendsGrid.broaden}
              emoji='😥'
            />
            {renderLoadMore()}
          </div>
        )
      }

      return <EmptyNotice title={LangData.friendsGrid.noFound} text={LangData.friendsGrid.sorry} emoji='😥' />
    } else if (props.activeState === EFriendsListActiveState.FRIENDS_LIST) {
      return (
        <div style={{ flex: 1 }}>
          <EmptyNotice
            containerStyle={{ justifyContent: 'flex-start' }}
            title={LangData.friendsGrid.snob}
            text={LangData.friendsGrid.expand}
            emoji='😮'
          />
          <FriendsSuggestions history={props.history} />
        </div>
      )
    }

    return null
  }

  const isBottom = (element) => {
    return element.scrollHeight - element.scrollTop === element.clientHeight
  }

  const handleScroll = (e) => {
    const wrappedElement = document.getElementById('profileCardsContainer')
    let bottom = isBottom(wrappedElement)
    if (bottom) {
      // setShowButton(true)
      loadMoreClicked()
      // let currentSkip = skipCount
      // props.loadMoreConnectionsData(currentSkip + 10);
      // setSkipCount(currentSkip + 10)
    }
  }

  const loadMoreClicked = () => {
    setLoadingFriend(true);
    setShowButton(false)
    setLoadMore(true)
    let currentSkip = skipCount
    props.loadMoreConnectionsData(currentSkip + 10, () => setLoadingFriend(false))
    setSkipCount(currentSkip + 10)
  }

  return (
    <div style={{ width: '100%', marginTop: '5%' }}>
      {loading ? null : users ? ( //<LoadingSpinner />
        <div style={styles.container}>
          {/* {hasMore &&
            users.length > 0 &&
            renderLoadMore(LangData.friendsGrid.broad)} */}
          {renderNoItemsNote(users.length, loading, hasMore)}
          {users.length > 0 && (
            <>
              <div
                style={{
                  ...styles.profileCardsContainer,
                  // height: props.inPopup ? '232px' : window.innerHeight * 0.65 + 25,
                }}
                id='profileCardsContainer'
                onScroll={(e) => handleScroll(e)}
              >
                {[...new Map(users.map((item) => [item['id'], item])).values()].map((item, index) => {
                  return renderItem(item)
                })}
              </div>
              {loadingFriend ? <FontAwesomeIcon spin={true} icon={faSpinner} style={styles.spinner} color="rgb(77, 75, 98)" /> : ''}
            </>
          )}
          {/* { !loadMore && showButton && <div style={styles.buttonContainer}><CustomButton onClick={loadMoreClicked}>Load More</CustomButton></div>} */}
        </div>
      ) : null}
    </div>
  )
}

export default FriendsGridView
const styles = {
  profileCardsContainer: {
    width: '102%',
    display: 'flex',
    // flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    height: width < 500 ? '60vh' : "500px",
    overflowY: 'auto',
    // my styles
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginBottom: 0,
  },

  list: {
    paddingBottom: 120,
    flexGrow: 1,
  },
  container: {
    width: '100%',
    // height: window.innerHeight * 0.9,
    overflowY: 'none',
    position: 'relative'
  },
  noteText: {
    color: 'white',
    textAlign: 'center',
    fontSize: 15,
    marginTop: 10,
  },
  renderMoreBtn: {
    textAlign: 'center',
    color: Theme.TEXT_COLOR,
    backgroundColor: Theme.BACKGROUND_COLOR,
    fontWeight: 'bold',
    fontFamily: 'inherit',
    fontSize: '14px',
    padding: '1%',
    borderRadius: '20px',
    width: '70%',
    border: '2px solid white',
    margin: '0 auto',
    display: 'block',
  },
  friendContainer: {
    cursor: 'pointer',
    width: '150px',
    height: '200px',
    // maxHeight: width < 500 ? '70px' : '150px',
    display: 'flex',
    flexDirection: 'column',
    // alignItems: width < 500 ? 'center' : 'normal',
    alignItems: 'center',
    // marginBottom: '2%'
    margin: '20px',
    borderRadius: '10px',
    overflow: 'hidden',
    backgroundColor: '#4d4b62',
  },
  imageContainer: {
    width: width < 500 ? '30%' : 'auto',
    maxHeight: width < 500 ? '60px' : '150px',
    backgroundColor: '#e0c2b0',
    height: width < 500 ? '60px' : 'auto',
    minHeight: '60px',
    alignItems: 'center',
    display: 'flex',
    borderRadius: '15px 0 0 15px',
  },
  image: {
    width: '150px',
    height: '150px',
    marginBottom: '10px',
  },
  user: {
    textAlign: 'center',
    fontWeight: 'bold',
    color: 'white',
  },
  usernameContainer: {
    width: width < 500 ? '70%' : '100%',
    backgroundColor: '#4d4b62',
    color: Theme.WHITE_COLOR,
    height: width < 500 ? '60px' : 'auto',
    minHeight: '60px',
  },
  profileImg: {
    maxHeight: width < 500 ? '60px' : '150px',
    width: width < 500 ? '100%' : 'auto',
    display: 'block',
    height: width < 500 ? 'auto' : '150px',
    borderRadius: '15px 0px 0px 15px',
    objectFit: 'fill',
  },
  username: {
    textAlign: 'left',
    marginLeft: '10%',
    fontWeight: 'bold',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // paddingTop: '2%'
  },
  spinner: {
    position: 'absolute',
    bottom: width < 500 ? '-10px' : 'unset',
    top: width > 500 ? '-25px' : 'unset',
    left: '50%',
    transform: 'translate(-50%, 0px)',
    width: '25px', 
    height: '25px', 
    color: "rgb(77, 75, 98)"
  }
}

const CustomButton = styled.button`
  background-color: ${Theme.DARK_BLUE_COLOR};
  justify-content: center;
  align-items: center;
  border: none;
  color: ${Theme.WHITE_COLOR};
  font-weight: bold;
  border-radius: 15px;
  padding: 1% 4%;
  height: 30px;
  white-space: nowrap;
  text-align: center;

  &:focus {
    outline: none;
    border: none;
  }
`
