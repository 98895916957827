import { connect } from 'react-redux';
import { ScheduleView } from './schedule.rn';
import { graphql } from 'react-apollo-temp';
import { scheduleQuery } from '../graphql/events-schedule.query';
import { compose } from 'redux';
import { setAgendaActiveCourse } from '../schedule.actions';
import { withErrorHandler } from '../../common/hoc/error-handler.rn';
import { toggleComponent } from '../../router/router.actions';
import {
  setActiveEventModal,
} from '../../news-feed/news-feed.actions';
const mapStateToProp = ({ schedule, auth, navigation, newsFeed }) => ({
  currentComponent: navigation.navigationComponents.component,
  prevComponent: navigation.navigationComponents.prevComponent,
  relevantMonths: schedule.relevantMonths,
  currentUser: auth.currentUser,
  activeEventModal: newsFeed.activeEventModal
});

const mapDispatch = dispatch => ({
  setActiveCourse: course => dispatch(setAgendaActiveCourse(course)),
  toggleComponent: component => dispatch(toggleComponent(component)),
  setActiveEventModal: event => dispatch(setActiveEventModal(event))
});

const withState = connect(mapStateToProp, mapDispatch);

const withData = graphql(scheduleQuery, {
  name: 'schedule',
  options: ({ relevantMonths, currentUser }) => ({
    variables: { 
      months: relevantMonths,
      userId: currentUser ? currentUser.id : null
    },
    fetchPolicy: 'cache-and-network'
  })
});

const withError = withErrorHandler({
  apollo: ['schedule']
});

export const withSchedule = compose(withState, withData, withError);

export const Schedule = withSchedule(ScheduleView);
