import React, { Component, useState, useEffect } from 'react';
import ProfileGalleryPhoto from './profile-gallery-photo.rn';
import { LangData } from '../../../assets/locales/localization';
import {
  openGalleryModal,
  toggleComponent
} from '../../../router/router.actions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const itemsPerRow = 3;
const width = window.innerWidth

export class ProfileGalleryView extends Component {
  static propTypes = {
    photos: PropTypes.arrayOf(PropTypes.any).isRequired,
    onDelete: PropTypes.func,
    noGradient: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.state = {
      viewer: null,
      isPhotoMenuOpen: false,
      photoMenuData: null,
      photos: [],
      size: 0
    };
  }

  componentDidMount() {
    this.setState({
      photos: this.props.photos,
      size: (this.props.photos || []).length
    });
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      photos: newProps.photos,
      size: newProps.photos.length
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  openPhoto(photo, index) {
    let imagesObj = { images: this.props.photos, firstIndex: index };
    this.props.openGalleryModal(imagesObj);
    this.props.history.push('/imageGalleryViewerModal');
  }

  renderItem = (photo, index) => {
    const mod = (index + 1) % itemsPerRow;
    const isFirst = mod === 1;
    const isLast = mod === 0;

    return (
      <div
        id='imgContainerOnProfileGallery'
        style={styles.imgContainer}
        key={index}
        onClick={() => this.openPhoto(photo, index)}
      >
        <ProfileGalleryPhoto
          currentUser={this.props.currentUser}
          photo={photo}
          isFirst={isFirst}
          isLast={isLast}
          showDeleteOption={this.props.showDeleteOption}
        />
      </div>
    );
  };

  render() {
    return (
      <div style={styles.container} id='mainConatainer'>
        {this.state.size ? (
          <div id='galleryContainer' style={styles.galleryContainer}>
            {this.state.photos.map((photo, index) => {
              return this.renderItem(photo, index);
            })}
          </div>
        ) : (
          <div style={styles.noPhotosContainer}>
            <p style={styles.noPhotosText}>{LangData.profileModal.noImages}</p>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ navigation }) => ({
  currentComponent: navigation.navigationComponents.component,
  prevComponent: navigation.navigationComponents.prevComponent
});

const mapDispatchToProps = dispatch => ({
  toggleComponent: component => dispatch(toggleComponent(component)),
  openGalleryModal: images => dispatch(openGalleryModal(images))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileGalleryView);

const styles = {
  container: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    maxHeight: width < 500 ? 'unset' : 'calc(100vh - 416px)',
    overflow: 'auto',
  },
  noPhotosContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 0.2
  },
  noPhotosText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#4d4b62',
    textAlign: 'center',
    marginTop: 15,
    marginHorizontal: 15
  },
  galleryContainer: {
    display: 'grid',
    width: width < 500 ? '100%' : 'unset',
    gridGap: width < 500 ? 'unset' : '25px',
    margin: width < 500 ? 'unset' : '0 auto',
    gridTemplateColumns:
    width < 500 ?
      window.innerWidth / 3.6 +
      10 +
      'px ' +
      (window.innerWidth / 3.6 + 10) +
      'px ' +
      (window.innerWidth / 3.6 + 10) +
      'px': '250px 250px 250px'
  },
  imgContainer: {
    textAlign: 'center',
    marginTop: '5px'
  }
};
