import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { notificationsStyles } from './common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

export class EventWallApprovalNotificationItem extends Component {
  static propTypes = {
    item: PropTypes.any
  };

  render() {
    const { item } = this.props;

    if (!item || !item.event) {
      return null;
    }

    return (
      <div style={notificationsStyles.container}>
        <div style={notificationsStyles.imageContainer}>
          <img
            src={item.event.thumbUrl}
            style={{ height: 65, width: 50, borderRadius: 10 }}
          />
        </div>
        <div style={notificationsStyles.contentContainer}>
          <span style={notificationsStyles.text}>
            <span style={notificationsStyles.boldText}>
              {item.event.title}:{' '}
            </span>
            {item.content}
          </span>
          <span style={notificationsStyles.date}>{moment(item.time).format('MM/DD/YYYY')}</span>
        </div>
        <div style={notificationsStyles.extraContainerImageApproval}>
          <img
            src={item.photoUrl}
            style={{
              opacity: 0.8,
              height: 65,
              width: 50,
              borderRadius: 10
            }}
          />
          <FontAwesomeIcon
            icon={item.approved ? faCheck : faTimes}
            style={styles.icon}
          />
        </div>
      </div>
    );
  }
}

const styles = {
  extraContainer: {
    alignItems: 'flex-end'
  },
  icon: {
    position: 'absolute',
    right: 5,
    bottom: 0,
    opacity: 0.7
  }
};
