import React from 'react';

const width = window.innerWidth

export const ChatModal = ({ children}) => {
  return (
    width > 500 ? <div style={styles.wrapper}>
      {children}
    </div> : <>{children}</>
  )
}

const styles = {
  wrapper: {
    width: '400px',
    height: '600px',
    position: 'fixed',
    right: '12px',
    bottom: '12px',
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.40)',
    zIndex: 99999999999,
  }
}
