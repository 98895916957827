import React from 'react';

import { ChatScreen } from "../chat/components/chat-screen/chat-screen.container";
import { ChatModal } from "../chat/components/chat-modal";
import {useSelector} from "react-redux";
import {selectIsChatOpen, selectTitle} from "./windowedChat.reducer";

export const WindowedChat = props => {
  const isOpen = useSelector(selectIsChatOpen)
  const title = useSelector(selectTitle);

  return isOpen ? (
    <ChatModal>
      <ChatScreen userName={title} {...props} />
    </ChatModal>
  ) : null
}
