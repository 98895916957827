import { graphql } from 'react-apollo-temp';
import { totalUnreadCountQuery } from "../../graphql/unread-count.query";

export const withUnreadChatMessagesCount = graphql(totalUnreadCountQuery, {
  name: 'unreadChatMessagesCount',
  options: ({ currentUser}) => {
    // console.log('in hook unreadchat user', currentUser)
    return {
      variables: {
        userId: currentUser ? currentUser.id : null,
      },
      fetchPolicy: 'network-only'
    }
  },
  props: ({ unreadChatMessagesCount }) => {
    // const unreadChatMessages = undefined
    if (!unreadChatMessagesCount || unreadChatMessagesCount.loading || unreadChatMessagesCount.error) {
      return {
        unreadChatMessagesCount: 0,
      };
    }
    return {
      unreadChatMessagesCount: unreadChatMessagesCount.unreadChatMessagesCount,
    };
  },
});
