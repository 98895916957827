import React, { Fragment, useState, useEffect } from 'react';
import CourseListItem from './course-list-item.rn';
import LoadingSpinner from '../../common/components/loading-spinner.rn';
import EmptyNotice from '../../common/components/empty-notice.rn';
import { LangData } from '../../assets/locales/localization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Theme } from '../../common/providers/colors';

const CoursesView = props => {
  const [courses, setCourses] = useState({
    loading: true,
    networkStatus: props.networkStatus ? props.networkStatus : 1,
    error: props.error ? props.error : null,
    myCourses: props.myCourses ? props.myCourses : []
  });

  useEffect(() => {
    setCourses(
      props.courses && props.courses.myCourses ? props.courses.myCourses : []
    );

    if (props.courses.myCourses !== undefined) {
      if (props.courses.myCourses.length > 0 && props.activeCourse === null) {
        let defaultCourseObjForActive = {
          identifier: props.courses.myCourses[0].identifier,
          name: props.courses.myCourses[0].fullName
        };

        props.changeActiveCourse(defaultCourseObjForActive);
      }
    }
  }, [props.courses]);

  const isActive = (index, identifier) => {
    if (props.activeCourse === null && index === 0) {
      return true;
    }

    return props.activeCourse.identifier === identifier;
  };

  const renderItem = courseItem => {
    let courseObjForActive = {
      identifier: courseItem.identifier,
      name: courseItem.fullName
    };

    return (
      <CourseListItem
        showUpcomingText={true}
        scheduleDate={
          courseItem.nextSchedule && courseItem.nextSchedule.date
            ? courseItem.nextSchedule.date
            : null
        }
        startTime={
          courseItem.nextSchedule && courseItem.nextSchedule.startTime
            ? courseItem.nextSchedule.startTime
            : null
        }
        endTime={
          courseItem.nextSchedule && courseItem.nextSchedule.endTime
            ? courseItem.nextSchedule.endTime
            : null
        }
        active={isActive(-1, courseItem.identifier)}
        key={courseItem.identifier}
        course={courseItem}
        onPress={() => props.changeActiveCourse(courseObjForActive)}
      />
    );
  };

  // const onRefresh = () => {
  //   setRefreshing(true);
  //
  //   if (props.courses) {
  //     props.courses
  //       .refetch()
  //       .catch(() => null)
  //       .then(() => {
  //         setRefreshing(false);
  //       });
  //   }
  // };

  // if (!props.currentUser) {
  //   return <Redirect to='/login' />;
  // }

  return (
    <div style={styles.allCourseContainer}>
      <div style={styles.optionsRow} id='optionsRow'>
        <div
          onClick={() => {
            props.toggleComponent(props.prevComponent);
            props.history.push('/');
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </div>
        <div>
          <span>Groups</span>
        </div>
        <div></div>
      </div>
      {courses.networkStatus === 1 || props.courses.loading ? (
        <LoadingSpinner />
      ) : props.courses &&
        props.courses.myCourses &&
        props.courses.myCourses.length === 0 ? (
            <EmptyNotice
              text={LangData.courses.part}
              emoji='🏖'
              title={LangData.courses.no}
            />
          ) : (
            <Fragment>{courses.map(course => renderItem(course))}</Fragment>
          )}

      {/* <CoursesDrawer history={props.history} /> */}
    </div>
  );
};

export default CoursesView;

const styles = {
  allCourseContainer: {
    width: window.innerWidth,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: Theme.BACKGROUND_COLOR,
    height: window.innerHeight,
    color: Theme.TEXT_COLOR
  },
  optionsRow: {
    display: 'flex',
    padding: '5%',
    justifyContent: 'space-between',
    color: Theme.TEXT_COLOR,
    fontWeight: 'bold',
    width: '90%'
  }
};
