import moment from 'moment';
import { reportAnalyticsMutation } from './graphql/report-analytics.mutation';

class MsjAnalytics {
  constructor() {
    this._queue = [];
    this._user = null;
    this._handle = null;
    this._apolloClient = null;
  }

  start(interval, client) {
    this._handle = setInterval(() => this.send(), interval);
    this._apolloClient = client;
  }

  stop() {
    if (this._handle) {
      clearInterval(this._handle);
      this._handle = null;
    }
  }

  setUser(user) {
    if (!user) {
      console.warn('Analytics: Attempting to set null user');
      return;
    }
    if (!user.id) {
      console.warn('Analytics: User object missing ID');
      return;
    }
    this._user = user;
  }

  send() {
    if (this._queue && this._queue.length > 0) {
      const copy = [...this._queue];
      this._queue = [];

      this._apolloClient.mutate({
        mutation: reportAnalyticsMutation,
        variables: {
          items: copy,
        },
      }).catch(() => null);
    }
  }

  report(category, action, identifier = null, overrideUser = null) {
    const userId = overrideUser ? overrideUser.id : this._user ? this._user.id : null;
    
    if (!userId) {
      console.warn(`Analytics: No userId available for event - category: ${category}, action: ${action}`);
    }

    this._queue.push({
      category,
      action,
      identifier,
      userId,
      time: moment().utc().format(),
    });
  }
}

export const analytics = new MsjAnalytics();
