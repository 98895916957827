import React, {Component, Fragment} from 'react';
import {apolloClient} from '../../apollo-subs';
import './meetings.css';
import {meetingsQuery} from '../graphql/meetings.query';
import {Theme} from '../../common/providers/colors';
import LoadingSpinner from '../../common/components/loading-spinner.rn';
import moment from 'moment';
import {registerMeetingMutation} from '../graphql/register-meeting.mutation';
import {meetingCreatedSubscription} from '../graphql/meeting-create.subscription';
import {meetingUpdatedSubscription} from '../graphql/meeting-updated.subscription';
import {meetingRemovedSubscription} from '../graphql/meeting-removed.subscription';
import {updateMeetingStartedMutation} from '../graphql/update-meeting-status.mutation';
import ErrorMessage from '../../login/components/error-message.rn';
import HeaderHOC from '../../main/HeaderHOC';

const {ZoomMtg} = require('@zoomus/websdk')
const apiKey = "2WRtlJLbTD2Oo_u9ItMp-g"
const width = window.innerWidth


export class MeetingsView extends Component {
  constructor() {
    super();

    this.state = {
      meetingNumber: 123,
      role: 1,
      futureMeetings: null,
      error: null
    }
  }

  componentWillMount = () => {
    this.getAllFutureMeetings()
    this.subscribeToNewMeetings()
    this.subscribeToUpdatesInMeetings()
    this.subscribeToRemovedMeetings()
  }

  componentWillUnmount = async () => {
    document.getElementById('zmmtg-root').style.display = 'none'
  }


  getAllFutureMeetings = async () => {
    apolloClient.query({
      query: meetingsQuery,
      variables: {}
    })
      .then(res => {
        let meetings = [...res.data.allMeetings]
        meetings.sort((a, b) => {
          let aTime = moment(a.meetingTime)
          let bTime = moment(b.meetingTime)
          if (bTime.isAfter(aTime)) return 1
          if (bTime.isBefore(aTime)) return -1
          return 0
        })
        this.setState({futureMeetings: meetings})
      })
  }

  subscribeToNewMeetings = async () => {
    apolloClient.subscribe({
      query: meetingCreatedSubscription,
      variables: {}
    })
      .subscribe({
        next: (data) => {
          let newMeeting = data.meetingCreated
          this.setState({futureMeetings: [newMeeting, ...this.state.futureMeetings]})
        }
      })
  }

  subscribeToUpdatesInMeetings = async () => {
    apolloClient.subscribe({
      query: meetingUpdatedSubscription,
      variables: {}
    })
      .subscribe({
        next: (data) => {
          let updatedMeeting = data.meetingUpdated
          let cpy = [...this.state.futureMeetings]
          let idx = cpy.findIndex(elem => elem.id === updatedMeeting.id)
          if (idx !== -1) {
            cpy[idx] = updatedMeeting
            this.setState({futureMeetings: cpy})
          }

        }
      })
  }

  subscribeToRemovedMeetings = async () => {
    apolloClient.subscribe({
      query: meetingRemovedSubscription,
      variables: {}
    })
      .subscribe({
        next: (data) => {
          let removedMeeting = data.meetingRemoved
          let cpy = [...this.state.futureMeetings]
          let idx = cpy.findIndex(elem => elem.id === removedMeeting.id)
          if (idx !== -1) {
            cpy.splice(idx, 1)
            this.setState({futureMeetings: cpy})
          }
        }
      })
  }

  getSignature = async (meetingNumber, hostType) => {
    let res = await fetch('https://Be.YOCAS.life:4000/generate_signature', {
      // let res = await fetch('http://localhost:4000/generate_signature', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify({
        meetingNumber,
        role: hostType
      })
    })
    let data = await res.json()
    let signature = data.signature
    return signature
  }

  generateOAuthToken = async () => {
    // let res = await fetch('http://localhost:4000/generateAccessToken', {
    let res = await fetch('https://Be.YOCAS.life:4000/generateAccessToken', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    })
    let data = await res.json()
    return data.meeting
  }

  createMeetingFromAPI = async () => {
    // let token
    let meeting = await this.generateOAuthToken()
    this.setState({meeting})
    return meeting
  }

  onUnload = async () => { // this will hold the cleanup code
    // whatever you want to do when the component is unmounted or page refreshes

    // let res = await fetch('http://localhost:4000/endMeeting', {
    let res = await fetch('https://Be.YOCAS.life:4000/endMeeting', {
      method: 'POST',
      // mode: 'no-cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        meetingId: this.state.meeting.id
      })
    })
    let data = await res.json()
    return data.meeting
  }


  registerMeeting = async (meeting) => {
    apolloClient.mutate({
      mutation: registerMeetingMutation,
      variables: {
        userId: this.props.currentUser.id,
        userEmail: this.props.currentUser.msjEmail,
        userPhoneNumber: this.props.currentUser.phoneNumber,
        meetingId: meeting.id
      }
    })
      .then(res => {
        let result = res.data.registerToMeeting
        if (result === null) {
          this.setState({error: "Already registered to live stream"})
        }
      })
  }

  updateHostJoined = async (meeting) => {
    apolloClient.mutate({
      mutation: updateMeetingStartedMutation,
      variables: {
        meetingId: meeting.id
      }
    })
  }

  componentWillUnmount = () => {
    document.getElementById('zmmtg-root').style.display = 'none'
    this.props.toggleComponent('News_Feed')
    this.props.history.push('/')
  }

  joinMeeting = async (meeting) => {
    window.open(`https://yocas.life/video/${meeting.meetingName}/${this.props.currentUser.name}`,"_self");
    let hostType = meeting.hostId.toString() === this.props.currentUser.id.toString() ? 1 : 0
    console.log('hostType =>', hostType)
    if (hostType === 1) {
      this.updateHostJoined(meeting)
    }
  }

  render() {
    return (
      <div>
        <HeaderHOC history={this.props.history} currentUser={this.props.currentUser} />
        <div style={styles.mainContainer}>
          <div style={styles.optionsRow}>
            <div
              onClick={() => {
                this.props.toggleComponent(this.props.prevComponent);
                this.props.history.push('/');
              }}
            >
              <span style={styles.goBack}>{'< Back'}</span>
            </div>
            <div style={width < 500 ? {} : {marginRight: '1%'}}>
              <h1 style={
                width < 500 ?
                  {color: '#4D4B62', textTransform: 'capitalize', fontSize: '7vw'}
                  :
                  {color: '#4D4B62', textTransform: 'capitalize', fontSize: '2rem'}
              }>Live Streaming</h1>
            </div>
          </div>
          <div className='meetingsContainer' style={styles.meetingsContainer}>
            {
              this.state.futureMeetings === null ?
                <LoadingSpinner/>
                :
                this.state.futureMeetings.map(meeting => (
                  <div className='meetingContainer' key={meeting.id} style={styles.meetingContainer}>
                    <div className='dateContainer' style={styles.dateContainer}>
                      <label className='day' style={styles.day}>{moment(meeting.meetingTime).format('DD')}</label>
                      <label>{moment(meeting.meetingTime).format('MMMM')}</label>
                    </div>
                    <label className='meetingName' style={
                      width < 500 ?
                        {width: '35%', fontSize: '4vw', display: 'flex', alignItems: 'center'}
                        : {width: '35%', fontSize: '2rem', display: 'flex', alignItems: 'center'}
                    }>{meeting.meetingName}</label>
                    <label className='meetingTime' style={
                      width < 500 ?
                        {fontSize: '4vw', display: 'flex', alignItems: 'center'}
                        : {fontSize: '2rem', display: 'flex', alignItems: 'center'}
                    }>{moment(meeting.meetingTime).format('h:mm A')}</label>
                    {
                      meeting.isStarted === true && meeting.isFinished === false ?
                        <button style={styles.actionButton} onClick={() => this.joinMeeting(meeting)}>Join Live</button>
                        :
                        this.props.currentUser && meeting.hostId.toString() === this.props.currentUser.id.toString() ?
                          <button style={styles.actionButton} onClick={() => this.joinMeeting(meeting)}>Start
                            Live </button>
                          :
                          this.props.currentUser && meeting.participantsList.length > 0 && meeting.participantsList.filter(participant => participant.userId.toString() === this.props.currentUser.id.toString()).length > 0 ?
                            <button style={styles.actionButton}
                                    onClick={() => this.registerMeeting(meeting)}>Registered</button>
                            :
                            <button style={styles.actionButton}
                                    onClick={() => this.registerMeeting(meeting)}>Register</button>

                    }
                  </div>
                ))
            }
          </div>
        </div>
        {this.state.error && (
          <ErrorMessage
            key='error message'
            show={this.state.error !== null}
            message={this.state.error}
            onPress={() => {
              this.setState({error: null})
              this.props.dismissError();
            }}
          />
        )}
      </div>
    )
  }
}

const styles = {
  meetingsContainer: {
    height: window.innerHeight,
    overflowY: 'scroll',
    width: width < 500 ? '97%' : "90%",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  mainContainer: {
    width: window.innerWidth,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: window.innerHeight,
    backgroundImage: 'linear-gradient(to bottom, rgb(231,228,216,0.95), rgb(237,204,181,0.95))',
    color: Theme.TEXT_COLOR,
    paddingTop: '70px'
  },
  optionsRow: {
    display: 'flex',
    padding: width<500?'5%':'2%',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: Theme.TEXT_COLOR,
    fontWeight: 'bold',
    width: width < 500 ? '71%' : '58%',
    marginLeft: width < 500 ? '-19vw' : '-41vw'
  },
  goBack: {
    borderRadius: '10px',
    position: 'absolute',
    textAlign: 'center',
    left: width < 500 ? '10px' : 'unset',
    cursor: 'pointer',
    backgroundColor: '#4D4B62',
    color: 'floralwhite',
    zIndex: 99,
    whiteSpace: 'nowrap',
    padding: "3px 7px",
    fontSize: '133%',
  },
  meetingContainer: {
    padding: width < 500 ? '3%' : '1.5%',
    border: '2px solid #4D4B62',
    width:  width < 500 ? '97%': '84%',
    marginBottom: width < 500 ? '7%' : '2%',
    borderRadius: '50px',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#4D4B62',
    color: 'white'
  },
  dateContainer: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: width<500?'14px':'89%',
    alignItems: 'center',
    fontSize: width < 500 ? 'unset' : '2rem',
    
  },
  day: {
    fontSize: width<500? '20px':'2rem',
  },
  actionButton: {
    backgroundColor: 'rgb(237,204,181,0.95)',
    color: '#4D4B62',
    borderRadius: '50px',
    padding: width < 500 ? '2%' : '1%',
    fontSize: width < 500 ? '1rem' : '2rem'
  }
}
