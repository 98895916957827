import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import sortBy from 'lodash.sortby'
import { FindChatsBar } from './find-chats-bar.container'
import ProfileImage from '../../../common/components/profile-image.rn'
import EmptyNotice from '../../../common/components/empty-notice.rn'
import { ChatListItemMenu } from '../chat-screen/chat-list-item-menu.rn'
import { LangData } from '../../../assets/locales/localization'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera } from '@fortawesome/free-solid-svg-icons'
import { Theme } from '../../../common/providers/colors'
import moment from 'moment'
import { apolloClient } from '../../../apollo-subs'
import { chatAddedSubscription } from '../../graphql/chat-added.subscription'
import { chatRemovedSubscription } from '../../graphql/chat-removed.subscription'
import { chatUpdatedSubscription } from '../../graphql/chat-updated.subscription'
import PulseLoader from 'react-spinners/PulseLoader'
import { PortalWrapper } from '../../../ProtalWrapper'
import { CreateChatModal } from '../create-chat-modal'

const width = window.innerWidth

export class ChatListView extends Component {
  static propTypes = {
    navigateToChat: PropTypes.func.isRequired,
    setSearchTerm: PropTypes.func.isRequired,
    searchTerm: PropTypes.string.isRequired,
    chatsData: PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.any,
      networkStatus: PropTypes.number,
      chats: PropTypes.array,
      startPolling: PropTypes.func,
      stopPolling: PropTypes.func,
    }),
    pendingCount: PropTypes.any,
    notificationsEnabled: PropTypes.bool,
    navigateToFriendsList: PropTypes.func,
    user: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.styles = makeStyles(props.inModal)
    this.state = {
      menuChat: null,
      newChatModalOpen: false,
      myChats: [],
      loading: true,
    }
  }

  componentDidMount() {
    if (!this.props.notificationsEnabled && this.props.chatsData) {
      this.props.chatsData.startPolling(10000)
    }
  }

  subscribeToNewChats = () => {
    apolloClient
      .subscribe({
        query: chatAddedSubscription,
        variables: {},
      })
      .subscribe({
        next: (data) => {
          let newChat = data.chatAdded
          let usersIds = []
          newChat.users.map((user) => usersIds.push(user.id))
          if (usersIds.includes(this.props.currentUser.id.toString()) && this.state.myChats) {
            this.setState({ myChats: [newChat, ...this.state.myChats] })
          }
        },
      })
  }

  subscribeToUpdatesInChat = () => {
    apolloClient
      .subscribe({
        query: chatUpdatedSubscription,
        variables: {},
      })
      .subscribe({
        next: (data) => {
          let updatedChat = data.chatUpdated
          let usersIds = []
          updatedChat.users.map((user) => usersIds.push(user.id))
          if (usersIds.includes(this.props.currentUser.id.toString()) && this.state.myChats) {
            let cpy = [...this.state.myChats]
            let idx = cpy.findIndex((elem) => elem.id === updatedChat.id)
            if (idx !== -1) {
              cpy[idx] = updatedChat
              this.setState({ myChats: cpy })
            }
          }
        },
      })
  }

  subscribeToRemovedChat = () => {
    apolloClient
      .subscribe({
        query: chatRemovedSubscription,
        variables: {},
      })
      .subscribe({
        next: (data) => {
          let removedChat = data.chatRemoved
          let usersIds = []
          removedChat.users.map((user) => usersIds.push(user.id.toString()))
          if (usersIds.includes(this.props.currentUser.id.toString()) && this.state.myChats) {
            let cpy = [...this.state.myChats]
            let idx = cpy.findIndex((elem) => elem.id === removedChat.id)
            if (idx !== -1) {
              cpy.splice(idx, 1)
              this.setState({ myChats: cpy })
            }
          }
        },
      })
  }

  componentWillMount = () => {
    this.setState({ loading: true })
    this.setState({ myChats: this.props.chatsData.chats })
    this.subscribeToNewChats()
    this.subscribeToUpdatesInChat()
    this.subscribeToRemovedChat()
  }

  componentWillReceiveProps = (newProps) => {
    if (newProps.chatsData.chats !== null) {
      this.setState({ loading: false, myChats: newProps.chatsData.chats })
    }
  }

  componentWillUnmount() {
    if (!this.props.notificationsEnabled && this.props.chatsData) {
      this.props.chatsData.stopPolling()
    }
  }

  renderChatItem(chat, isGroup = false) {
    const chatTitle = isGroup
      ? chat.title
      : chat.users.filter((user) => user.id.toString() !== this.props.currentUser.id.toString())[0].name

    return (
      <Fragment>
        <div style={this.styles.profilePic}>
          {isGroup ? (
            this.buildProfilePictureCollage(chat.users)
          ) : (
            <ProfileImage
              imageUrl={
                chat.users.filter((user) => user.id.toString() !== this.props.currentUser.id.toString())[0]
                  .profilePictureUrl
              }
              height={50}
              width={50}
              showBorder={true}
            />
          )}
        </div>
        <div style={this.styles.detailsContainer} id='detailsContainer'>
          <div style={this.styles.dateContainer} id='dateContainer'>
            <span style={this.styles.lastMessageDate} id='lastMessageDate'>
              {chat.lastMessage
                ? moment(chat.lastMessage.createdAt).format('lll')
                : moment(chat.createdAt).format('lll')}
            </span>
          </div>

          <div style={this.styles.nameRow} id='nameRow'>
            <div style={this.styles.nameAndMessageContainer}>
              <span style={this.styles.name}>{chatTitle}</span>
              {chat.unreadMessagesCount && chat.unreadMessagesCount > 0 ? (
                <span style={this.styles.unreadMessagesCount}>{chat.unreadMessagesCount}</span>
              ) : null}
            </div>

            {this.renderLastMessage(chat)}
          </div>
        </div>
      </Fragment>
    )
  }

  renderLastMessage(chat) {
    if (!chat.lastMessage) {
      return null
    }

    if (chat.lastMessage.messageType === 'IMAGE') {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <FontAwesomeIcon icon={faCamera} />
          <span style={this.styles.lastMessageText}>{LangData.chatList.photo}</span>
        </div>
      )
    }

    return <span style={this.styles.lastMessageText}>{chat.lastMessage ? chat.lastMessage.content : ''}</span>
  }

  buildProfilePictureCollage(users) {
    const toPic = (url, size = 20) => <ProfileImage withFullSize={false} imageUrl={url} height={size} width={size} />

    const mapping = {
      2: () => (
        <div style={{ position: 'relative' }}>
          <div style={{ position: 'absolute', left: 0, top: 0 }}>{toPic(users[0].profilePictureUrl)}</div>
          <div style={{ position: 'absolute', right: 0, bottom: 0 }}>{toPic(users[1].profilePictureUrl)}</div>
        </div>
      ),
      3: () => (
        <div style={{ position: 'relative' }}>
          <div style={{ position: 'absolute', left: -10, bottom: 0 }}>{toPic(users[0].profilePictureUrl)}</div>
          <div style={{ position: 'absolute', right: 0, top: 0 }}>{toPic(users[1].profilePictureUrl)}</div>
          <div style={{ position: 'absolute', left: 0, top: 0 }}>{toPic(users[2].profilePictureUrl)}</div>
        </div>
      ),
      4: () => (
        <div style={{ position: 'relative' }}>
          <div style={{ position: 'absolute', right: 0, bottom: 0 }}>{toPic(users[0].profilePictureUrl)}</div>
          <div style={{ position: 'absolute', right: 0, top: 0 }}>{toPic(users[1].profilePictureUrl)}</div>
          <div style={{ position: 'absolute', left: 0, bottom: 0 }}>{toPic(users[2].profilePictureUrl)}</div>
          <div style={{ position: 'absolute', left: 0, top: 0 }}>
            <p style={this.styles.totalUsersText}>{users.length + 1}</p>
          </div>
        </div>
      ),
    }

    const renderer = mapping[Math.min(users.length, 4)]

    return renderer ? renderer() : null
  }

  navigateToChat(chat) {
    requestAnimationFrame(() => {
      this.props.navigateToChat(chat)
    })
    if (width < 500) {
      this.props.toggleComponent('ChatScreen')
      this.props.history.push('chatScreen')
    } else {
      this.props.openChat(this.props.user.name == chat.users[0].name ? chat.users[1].name : chat.users[0].name);
    }
  }

  openChatMenu(chat) {
    requestAnimationFrame(() => {
      this.setState({
        menuChat: chat,
      })
    })
  }

  renderItem(item) {
    return (
      <div
        key={item.id}
        style={this.styles.itemContainer}
        id='itemContainer'
        // onLongPress={() => this.openChatMenu(item)}
        onClick={() => {this.navigateToChat(item); this.props.closeChatModal() }}
      >
        {this.renderChatItem(item, !!item.title)}
      </div>
    )
  }

  openNewChatModal() {
    this.setState({
      newChatModalOpen: true,
    })
    this.props.toggleComponent('CreateNewChat')
    this.props.history.push('createNewChat')
  }

  toFriendsList(toRequests) {
    this.props.toggleComponent('Friends')
    this.props.history.push('friends')
  }

  render() {
    const allChats = this.state.myChats ? this.state.myChats : []

    let lowercase =
      this.props.searchTerm !== null ? this.props.searchTerm.toString().toLowerCase() : this.props.searchTerm
    const chatsList = sortBy(
      allChats.filter((chat) =>
        [chat.title || '', ...chat.users.map((u) => u.name), ...chat.users.map((u) => u.username)].some((text) =>
          text !== null ? text.toLowerCase().includes(lowercase) : false
        )
      ),
      (obj) => parseInt(obj.lastUpdate)
    ).reverse()

    return (
      <>
        <div style={this.styles.mainContainer} id='main-chat-container'>
          <ChatListItemMenu
            chat={this.state.menuChat}
            currentUser={this.props.currentUser}
            onClose={() => this.setState({ menuChat: null })}
            visible={this.state.menuChat !== null}
          />
          {this.props.pendingCount > 0 && (
            <div onClick={() => this.toFriendsList(true)}>
              <p style={this.styles.pendingText}>
                {LangData.chatList.connections1 + this.props.pendingCount + LangData.chatList.connections2}
              </p>
            </div>
          )}
          <FindChatsBar openNewChatModal={() => this.openNewChatModal()} />

          {(this.props.chatsData.networkStatus === 1 && !chatsList.length) ? (
            <div style={this.styles.loaderContainer}>
              <PulseLoader sizeUnit={'px'} size={20} color={'white'} />
            </div>
          ) : chatsList.length === 0 ? (
            <div style={{ flex: 1 }}>
              <EmptyNotice
                title={
                  this.props.searchTerm === 'no conversations yet'
                    ? LangData.chatList.noConv
                    : LangData.chatList.noFound
                }
                emoji='👻'
                text={this.props.searchTerm === '' ? LangData.chatList.started : ''}
                titleColor={'#3a3d62'}
              />

              <div style={{ textAlign: 'center' }} id='reset search'>
                {this.props.searchTerm === '' ? (
                  <button style={this.styles.resetBtn} onClick={() => this.toFriendsList(false)}>
                    {LangData.chatScreen.addConnections}
                  </button>
                ) : (
                  <button style={this.styles.resetBtn} onClick={() => this.props.setSearchTerm('')}>
                    {LangData.chatScreen.reset}
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div id='render-items' style={this.styles.allChatsContainer}>
              {chatsList
                .sort((item1, item2) => {
                  return new Date(item2.lastUpdate) - new Date(item1.lastUpdate)
                })
                .sort((item1, item2) => {
                  if (item1.unreadMessagesCount && !item2.unreadMessagesCount) {
                    return -1
                  } else {
                    return 1
                  }
                })
                .map((item, index) => {
                  return this.renderItem(item, index)
                })}
            </div>
          )}
        </div>
        <PortalWrapper>
          <CreateChatModal />
        </PortalWrapper>
      </>
    )
  }
}

const makeStyles = (inModal) => ({
  loaderContainer: {
    opacity: '0.85',
    zIndex: '999999',
    textAlign: 'center',
    marginTop: '10%',
    // position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Theme.BACKGROUND_COLOR,
  },
  mainContainer: {
    width: width < 500 ? window.innerWidth : '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: width < 500 ? '20%' : inModal ? 0 : '120px',
  },
  allChatsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10px',
    paddingBottom: inModal ? '5px' : '40px',
  },
  list: {
    paddingBottom: 70,
  },
  layoutContainer: {
    flexDirection: 'row',
    flex: 1,
    position: 'relative',
    height: '100%',
  },
  dateContainer: {
    alignSelf: 'flex-end',
  },
  lastMessageDate: {
    color: Theme.TEXT_COLOR,
    fontSize: 11,
  },
  detailsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 10,
    marginRight: '2%',
    width: '100%',
    flexDirection: 'column',
  },
  profilePic: {
    width: 50,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  name: {
    fontWeight: 'bold',
    color: Theme.SECONDARY_TEXT_COLOR,
    fontSize: 15,
    backgroundColor: 'transparent',
  },
  nameRow: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 5,
  },
  nameAndMessageContainer: {
    display: 'flex',
  },
  unreadMessagesCount: {
    backgroundColor: Theme.SECONDARY_TEXT_COLOR,
    marginLeft: 10,
    height: 15,
    width: 20,
    borderRadius: 15,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 10,
    color: 'white',

    letterSpacing: -1,
  },

  lastMessageText: {
    alignSelf: 'flex-start',
    color: Theme.TEXT_COLOR,
    fontSize: 15,
    marginTop: 2,
  },
  animatedBg: {
    flex: 1,
    opacity: 0.3,
  },
  animatedContainer: {
    marginTop: 22,
  },
  totalUsersText: {
    height: 20,
    width: 20,
    textAlign: 'center',
    fontWeight: 'bold',
    marginTop: 3,
  },
  pendingText: {
    alignSelf: 'center',
    color: '#4d4b62',
    fontWeight: 'bold',
    fontSize: 13,
  },
  itemContainer: {
    margin: '10px',
    backgroundColor: 'white',
    borderRadius: '15px',
    display: 'flex',
    height: '65px',
    overflow: 'hidden',
    cursor: 'pointer',
  },
  resetBtn: {
    width: '70%',
    height: '30px',
    backgroundColor: Theme.SECONDARY_BACKGROUND_COLOR,
    color: Theme.TEXT_COLOR,
    border: 'none',
    borderRadius: '20px',
    fontWeight: 'bold',
    fontSize: '15px',
    fontFamily: 'inherit',
  },
})
