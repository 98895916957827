import React, { useState, useEffect } from 'react';
import { FeedEventItem } from '../../feed-list/feed-event-item/feed-event-item.container';
// import { ImageGalleryViewerModal } from '../../../../common/components/image-gallery-viewer-modal';
import { analytics } from '../../../../analytics/report';
import { LangData } from '../../../../assets/locales/localization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import AttendingListView from './attending-list.rn';
import { Theme } from '../../../../common/providers/colors';

const FeedEventPreviewModalView = props => {
  const [rsvpTotal, setRsvpTotal] = useState(0);

  let maybeProps;
  let goingProps;
  useEffect(() => {
    maybeProps = getButtonStyleProps('MAYBE');
    goingProps = getButtonStyleProps('GOING');
    // if (props.goingList.eventGoingList)
    //   setRsvpTotal(props.goingList.eventGoingList.total);
  }, [props]);

  function rsvp(response) {
    props.onRsvpChanged(response);
  }

  function getButtonStyleProps(type) {
    if (props.event.userRSVP === type) {
      return {
        color: Theme.SECONDARY_TEXT_COLOR,
        border: null
      };
    }

    return {
      color: 'white',
      border: 'white'
    };
  }

  return (
    <div id='FeedEventPreviewModalViewRow42' style={styles.containerModal}>
      <div
        id='FeedEventPreviewModalViewRow43'
        disabled={true}
        style={styles.innerContainer}
      >
        <div onClick={() => props.onClose()} style={styles.closeContainer}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <FeedEventItem
          extended={true}
          eventItem={props.event}
          onPosterClicked={props.onPosterClicked}
          onLastImagesClicked={data => {
            analytics.report('Feed', 'LastImagesGalleryView', props.event.id);
          }}
          history={props.history}
          showComments={false}
        />

        <div style={styles.rowButtonsContainer}>
          <button
            onClick={e => {
              e.stopPropagation();
              rsvp('GOING');
            }}
            {...goingProps}
            style={
              props.event.userRSVP === 'MAYBE'
                ? styles.notSelectedBtn
                : styles.selectedBtn
            }
          >
            <FontAwesomeIcon icon={faThumbsUp} style={{ marginRight: '5%' }} />
            <span>{LangData.previewModal.going}</span>
          </button>

          <button
            style={
              props.event.userRSVP === 'MAYBE'
                ? styles.selectedBtn
                : styles.notSelectedBtn
            }
            onClick={e => {
              e.stopPropagation();
              rsvp('MAYBE');
            }}
            {...maybeProps}
          >
            {LangData.previewModal.maybe}
          </button>
        </div>
      </div>
      <AttendingListView
        onRequestClose={() => props.onClose()}
        ended={props.ended}
        me={props.me}
        goingList={props.goingList}
        eventId={props.event.id}
        tabLabel={`${
          !props.ended
            ? LangData.liveModal.attending
            : LangData.liveModal.attended
        } '(0)'`}
      />
      {/* <ImageGalleryViewerModal
        onClose={() => closeImageViewer()}
        data={eventImageViewerData}
      /> */}
    </div>
  );
};
export default FeedEventPreviewModalView;

const styles = {
  containerModal: {
    padding: '10px',
    backgroundColor: 'white',
    margin: '2%',
    borderRadius: '20px',
    width: '90%'
  },
  innerContainer: {
    maxHeight: '340px',
    display: 'flex',
    flexDirection: 'column'
  },
  rowButtonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    display: 'flex',
    marginTop: '9%',
    width: '100%'
  },
  selectedBtn: {
    border: `2px solid ${Theme.SECONDARY_TEXT_COLOR}`,
    backgroundColor: Theme.SECONDARY_TEXT_COLOR,
    padding: '2% 5%',
    borderRadius: '20px',
    fontWeight: 'bold',
    fontFamily: 'inherit',
    display: 'flex',
    color: 'white',
    width: '25%',
    justifyContent: 'center'
  },
  notSelectedBtn: {
    flexDirection: 'row',
    color: Theme.TEXT_COLOR,
    border: `2px solid ${Theme.TEXT_COLOR}`,
    backgroundColor: 'white',
    padding: '2% 5%',
    borderRadius: '20px',
    fontWeight: 'bold',
    fontFamily: 'inherit',
    display: 'flex',
    width: '25%',
    justifyContent: 'center'
  }
};
