import React, { useState, Children, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

import { calendarToolbar } from "./calendarToolbar";
import './calendar.scss';

import {
  withStyles
} from "@material-ui/core/styles";

const localizer = momentLocalizer(moment)
const priorityIcons = [
  { icon: "simple-icon-badge", color: "black"},
  { icon: "simple-icon-badge", color: "#ffc107"},
  { icon: "simple-icon-badge", color: "#007bff"},
  { icon: "simple-icon-badge", color: "#28a745"},
]

const groupsIcons = [
  { icon: "iconsminds-student-male-female", color: "black"},
  { icon: "iconsminds-student-male-female", color: "#17a2b8"},
  { icon: "iconsminds-student-male-female", color: "#e83e8c"},
  { icon: "iconsminds-student-male-female", color: "#6610f2"},
]


const CURRENT_DATE = moment().toDate();

// example implementation of a wrapper
const ColoredDateCellWrapper = ({children, value}) => React.cloneElement(Children.only(children), {
        style: {
            // ...children.style,
            backgroundColor: value < CURRENT_DATE ? 'lightgreen' : 'lightblue',
        },
    });

const CalendarCard = (props) => {
  const [selectedInCalendar, setSelectedInCalendar] = useState(new Date())


  const handleSelectEvent = (event) => {
    setSelectedInCalendar(new Date(event.start))
    let newDate = moment(new Date(event.start)).format('MM/DD/YYYY')
    props.updateSelectedDate(newDate)
  }

  const dayStyleGetter = (day) => {
    return {
        style: {
            backgroundColor: 'red', //this works
            display: 'block',

        }
      }
  }

  const eventStyleGetter = (event) => {
    var backgroundColor = event.hexColor;
    var style = {
      backgroundColor,
      borderRadius: '0px',
      opacity: 0.8,
      color: 'black',
      border: '0px',
      display: 'block',
    };
    return {
     style
    };
  }


  const MyEvent = (event) => {
    return (
       <span>{event.title}</span>
    )
  }

  return (
   
        <Calendar
          localizer={localizer}
          selectable={true}
          onSelectSlot={(slotInfo) => console.log("click!", slotInfo)}
          onSelectEvent={handleSelectEvent}
          style={{ minHeight: "457px", cursor:'pointer' }}
          events={props.events}
          rtl={false}
          date={selectedInCalendar}
          onNavigate={(date, view, action) => {
            setSelectedInCalendar(new Date(date))
          } }
          views={["month"]}
          components={{
            toolbar: calendarToolbar,
            event: MyEvent,
            // dateCellWrapper: ColoredDateCellWrapper,
            month: {
              dateHeader: ({date, label}) => {   
                return (
                  <a>{label}</a>
                  
                ) 
              }
            }
          }}
          eventPropGetter={eventStyleGetter}
          dayPropGetter={dayStyleGetter}
        />
  );
};




export default CalendarCard;
