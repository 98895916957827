import React, { useState, useEffect, Fragment } from 'react';
import { LangData } from '../../../assets/locales/localization';
import { ClassmateItem } from './classmate-item.rn';
import ErrorMsg from '../../../common/components/error-msg.rn';
import LoadingSpinner from '../../../common/components/loading-spinner.rn';
import EmptyNotice from '../../../common/components/empty-notice.rn';
import { Theme } from '../../../common/providers/colors';

const width = window.innerWidth

const ClassmateGrid = props => {
  const [data, setData] = useState([]);
  const [loadMore, setLoadMore] = useState(true);
  const [selectedClassmate, setSelectedClassmate] = useState({});

  useEffect(() => {
    if (props.data) {
      const classmates = props.data.classmates || [];
      setData(classmates);

      const filtered = classmates.filter(classmate =>
        [classmate.name, classmate.username].some(text =>
          text.toLowerCase().includes((props.filterTerm || '').toLowerCase())
        )
      );
      setData(filtered);
      if (props.data.classmates && props.data.classmates.length % 10 !== 0) {
        setLoadMore(false);
      }
    }
  }, [props]);

  function _renderError() {
    return <ErrorMsg message={ErrorMsg.LOADING_MSG} />;
  }

  function _renderLoadingSpinner() {
    return <LoadingSpinner bgcolor={'rgba(0, 0, 0, 0.2)'} />;
  }

  const isBottom = (element) => {
    return element.scrollHeight - element.scrollTop === element.clientHeight
  }

  function handleScroll(e) {
    const wrappedElement = document.getElementById('scrollDiv');
    let bottom = isBottom(wrappedElement)
    if (bottom) {
      props.handleOnScroll();

    }
  }

  function renderClassmateList() {
    const checkedArr = props.checkedItems || [];

    if (data.length === 0 && props.filterTerm !== '') {
      return (
        <div>
          <p />
        </div>
      );
    }

    return (
      <div
        id="scrollDiv"
        onScroll={e => handleScroll(e)}
        style={{
          overflowY: 'scroll',
          width: '100%',
          height: width < 500 ? window.innerHeight * 0.66 : '50vh',
          paddingLeft: '15%'
        }}
      >
        {data.map(item => {
          return (
            <ClassmateItem
              key={item.id}
              isRelative={false}
              checked={!!checkedArr.find(user => user.id === item.id)}
              minimal={props.minimal}
              classmate={item}
              onItemClick={classmate => {
                if (props.origin === 'findFriends') {
                  props.onItemPress(classmate);
                } else {
                  if (props.validateModalOpen) {
                    if (!props.validateModalOpen(classmate)) {
                      return;
                    } else {
                      props.onItemPress(classmate);
                      props.toggleComponent('UserProfile');
                      props.history.push('userProfile');
                    }
                  }
                }
              }}
            />
          );
        })}
      </div>
    );
  }

  function renderMsg() {
    return (
      <EmptyNotice
        text={LangData.classmateGrid.sorry}
        textColor={Theme.SECONDARY_TEXT_COLOR}
        titleColor={Theme.TEXT_COLOR}
        title={LangData.classmateGrid.noFound}
      />
    );
  }

  return (
    <Fragment>
      {!props.data ? null : props.data && props.data.loading ? (
        _renderLoadingSpinner()
      ) : props.data && props.data.error ? (
        _renderError()
      ) : data.length === 0 ? (
        renderMsg()
      ) : (
        <div style={styles.classMates}>{renderClassmateList()}</div>
      )}
    </Fragment>
  );
};

export default ClassmateGrid;

const styles = {
  classMates: {
    marginTop: '3%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center'
  }
};
