import React, { Component } from 'react';
import { NewChat } from './new-chat.container';

export class NewChatModal extends Component {
  render() {
    return (
      <NewChat {...this.props} />
    );
  }
}
