import { NotificationsListView } from './notifications-list.rn'
import { graphql, withApollo } from 'react-apollo-temp'
import { myNotificationsQuery } from '../../graphql/notifications.query'
import update from 'immutability-helper'
import { compose } from 'redux'
import { setNotificationsReadStateMutation } from '../../graphql/notify-read-state.mutation'
import { toggleUserProfileModal } from '../../../profile/profile.actions'
import { connect } from 'react-redux'
import { withErrorHandler } from '../../../common/hoc/error-handler.rn'
import { toggleComponent } from '../../../router/router.actions'
import { closeNotificationsListModal } from '../../notifications.actions'

const withSetNotificationsReadState = graphql(setNotificationsReadStateMutation, {
  props: ({ ownProps, mutate }) => ({
    notifyReadStatus: (userId, notificationId) => {
      // console.log('In notify read status', notificationId, userId)
      mutate({
        variables: {
          userId,
          notificationId,
        },
        refetchQueries: [{ query: myNotificationsQuery, variables: { userId: userId, cursor: null } }],
        // update: (store, { data: setNotificationsReadState }) => {
        //   store.writeQuery({
        //     query: unreadNotificationCountQuery,
        //     variables: {},
        //     data: {
        //       unreadNotificationsCount:
        //         setNotificationsReadState.setNotificationsReadState
        //     }
        //   });
        // }
      })
    },
  }),
})

const withMyNotifications = graphql(myNotificationsQuery, {
  name: 'notifications',
  options: (props) => {
    return {
      variables: {
        userId: props.currentUser ? props.currentUser.id : null,
        cursor: null,
      },
      // fetchPolicy: 'network-only',
    }
  },
  props: ({ notifications }) => {
    // const now = Date.now()
    let currentUser = notifications.variables.userId
    const loadOlder = () => {
      if (
        notifications &&
        notifications.notifications &&
        notifications.notifications.cursor &&
        notifications.notifications.hasMore
      ) {
        // console.log('IN LOADOLDER')
        notifications.fetchMore({
          query: myNotificationsQuery,
          variables: {
            cursor: notifications.notifications.cursor,
            userId: currentUser ? currentUser : null,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            // console.log('loadOlder Result', fetchMoreResult)
            if (fetchMoreResult.notifications && fetchMoreResult.notifications.items) {
              const target =
                prev && prev.notifications
                  ? prev
                  : {
                      notifications: { items: [], hasMore: false, cursor: null },
                    }

              return update(target, {
                notifications: {
                  cursor: { $set: fetchMoreResult.notifications.cursor },
                  hasMore: { $set: fetchMoreResult.notifications.hasMore },
                  items: {
                    $push: Array.from(fetchMoreResult.notifications.items),
                  },
                },
              })
            }

            return prev
          },
        })
      }
    }

    const reload = () =>
      notifications.refetch({
        cursor: null,
      })

    if (!notifications) {
      return {
        loading: false,
        notifications: [],
        loadOlder,
        reload,
      }
    }

    // console.log(notifications)

    return {
      loading: notifications.networkStatus === 1,
      notifications:
        notifications && notifications.notifications && notifications.notifications.items
          ? notifications.notifications.items
          : [],
      loadOlder,
      reload,
      time: Date.now(),
    }
  },
})

const withError = withErrorHandler({
  apollo: ['notifications'],
})

const mapStateToProps = ({ navigation, profile, auth }) => ({
  currentComponent: navigation.navigationComponents.component,
  prevComponent: navigation.navigationComponents.prevComponent,
  profile: profile.userProfileModal,
  currentUser: auth.currentUser,
})

const mapDispatchToProps = (dispatch) => ({
  toggleModal: (isOpen, userId) => dispatch(toggleUserProfileModal(isOpen, userId)),
  toggleComponent: (component) => dispatch(toggleComponent(component)),
  closeNotifyModal: () => dispatch(closeNotificationsListModal())
})

const withState = connect(mapStateToProps, mapDispatchToProps, null)

export const NotificationsList = compose(
  withMyNotifications,
  withState,
  withSetNotificationsReadState,
  withError,
  withApollo
)(NotificationsListView)
