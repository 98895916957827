export const EChatActions = {
  SET_SEARCH_TERM: 'chat/SET_SEARCH_TERM',
  CHAT_NAVIGATE: 'chat/NAVIGATE_TO_CHAT',
  CHAT_WINDOWED: 'chat/CHAT_WINDOWED',
  NAVIGATE_TO_FRIENDS_LIST: 'chat/NAVIGATE_TO_FRIENDS_LIST',
  NAVIGATE_TO_PROFILE_PAGE: 'chat/NAVIGATE_TO_PROFILE_PAGE',
  OPEN_CREATE_CHAT_MODAL: 'chat/OPEN_CREATE_CHAT_MODAL',
  CLOSE_CREATE_CHAT_MODAL: 'chat/CLOSE_CREATE_CHAT_MODAL',
  OPEN_CHAT_LIST_MODAL: 'chat/OPEN_CHAT_LIST_MODAL',
  CLOSE_CHAT_LIST_MODAL: 'chat/CLOSE_CHAT_LIST_MODAL',
};

export const setSearchTerm = search => ({
  type: EChatActions.SET_SEARCH_TERM,
  search,
});

export const chatNavigate = chat => ({
  type: EChatActions.CHAT_NAVIGATE,
  chat,
});

export const chatWindowed = user => ({
  type: EChatActions.CHAT_WINDOWED,
  user,
})

export const chatWithUser = user => ({
  type: EChatActions.CHAT_NAVIGATE,
  user,
});

export const navigateToFriendsList = toPendingRequests => ({
  type: EChatActions.NAVIGATE_TO_FRIENDS_LIST,
  toPendingRequests,
});


export const navigateToProfilePage = () => ({
  type: EChatActions.NAVIGATE_TO_PROFILE_PAGE,
});

export const openCreateChatModal = () => ({
  type: EChatActions.OPEN_CREATE_CHAT_MODAL,
})

export const closeCreateChatModal = () => ({
  type: EChatActions.CLOSE_CREATE_CHAT_MODAL,
})

export const openChatListModal = () => ({
  type: EChatActions.OPEN_CHAT_LIST_MODAL,
})

export const closeChatListModal = () => ({
  type: EChatActions.CLOSE_CHAT_LIST_MODAL,
})
