import { connect } from 'react-redux';
import MainView from './main-page-view';
import { toggleComponent } from '../router/router.actions';
import { setUser } from '../auth/auth.actions';
import { compose, graphql } from 'react-apollo-temp';
import { setAnswerOpenQeustionMutation } from './graphql/set-answer-open-ended-question';
import { notifyChatReadStatusMutation } from '../chat/graphql/notify-chat-read-status.mutation';
import gql from 'graphql-tag';
import { totalUnreadCountQuery } from '../chat/graphql/unread-count.query';
import { chatNavigate } from '../chat/chat.actions';
import {
  withUnreadNotificationsCount
} from "../my-notifications/components/hoc/with-unread-notifications-count.container";
import { withUnreadChatMessagesCount } from "../chat/components/hoc/withUnreadChatMessagesCount";


const mapStateToProps = ({ navigation, auth, chat }) => ({
  currentComponent: navigation.navigationComponents.component,
  profilePictureUrl: auth.currentUser
    ? auth.currentUser.profilePictureUrl
    : null,
  currentUser: auth.currentUser,
  chat: chat.currentChat,
});

const mapDispatchToProps = dispatch => ({
  toggleComponent: component => dispatch(toggleComponent(component)),
  setUser: user => dispatch(setUser(user)),
  setChat: chat => dispatch(chatNavigate(chat)),
});

const withAnswerOpenQuestion = graphql(setAnswerOpenQeustionMutation, {
  props: ({ ownProps, mutate }) => ({
    answerOpenEndedQuestion: (userId, questionText, answerText) =>
      mutate({
        variables: {
          userId,
          questionText,
          answerText
        }
      })
  })
});

const withState = connect(mapStateToProps, mapDispatchToProps, null);

const withNotifyChatReadStatus = graphql(notifyChatReadStatusMutation, {
  props: ({ ownProps, mutate }) => ({
    notifyChatReadStatus: (userId, chatId, messageId) =>
      mutate({
        variables: {
          userId,
          chatId,
          messageId
        },
        update: (store, { data: notifyChatReadStatus }) => {
          try {
            const chatFragment = gql`
              fragment ChatFields on Chat {
                id
                unreadMessagesCount
              }
            `;
            const identifier = `Chat:${chatId}`;

            const chatState = store.readFragment({
              fragment: chatFragment,
              id: identifier
            });

            chatState.unreadMessagesCount =
              notifyChatReadStatus.notifyChatReadStatus.chat.unreadMessagesCount;

            store.writeFragment({
              fragment: chatFragment,
              id: identifier,
              data: chatState
            });
          } catch (e) {
            // nothing to do here
          }

          try {
            const unreadState = store.readQuery({
              query: totalUnreadCountQuery,
              variables: {}
            });

            unreadState.unreadChatMessagesCount =
              notifyChatReadStatus.notifyChatReadStatus.unreadChatMessagesCount;

            store.writeQuery({
              query: totalUnreadCountQuery,
              variables: {},
              data: unreadState
            });
          } catch (e) {
            // nothing to do here
          }
        }
      })
  })
});

export const Main = compose(
  withState,
  // withAnswerOpenQuestion,
  withNotifyChatReadStatus,
  withUnreadNotificationsCount,
  withUnreadChatMessagesCount
)(MainView);
