import { SettingsView } from './settings.rn';
import { connect } from 'react-redux';
import { updatePrivacy } from '../profile/profile.actions';
import { compose } from 'redux';
import { withApollo } from 'react-apollo-temp';
import { reloadUserProfile, setUser } from '../auth/auth.actions';
import { toggleComponent } from '../router/router.actions';

const mapStateToProps = ({
  snapchat,
  twitter,
  profile,
  main,
  auth,
  instagram,
  navigation
}) => ({
  privacySettings: profile.privacySettings,
  appVersion: '2.0-22',
  user: auth.currentUser,
  currentUser: auth.currentUser,
  isInstagramLoading: instagram.isDuringAuthentication,
  isSnapchatLoading: snapchat.isDuringEnrollment,
  isTwitterLoading: twitter.isDuringAuthentication,
  currentComponent: navigation.navigationComponents.component,
  prevComponent: navigation.navigationComponents.prevComponent
});

const mapDispatchToProps = dispatch => ({
  updatePrivacy: (privacy,userId, newPrivacyConfig) =>
    dispatch(updatePrivacy(privacy,userId, newPrivacyConfig, true)),
  updatePrivacyLocally: (privacy, userId, newPrivacyConfig) =>
    dispatch(updatePrivacy(privacy, userId, newPrivacyConfig, false)),
  reloadUser: () => dispatch(reloadUserProfile()),
  setUser: user => dispatch(setUser(user)),
  toggleComponent: component => dispatch(toggleComponent(component))
});

const withState = connect(mapStateToProps, mapDispatchToProps);

export const Settings = compose(
  withState,

  withApollo
)(SettingsView);
