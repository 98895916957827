import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBell as farBell,
  faEnvelope as farEnvelope,
  faAddressCard as farAddressCard,
  faSmile as farmile,
} from '@fortawesome/free-regular-svg-icons';
import {
  faMusic
} from '@fortawesome/free-solid-svg-icons';
import { faPalette } from '@fortawesome/free-solid-svg-icons';
import {
  faInstagram,
  faSnapchatGhost,
  faTwitter,
  faFacebookF,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import { Theme } from '../common/providers/colors';
const width=window.innerWidth

export class SettingsToggleItem extends Component {
  static propTypes = {
    icon: PropTypes.string,
    hideToggle: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    visible: PropTypes.bool,
    active: PropTypes.bool,
    children: PropTypes.node
  };

  returnIcon() {
    if (this.props.icon === 'reminder') return farBell;
    else if (this.props.icon === 'picture') return farmile;
    else if (this.props.icon === 'bell') return faMusic;
    else if (this.props.icon === 'info') return farAddressCard;
    else if (this.props.icon === 'email') return farEnvelope;
    else if (this.props.icon === 'palette') return faPalette;
    else if (this.props.icon === 'Instagram') return faInstagram;
    else if (this.props.icon === 'Snapchat') return faSnapchatGhost;
    else if (this.props.icon === 'Twitter') return faTwitter;
    else if (this.props.icon === 'Facebook') return faFacebookF;
    else if (this.props.icon === 'Linkedin') return faLinkedin;
  }

  render() {
    return (
      <div style={styles.container}>
        <div style={styles.iconContainer}>
          <FontAwesomeIcon
            icon={this.returnIcon()}
            style={
              this.props.settingSocialIcon
                ? styles.settingSocialIcon
                : styles.settingIcon
            }
          />
        </div>
        <div style={styles.childContainer}>{this.props.children}</div>
        {!this.props.hideToggle ? (
          <div style={styles.toggleContainer}>
            <label
              style={this.props.active ? styles.switchChecked : styles.switch}
            >
              <input
                type='checkbox'
                style={styles.checkboxInput}
                value={this.props.active}
                onChange={event => this.props.onChange(event.target.checked)}
              />
              <span
                style={this.props.active ? styles.slider : styles.sliderBefore}
              ></span>
            </label>
          </div>
        ) : (
          <div style={styles.toggleContainer}>
            <button
              style={styles.buttonChange}
              onClick={() => this.props.onColorChange()}
            >
              Change
            </button>
          </div>
        )}
      </div>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    margin: '3% 0 3% 0'
  },
  iconContainer: {
    flex: 0.1,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  },
  childContainer: {
    // flex: width < 500 ? 0.7 : 'none',
    // width: width < 500 ? 'auto' : '400px',
    // marginRight: width < 500 ? '0' : '20px',
    flex: 0.7,
    alignItems: 'stretch',
    justifyContent: 'center'
  },
  toggleContainer: {
    flex: 0.2,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  settingIcon: {
    color: Theme.TEXT_COLOR,
    fontSize: width<500?window.innerWidth / 18 + 'px':'50px',
    marginRight: window.innerWidth / 36 + 'px'
  },
  settingSocialIcon: {
    color: '2665B1',
    fontSize: window.innerWidth / 18 + 'px',
    marginRight: window.innerWidth / 36 + 'px'
  },
  switch: {
    position: 'relative',
    display: 'inline-block',
    width: width<500?window.innerWidth / 7.826 + 'px':'50px',
    height: width<500?window.innerWidth / 15.652 + 'px':'25px',
    borderRadius: window.innerWidth / 18 + 'px',
    backgroundColor: Theme.SECONDARY_TEXT_COLOR
  },
  switchChecked: {
    position: 'relative',
    display: 'inline-block',
    width: width<500?window.innerWidth / 7.826 + 'px':'50px',
    height: width<500?window.innerWidth / 15.652 + 'px':'25px',
    borderRadius: window.innerWidth / 18 + 'px',
    backgroundColor: Theme.SECONDARY_TEXT_COLOR
  },
  checkboxInput: {
    opacity: '0',
    width: '0',
    height: '0'
  },
  sliderBefore: {
    position: 'absolute',
    content: '""',
    height: width<500?window.innerWidth / 18.947 + 'px':'21px',
    width: width<500?window.innerWidth / 18.947 + 'px':'21px',
    left: width<500?window.innerWidth / 360 + 'px':'2px',
    bottom: width<500?window.innerWidth / 180 + 'px':'2px',
    backgroundColor: 'white',
    transition: 'all 0.4s ease 0s',
    borderRadius: '50%'
  },
  slider: {
    position: 'absolute',
    content: '""',
    height: width<500?window.innerWidth / 18.947 + 'px':'21px',
    width: width<500?window.innerWidth / 18.947 + 'px':'21px',
    left: width<500?window.innerWidth / -360 + 'px':'0px',
    bottom: width<500?window.innerWidth / 180 + 'px':'2px',
    backgroundColor: 'white',
    transition: 'all 0.4s ease 0s',
    borderRadius: window.innerWidth / 10.588 + 'px',
    transform: 'translateX(26px)'
  },
  buttonChange: {
    color: 'white',
    fontSize: width<500?'12px':'medium',
    border: 'none',
    backgroundColor: Theme.SECONDARY_TEXT_COLOR,
    padding: width<500?'10%':'5%',
    borderRadius: '5px',
    width: 'fit-content',
    fontFamily: 'inherit'
  }
};
