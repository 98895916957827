import { connect } from 'react-redux';
import ProfileModalView from './profile-modal';
import { toggleProfileModal } from '../profile.actions';
//import { navigateToChild } from '../../main/main.actions';
import { reportError } from '../../bugsnag';
import { loggingOut } from '../../login/login.reducer';
// import FCM from 'react-native-fcm';
import { compose } from 'redux';
import { logout } from '../../account/account.util';
import { toggleComponent } from '../../router/router.actions';
import { setUser } from '../../account/account.actions';

const withStatelessActions = connect(
  ({ userProfileNavigationReducer }) => ({
    activeScreen: 'UserProfile'
  }),
  dispatch => ({
    // navigateToScreen: screenName => dispatch(navigateToChild(screenName)),
    logout: async () => {
      dispatch(loggingOut());
      localStorage.clear();
      // try {
      //   const token = await FCM.getFCMToken();
      //   const client = getClient();

      //   if (token) {
      //     client
      //       .mutate({
      //         mutation: gql`
      //           mutation removeFcmToken($fcmToken: String) {
      //             removeUserFcmToken(fcmToken: $fcmToken)
      //           }
      //         `,
      //         variables: { fcmToken: token },
      //       })
      //       .catch(() => null);
      //   }
      // } catch (e) {
      //   reportError(e);
      // }

      try {
        await logout();
      } catch (e) {
        reportError(e);
      }
    }
  })
);

const mapStateToProps = ({ navigation, profile, auth }) => ({
  currentComponent: navigation.navigationComponents.component,
  prevComponent: navigation.navigationComponents.prevComponent,
  isOpen: profile.isProfileModalOpen,
  currentUser: auth.currentUser
});

const mapDispatchToProps = dispatch => ({
  toggleComponent: component => dispatch(toggleComponent(component)),
  toggleModal: isOpen => dispatch(toggleProfileModal(isOpen)),
  setUser: user => dispatch(setUser(user))
});

const withState = connect(mapStateToProps, mapDispatchToProps, null);

export const StatelessProfileModal = withStatelessActions(ProfileModalView);
export const ProfileModal = compose(
  withStatelessActions,
  withState
)(ProfileModalView);
