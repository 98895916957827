import { NewChatView } from './new-chat.rn';
import { chatNavigate, chatWindowed, closeCreateChatModal } from '../../chat.actions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withCreateChatMutation } from '../../../common/components/create-new-chat.container';
import { toggleComponent } from '../../../router/router.actions';
import { openWindowedChat } from "../../../windowedChat/windowedChat.actions";

const mapDispatch = dispatch => ({
  navigateToChat: chat => dispatch(chatNavigate(chat)),
  toggleComponent: component => dispatch(toggleComponent(component)),
  openChat: () => dispatch(openWindowedChat()),
  chatWindowed: user => dispatch(chatWindowed(user)),
  closeCreateChatModal: () => dispatch(closeCreateChatModal())
});

const mapStateToProp = ({ navigation, auth }) => ({
  currentComponent: navigation.navigationComponents.component,
  prevComponent: navigation.navigationComponents.prevComponent,
  currentUser: auth.currentUser,
});

const withState = connect(mapStateToProp, mapDispatch);

export const NewChat = compose(withState, withCreateChatMutation)(NewChatView);
