import { FindFriendsForChatView } from './find-friends.rn';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withMyConnections } from '../../../friends/components/friends-grid.container';
import { navigateToChild } from '../../../main/main.actions';
import { toggleComponent } from '../../../router/router.actions';
import { closeCreateChatModal } from "../../chat.actions";

const mapDispatch = dispatch => ({
  navigateToChild: routeName => dispatch(navigateToChild(routeName)),
  toggleComponent: component => dispatch(toggleComponent(component)),
  closeCreateChatModal: () => dispatch(closeCreateChatModal())
});

const withState = connect(null, mapDispatch);

export const FindFriendsForChat = compose(
  withState,
  withMyConnections
)(FindFriendsForChatView);
