import React, { useEffect, useState } from 'react';
import FHimage from '../assets/images/LogoDark.png';
import { useReactPWAInstall } from "react-pwa-install";
import './install-app.scss'
import { isAndroid, isIOS } from 'react-device-detect';
import { usePWAInstall } from 'react-use-pwa-install'



function InstallAppView(props) {
  const { pwaInstall, supported, isInstalled } = useReactPWAInstall();
  const [promptInstall, setPromptInstall] = useState(null);
  const install = usePWAInstall()

  useEffect(() => {
    const handler = e => {
      e.preventDefault();
      console.log("we are being triggered :D");
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  useEffect(() => {
    props.setShowInstallPage(true);
    if(!supported() || isInstalled()) {
      props.setShowInstallPage(false);
      props.history.push('login');
    }
  }, [supported, isInstalled, props]);

  const DidMount = () => {
    let deferredPrompt;


    window.addEventListener('beforeinstallprompt', function (e) {
      e.preventDefault();
      deferredPrompt = e;
    });
    console.log('supported: ', supported())

    if(supported()) {
      document.getElementById("install-btn").click()
    }
  };

  useEffect(DidMount, [supported]);

 
  const handleClick = () => {

    pwaInstall({
      title: "Install Yocas",
      features: (
      <div>
        <p style={styles.description}>
          Please note, we recommend installing this app on your device
        </p>
        <p style={styles.description}>
          You may want to install it on your device, so you won’t have to login everytime
        </p>
       </div>
      ),
    })
    .then(() => {
      if(isIOS || isAndroid) {
        props.setShowInstallPage(false);
        props.history.push('login');
        if (!promptInstall) {
          return;
        }
        promptInstall.prompt();
      }
    })
    .catch(() => {
      props.setShowInstallPage(false);
      props.history.push('login');
    });
  };

  return (
    <div>
      <div style={styles.mainContainer}>
        <img src={FHimage} style={styles.imageLogo} alt=""/>
        <div style={styles.optionsRow}>
          <button
            aria-label="Install app"
            title="Install app"
            style={styles.button}
            id="install-btn"
            onClick={handleClick}
            hidden
          >
          </button>
          {install && <button onClick={install}>My test install app button</button>}
        </div>
      </div>
    </div>
  );
}
export default InstallAppView;

const styles = {
  mainContainer: {
    height: window.innerHeight,
    width: window.innerWidth,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color:'#4D4B62',
    backgroundImage: 'linear-gradient(to bottom, rgb(231,228,216,0.95), rgb(237,204,181,0.95))'
  },
  description: {
    fontSize: '15px',
    fontWeight: 'bold',
    textAlign: 'center',
    margin: '3%',
    marginBottom: '7%',
    padding: '2%'
  },
  optionsRow: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  button: {
    color: '#E1C2B0',
    fontWeight: 'bold',
    fontFamily: 'inherit',
    marginTop: '5%',
    fontSize: '17px',
    backgroundColor: '#4D4B62',
    border: 'none',
    padding: '3%',
    borderRadius: '50px',
    marginBottom: '3%',
    width: '45%'
  },
  imageLogo: {
    width: '60%'
  }
};
