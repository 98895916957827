import React, { Fragment } from 'react';
import { openImgModal, toggleComponent } from '../../router/router.actions';
import { connect } from 'react-redux';
export const PROFILE_IMAGE_SIZE = 75;
export const DEFAULT_IMAGE = require('../../assets/images/default_profile.jpeg');

const ProfileImage = props => {

  const openImageModal = imageUrl => {
    props.openImgModal(imageUrl);
    if (props.history) {
      props.history.push('/fullScreenImageModal');
    }
    props.toggleComponent('FullScreenImageModal');
  };

  const isDefaultImage = () => {
    return !props.imageUrl;
  };

  const getImage = (fullSize = false) => {
    if (props.imageUrl) {
      // if (fullSize) {
      //   return props.fullSizeUrl;
      // }

      return props.imageUrl;
    }

    return DEFAULT_IMAGE;
  };

  // const toggleFullSize = show => {
  //   setIsFullSizeOpen(show);
  // };
  //
  // let sizeStyle = {};
  //
  // if (props.size) {
  //   sizeStyle = {
  //     borderRadius: props.size / 2,
  //     width: props.size,
  //     height: props.size
  //   };
  // }

  return (
    <Fragment>
      {props.withFullSize && !isDefaultImage() && props.fullSizeUrl ? (
        <div
          onClick={e => {
            e.stopPropagation();
            openImageModal(props.fullSizeUrl);
          }}
          style={{ width: '100%', height: '100%' }}
        >
          <img
            alt="profile"
            style={
              props.height
                ? {
                    height: props.height,
                    width: props.width,
                    borderRadius: '50%'
                  }
                : styles.defaultImage
            }
            src={getImage()}
          />
        </div>
      ) : (
        <img
          alt="profile"
          style={
            props.height
              ? {
                  height: props.height,
                  width: props.width,
                  borderRadius: '50%',
                  objectFit: 'cover'
                }
              : styles.defaultImage
          }
          src={getImage()}
          onClick={() => {
            if (props.onClick) props.onClick();
          }}
        />
      )}
    </Fragment>
  );
};

const mapDispatchToProps = dispatch => ({
  openImgModal: img => dispatch(openImgModal(img)),
  toggleComponent: component => dispatch(toggleComponent(component))
});

const mapStateToProps = ({ navigation }) => ({
  currentComponent: navigation.navigationComponents.component,
  prevComponent: navigation.navigationComponents.prevComponent
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileImage);

const styles = {
  defaultImage: {
    overflow: 'hidden',
    borderRadius: '50%',
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  }
};
