import React, { useEffect } from 'react';
import Navigation from './router/navigation';
import { ApolloProvider } from 'react-apollo';
import { Provider } from 'react-redux';
import { store } from './store';
import { CONTINUE_SESSION } from './login/login.reducer';
import {
  isIOS,
} from 'react-device-detect';
import { setAppVersion } from './main/main.actions';
import { version, ENV } from './env';
import { AppVersionMiddleware } from './core/network/app-version-middleware';
import { HIDE_NEW_BADGE } from './core/constants/storage';
import { setReadIndicationForItem } from './news-feed/news-feed.actions';
import { analytics } from './analytics/report';
import { refreshSession, getUser } from './account/account.util';
import { client } from './account/account.init';
import { apolloClient } from './apollo-subs';
import { loggingIn } from './account/account.actions';
import './App.css';
import ReactPWAInstallProvider from "react-pwa-install";
import { setUser } from './auth/auth.actions';

function App(props) {

  useEffect(() => {
    console.disableYellowBox = true;
    const appVersion = version;
    const localVersion = localStorage.getItem('version');
    const userIdFromLocalStorage = localStorage.getItem('userId');
    if (appVersion !== localVersion) {
      localStorage.clear();
      localStorage.setItem('version', appVersion);
      if (userIdFromLocalStorage) {
        localStorage.setItem('userId', userIdFromLocalStorage);
      }
      if ('caches' in window) {
        caches.keys().then((names) => {
          // Delete all the cache files
          names.forEach(name => {
            caches.delete(name);
          });
        });
      }
      window.location.reload();
    }
  }, [])

  useEffect(() => {
    if (!isIOS) {
      Notification.requestPermission(function (status) {
        console.log('Notification permission status:', status);
      });
    }

    async function initializeApp() {
      try {
        await refreshSession();
        let user = await getUser();
        analytics.setUser(user);
        store.dispatch(setUser(user));
        analytics.start(15 * 1000, client);
      } catch (e) {
        store.dispatch(loggingIn(false));
      }
    }

    initializeApp();
    store.dispatch(CONTINUE_SESSION);

    // I18nManager.forceRTL(true)

    let appVersionIdentifier = '';

    try {
      AppVersionMiddleware.setVersion('2.0-22');

      if (ENV === 'staging') {
        appVersionIdentifier = `staging (${appVersionIdentifier})`;
      }

      store.dispatch(setAppVersion('2.0-22'));

      async function existingIndicationsFunction() {
        const existingIndications = localStorage.getItem(HIDE_NEW_BADGE);
        store.dispatch(
          setReadIndicationForItem(
            existingIndications ? JSON.parse(existingIndications) : {}
          )
        );
      }

      existingIndicationsFunction();
    } catch (e) {
      // nothing to do
    }
  }, []);

  return (
    <ReactPWAInstallProvider enableLogging>
      <Provider store={store}>
        <ApolloProvider client={apolloClient}>
            <Navigation/>
        </ApolloProvider>
      </Provider>
    </ReactPWAInstallProvider>

  );
}

export default App;
