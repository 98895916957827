import { combineReducers } from 'redux';
import { EChatActions } from './chat.actions';
import { EMainActions } from '../main/main.actions';

export const selectIsCreateChatModalOpen = state => state.chat.createChatModal.isOpen;
export const selectIsChatListModalOpen = state => state.chat.chatListModal.isOpen;

function searchTerm(state = '', action) {
  switch (action.type) {
    case EMainActions.NAVIGATE_CHILD:
    case EMainActions.NAVIGATE_PARENT:
    case EChatActions.CHAT_NAVIGATE:
      return '';
    case EChatActions.SET_SEARCH_TERM:
      return action.search;
    default:
      return state;
  }
}

function currentChat(state = null, action) {
  switch (action.type) {
    case EMainActions.CLOSE_ALL_MODALS:
      return null;
    case EMainActions.NAVIGATE_CHILD: {
      if (action.routeName === 'FriendsList') {
        return null;
      }

      return state;
    }
    case EChatActions.NAVIGATE_TO_PROFILE_PAGE:
    case EChatActions.NAVIGATE_TO_FRIENDS_LIST:
      return null;
    case EChatActions.CHAT_WINDOWED:
      return {
        id: null,
        users: [
          action.user
        ],
      }
    case EChatActions.CHAT_NAVIGATE: {
      if (action.chat) {
        return action.chat;
      } else if (action.user) {
        const userId = action.user.id;
        const currentChatUserId = state !== null && state.users.length === 1 ? state.users[0].id : null;
        if (userId === currentChatUserId) {
          return state;
        }
        return {
          id: null,
          users: [
            action.user,
          ],
        };
      }
      return null;
    }
    default:
      return state;
  }
}

function createChatModal(state = { isOpen: false }, action) {
  switch (action.type) {
    case EChatActions.OPEN_CREATE_CHAT_MODAL:
      return { ...state, isOpen: true }
    case EChatActions.CLOSE_CREATE_CHAT_MODAL:
      return { ...state, isOpen: false }
    default:
      return state
  }
}

function chatListModal(state = { isOpen: false }, action) {
  switch (action.type) {
    case EChatActions.OPEN_CHAT_LIST_MODAL:
      return { ...state, isOpen: true }
    case EChatActions.CLOSE_CHAT_LIST_MODAL:
      return { ...state, isOpen: false }
    default:
      return state
  }
}

export const chat = combineReducers({
  searchTerm,
  currentChat,
  createChatModal,
  chatListModal,
});
