import React, { useState, useEffect, Fragment, useCallback } from 'react'
import { Schedule } from '../schedule-view/components/schedule.container'
import { Redirect } from 'react-router'
import './main-page-view.scss'
import { Menu } from '../menu/menu.container'
import { Theme } from '../common/providers/colors'
import logoSrc from '../assets/images/LogoDark.png'
import ProfileImage from '../common/components/profile-image.rn'
import { refreshUser } from '../account/account.util'
import BurgerMenu from '../burgerMenu/BurgerMenu'
import notificationsLogoLight from '../assets/images/notificationsLogoLight.png'
import friendsLogoLight from '../assets/images/friendsLogoLight.png'
import homeLight from '../assets/images/homeLight.png'
import chatsLogoLight from '../assets/images/chatsLogoLight.png'
import plusLogoLight from '../assets/images/plusLogoLight.png'
import closeLogoLight from '../assets/images/closeLogoLight.png'
import { getClient } from '../apollo'
import { userTalkedWithYogi } from './graphql/userTalkedWithYogi'
import { userCompletedFirstLogin } from './graphql/userCompletedFirstLogin'
import yogiBlob from '../assets/images/yogiBlob.png'
import { NotificationAddedSubscription } from '../my-notifications/graphql/notification-added.subscription'
import { apolloClient } from '../apollo-subs'
import { friendAddedSubscription } from '../friends/graphql/friend-added.subscription'
import { chatAddedSubscription } from '../chat/graphql/chat-added.subscription'
import { chatMessageAddedSubscription } from '../chat/graphql/message-added.subscription'
import { GeneralComponent } from './general-page-container'
import chatsLogo from '../assets/images/chatsLogo.png'
import notificationsLogo from '../assets/images/notificationsLogo.png'
import './main-page-view.scss'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsChatListModalOpen } from '../chat/chat.reducer'
import { closeChatListModal, openChatListModal } from '../chat/chat.actions'
import { closeNotificationsListModal, openNotificationsListModal } from '../my-notifications/notifications.actions'
import { selectIsNotificationsListModalOpen } from '../my-notifications/notifications.reducers'
import { ChatsModal } from '../chat/components/chats-modal'
import { MyNotificationsModal } from '../my-notifications/components/my-notifications-modal'

const width = window.innerWidth

function HeaderHOC(props) {
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [countBubble, setCountBubble] = useState(0)
  const [countChatBubble, setCountChatBubble] = useState(0)
  const isChatListModalOpen = useSelector(selectIsChatListModalOpen)
  const isNotificationsListOpen = useSelector(selectIsNotificationsListModalOpen)

  const toggleNotificationsListModal = useCallback(() => {
    setCountBubble(0)
    if (isNotificationsListOpen) {
      dispatch(closeNotificationsListModal())
    } else {
      console.log('open notifications', Date.now())
      dispatch(openNotificationsListModal())
      if (isChatListModalOpen) {
        dispatch(closeChatListModal())
      }
    }
  }, [dispatch, isChatListModalOpen, isNotificationsListOpen])

  const toggleChatListModal = useCallback(() => {
    if (isChatListModalOpen) {
      dispatch(closeChatListModal())
    } else {
      dispatch(openChatListModal())
      if (isNotificationsListOpen) {
        dispatch(closeNotificationsListModal())
      }
    }
  }, [dispatch, isChatListModalOpen, isNotificationsListOpen])

  const refreshUserFunction = async () => {
    let user = await refreshUser()
    if (user) {
      props.setUser(user)
    }
  }

  return (
    <>
      <div style={styles.topNav}>
        {/* <div
          onClick={() => {
            setIsMenuOpen(!isMenuOpen)
          }}
          style={styles.menu}
        >
          <BurgerMenu /> */}
          {/* <FontAwesomeIcon icon={faBars} /> */}
        {/* </div> */}
        <img src={logoSrc} style={styles.logo} alt='' />
        <div
          className='header-profile-picture-and-chat-container'
          style={{ marginRight: '2%', width: width < 500 ? '13%' : '236px' }}
        >
          {/* {width >= 500 && (
            <div style={styles.chatButtonContainer}>
              <img
                alt='notifications'
                style={{ cursor: 'pointer' }}
                className='header-chat-icon'
                src={notificationsLogo}
                onClick={toggleNotificationsListModal}
              />
              {countBubble && width > 500 ? <div style={styles.notificationBubble}>{countBubble}</div> : null}
            </div>
          )} */}
          {/* {width >= 500 && (
            <div style={styles.chatButtonContainer}>
              <img
                alt='chat'
                style={{ marginRight: '22%', cursor: 'pointer' }}
                className='header-chat-icon'
                src={chatsLogo}
                onClick={toggleChatListModal}
              />
              {countChatBubble && width > 500 ? <div style={styles.notificationBubble}>{countChatBubble}</div> : null}
            </div>
          )} */}
          <ProfileImage
            showBorder={false}
            withFullSize={false}
            height={60}
            width={60}
            imageUrl={props.currentUser ? props.currentUser.profilePictureUrl : null}
            onClick={() => {
              refreshUserFunction()
              props.history.push('profile')
            }}
          />
        </div>
      </div>
      {isMenuOpen && (
        <Menu
          notificationsCount={countBubble}
          history={props.history}
          isOpen={isMenuOpen}
          closeMenu={() => setIsMenuOpen(false)}
        />
      )}
      {isChatListModalOpen && width > 500 && <ChatsModal history={props.history} currentUser={props.currentUser} />}
      {isNotificationsListOpen && width > 500 &&
        <MyNotificationsModal history={props.history}/>}
    </>
  )
}

export default HeaderHOC

const styles = {
  bubble: {
    backgroundColor: 'red',
    fontSize: '13px',
    height: 'fit-content',
    padding: '0.7% 2.2%',
    borderRadius: '50%',
    position: 'absolute',
    top: '18%',
    right: '6%',
  },
  mainPage: {
    // minHeight: '100vh',
    backgroundColor: Theme.BACKGROUND_COLOR,
  },
  outerNav: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: window.innerWidth,
    paddingTop: '2%',
    position: 'fixed',
    bottom: 0,
    zIndex: 9,
    backgroundColor: '#4d4b62',
    borderRadius: '20px 20px 0 0',
    padding: '0',
  },
  innerMenu: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%',
  },
  topNav: {
    display: 'flex',
    justifyContent: width < 500 ? 'space-around' : 'space-between',
    alignItems: 'center',
    width: window.innerWidth,
    paddingRight: width < 500 ? 0 : '40px',
    paddingLeft: width < 500 ? 0 : '40px',
    paddingTop: width < 500 ? '2%' : '20px',
    paddingBottom: width < 500 ? '0' : '20px',
    position: 'fixed',
    top: 0,
    height: width < 500 ? '70px' : '120px',
    zIndex: 99999,
    backgroundColor: 'rgb(231,228,216,0.95)'
  },
  topNavGray: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: window.innerWidth,
    paddingTop: '2%',
    position: 'fixed',
    top: 0,
    zIndex: 99999,
    backgroundColor: Theme.GRAY_COLOR,
  },
  innerNav: {
    textAlign: 'center',
    fontSize: '35px',
    color: Theme.LOWER_NAV_ICONS_COLOR,
    paddingBottom: '1%',
    display: 'flex',
  },
  innerNavSelected: {
    textAlign: 'center',
    fontSize: '35px',
    color: Theme.WHITE_COLOR,
    paddingBottom: '1%',
    borderBottom: `2px solid ${Theme.WHITE_COLOR}`,
  },
  logo: {
    width: width < 500 ? '50%' : 'auto',
    height: width < 500 ? 'auto' : '84%',
    padding: width < 500 ? '1%' : '20px',
    paddingLeft: width < 500 ? '0' : '18%',
    marginLeft: width < 500 ? '0' : '25%',
  },
  menu: {
    color: Theme.BLACK_COLOR,
    fontSize: '25px',
    marginLeft: 0,
    cursor: 'pointer',
  },
  fullPage: {
    minHeight: window.innerHeight,
    width: window.innerWidth,
    opacity: '0.9',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: '999999',
    textAlign: 'center',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '0',
  },
  msgContainer: {
    width: window.innerWidth / 2 + 'px',
    backgroundColor: 'white',
    fontFamily: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: Theme.TEXT_COLOR,
  },
  btn: {
    color: Theme.TEXT_COLOR,
    backgroundColor: Theme.BACKGROUND_COLOR,
    border: 'none',
    padding: '7%',
    fontWeight: 'bold',
    fontSize: window.innerWidth / 24 + 'px',
    fontFamily: 'inherit',
    width: '50%',
    margin: '1%',
  },
  icon: {
    color: Theme.BACKGROUND_COLOR,
    fontSize: window.innerWidth / 7.2 + 'px',
    marginTop: '5%',
  },
  buttonsRow: {
    width: '100%',
    display: 'flex',
  },
  userAnswerInput: {
    border: '1px solid #dcd7c8',
    fontFamily: 'inherit',
    marginBottom: '10%',
    padding: '5%',
    fontSize: '15px',
    borderRadius: '15px',
  },
  talkWithYourYogiBtn: {
    width: '100%',
    borderRadius: '25px',
    border: 'none',
    padding: '5px 10px',
    backgroundColor: 'transparent',
    color: '#665f70', // margin: '5%'
    display: 'flex',
    flexDirection: 'row-reverse',
    outline: '0',
  },
  exitIcon: {
    position: 'absolute',
    zIndex: 10000,
    top: '18%',
    left: '94%',
    width: '20%',
    fontSize: '18px',
    color: '#978689',
    cursor: 'pointer',
  },
  talkWithYourYogiContainer: {
    // marginTop: width < 500 ? window.innerWidth / 7.2 + 'px' : window.innerWidth / 11 + 'px',
    // width: 'fit-content',
    // display: "flex",
    // justifyContent: "center",
    position: 'absolute',
    zIndex: '10000',
    top: '16%',
    left: '80%',
    width: '20%',
    flexDirection: 'row-reverse',
  },
  outerNavSubMenuBtn: {
    width: '70%',
    borderRadius: '25px',
    padding: '2%',
    backgroundColor: '#e1c2b0',
    color: '#4D4B62',
    fontSize: 'large',
    marginBottom: '2%',
  },
  addNewPostContainer: {
    marginTop: width < 500 ? window.innerWidth / 7.2 - 2 + 'px' : window.innerWidth / 11 + 'px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  addNewPostBtn: {
    outline: 'none',
    width: '80%',
    borderRadius: '25px',
    border: 'none',
    padding: '5px 10px',
    backgroundColor: '#e1c2b0',
    color: '#665f70',
    margin: '5%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  yogiLogo: {
    width: 'inherit',
  },
  yogiBlobStyle: {
    width: '230px',
    height: '100%',
    position: 'absolute',
    top: '-38%',
    backgroundImage: `url(${yogiBlob})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  yogiBlobTextStyle: {
    width: '30%',
    textAlign: 'center',
    fontSize: '11px',
  },
  addPostToggler: {
    display: 'inline-block',
    transform: 'rotate(90deg)',
    fontSize: '20px',
    padding: '4px 8px',
    backgroundColor: '#4D4B62',
    borderRadius: '50%',
    color: '#E1C2B0',
    fontWeight: 'bolder',
  },
  notificationBubble: {
    width: '16px',
    height: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    borderRadius: '50%',
    backgroundColor: 'red',
    fontSize: '13px',
    left: '20px',
    top: '5px',
    position: 'absolute',
  },
  chatButtonContainer: {
    display: 'inline-block',
    marginRight: '36px',
    position: 'relative',
  },
}
