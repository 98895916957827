import { getClient } from '../apollo';
import { unreadNotificationCountQuery } from './graphql/unread-notifications-count.query';
import { myNotificationsQuery } from './graphql/notifications.query';
import { EFriendsListModalActions } from "../friends/friends.actions";

export const EMyNotificationsActions = {
  TOGGLE_NOTIFICATIONS_MODAL: '@notifications/TOGGLE_NOTIFICATIONS_MODAL',
  OPEN_NOTIFICATIONS_LIST_MODAL: '@notifications/OPEN_NOTIFICATIONS_LIST_MODAL',
  CLOSE_NOTIFICATIONS_LIST_MODAL: '@notifications/CLOSE_NOTIFICATIONS_LIST_MODAL',
};

// export const toggleMyNotifications = isOpen => ({
//   type: EMyNotificationsActions.TOGGLE_NOTIFICATIONS_MODAL,
//   isOpen
// });

export async function refreshNotificationsList() {
  const client = getClient();

  try {
    const existingState = client.readQuery({
      query: unreadNotificationCountQuery
    });
    const prevCount = existingState
      ? existingState.unreadNotificationsCount
      : 0;
    const newState = await client.query({
      query: unreadNotificationCountQuery,
      fetchPolicy: 'network-only'
    });
    const newCount =
      newState && newState.data && newState.data.unreadNotificationsCount
        ? newState.data.unreadNotificationsCount
        : 0;

    await client.query({
      query: myNotificationsQuery,
      variables: { cursor: null, userId: null },
      fetchPolicy: 'network-only'
    });
  } catch (e) {
    // nothing to do here
  }
}

export const openNotificationsListModal = () => ({
  type: EMyNotificationsActions.OPEN_NOTIFICATIONS_LIST_MODAL
})

export const closeNotificationsListModal = () => ({
  type: EMyNotificationsActions.CLOSE_NOTIFICATIONS_LIST_MODAL
})
