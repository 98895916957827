import { WindowedChatActions } from "./windowedChat.actions";

const initialState = {
  isWindowedChatOpen: false,
  title: ''
}

export const selectIsChatOpen = (state) => state.windowedChat.isWindowedChatOpen;
export const selectTitle = (state) => state.windowedChat.title;

export const windowedChat = (state = initialState, action) => {
  switch (action.type) {
    case WindowedChatActions.WINDOWED_CHAT_OPEN :
      return {
        ...state,
        isWindowedChatOpen: true,
        title: action.payload
      }
    case WindowedChatActions.WINDOWED_CHAT_CLOSE:
      return {
        ...state,
        isWindowedChatOpen: false,
        title: ''
      }
    default:
      return state
  }
}
